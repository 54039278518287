import React, { useState } from 'react';
import SubscriberList from 'src/pages/crud/subscriber/SubscriberList';
import HandlerSteperBtn from '../HandlerSteperBtn';
import { useSelector } from '../../../redux/store';
import { steps, prev, next } from 'src/redux/slices/newslatter';
import SubscriberListL from 'src/pages/crud/subscriberList/SubscriberListL';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SelectSubscriber = () => {
  const selectedEmails = useSelector((state) => state.newsLetter.selectedEmails);
  const selectedListSubscriberEmails = useSelector(
    (state) => state.subscriberList.selectedSubscribers
  );
  const [option, setOption] = useState<any>();

  const renderSubscription = () => {
    switch (option) {
      case 0:
        return (
          <>
            <SubscriberList showTopBar={false} showActions={false} />
          </>
        );
      case 1:
        return (
          <>
            <SubscriberListL showTopBar={false} showActions={false} />
          </>
        );
      case 2:
        return (
          <>
            <SubscriberList showTopBar={false} showActions={false} />
            <SubscriberListL showTopBar={false} showActions={false} />
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <FormControl fullWidth sx={{ marginTop: 5, marginBottom: 5 }}>
        <InputLabel id="sbo">Subscription options</InputLabel>
        <Select
          labelId="sbo"
          id="ssb"
          value={option}
          label="Please select your preferred subscription option"
          onChange={(e) => setOption(e.target.value)}
        >
          <MenuItem value={0}>View Subscriber Table</MenuItem>
          <MenuItem value={1}>View My Subscriber List Table</MenuItem>
          <MenuItem value={2}>View Both Subscriber Table and My Subscriber List Table</MenuItem>
        </Select>
      </FormControl>
      {renderSubscription()}
      <HandlerSteperBtn
        config={{ steps, prev, next, sliceName: 'newsLetter' }}
        cnext={selectedEmails.length > 0 || selectedListSubscriberEmails.length > 0}
        labelNext={'Next'}
      />
    </>
  );
};

SelectSubscriber.label = 'Select Subscribers';

export default SelectSubscriber;

import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from './Loadable';

const SubscriberListL = Loadable(
  lazy(() => import('src/pages/crud/subscriberList/SubscriberListL'))
);
const SubscriberListEditor = Loadable(
  lazy(() => import('src/pages/crud/subscriberList/SubscriberListEditor'))
);

// const UserProfile = Loadable(lazy(() => import('../pages/dashboard/UserProfile')));
// const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/crud/user/UserList')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/crud/user/Editor')));
const UserDetail = Loadable(lazy(() => import('../pages/crud/user/userDetails')));

const ServiceRequestList = Loadable(
  lazy(() => import('../pages/crud/serviceRequest/ServiceRequestList'))
);
const ServiceRequestEditor = Loadable(
  lazy(() => import('../pages/crud/serviceRequest/ServiceRequestEditor'))
);
const SRDetails = Loadable(lazy(() => import('../pages/crud/serviceRequest/SRDetails')));

const CampaignList = Loadable(lazy(() => import('../pages/crud/campaign/index')));
const NewCampaign = Loadable(lazy(() => import('../pages/crud/campaign/new')));
const EditCampaign = Loadable(lazy(() => import('../pages/crud/campaign/edit')));
const CampaignDetails = Loadable(lazy(() => import('../pages/crud/campaign/details')));

const PromoCodeList = Loadable(lazy(() => import('../pages/crud/promo-codes/index')));
const NewPromoCode = Loadable(lazy(() => import('../pages/crud/promo-codes/new')));
const EditPromoCode = Loadable(lazy(() => import('../pages/crud/promo-codes/edit')));
const PromoCodeDetails = Loadable(lazy(() => import('../pages/crud/promo-codes/details')));

const ClientReviewsList = Loadable(lazy(() => import('../pages/crud/clientReview/index')));
const NewClientReview = Loadable(lazy(() => import('../pages/crud/clientReview/new')));
const EditClientReview = Loadable(lazy(() => import('../pages/crud/clientReview/edit')));
const ClientReviewDetails = Loadable(lazy(() => import('../pages/crud/clientReview/details')));

const SubscriberList = Loadable(lazy(() => import('../pages/crud/subscriber/SubscriberList')));
const SubscriberEditor = Loadable(lazy(() => import('../pages/crud/subscriber/SubscriberEditor')));

const CallbackRequestList = Loadable(
  lazy(() => import('../pages/crud/callbackRequest/CallbackRequestList'))
);
const CallbackRequestEditor = Loadable(
  lazy(() => import('../pages/crud/callbackRequest/CallbackRequestEditor'))
);

const ContactList = Loadable(lazy(() => import('../pages/crud/contact/ContactList')));
const ContactEditor = Loadable(lazy(() => import('../pages/crud/contact/ContactEditor')));
const ContactDetails = Loadable(lazy(() => import('../pages/crud/contact/ContactDetails')));

const Calendar = Loadable(lazy(() => import('../pages/appointment/calendar/Calendar')));

const ClientList = Loadable(lazy(() => import('../pages/crud/client/ClientList')));
const ClientEditor = Loadable(lazy(() => import('../pages/crud/client/ClientEditor')));

const Sms = Loadable(lazy(() => import('../pages/crud/sms')));

const JobList = Loadable(lazy(() => import('../pages/crud/job/JobList')));
const JobEditor = Loadable(lazy(() => import('../pages/crud/job/JobEditor')));

const JobRoleList = Loadable(lazy(() => import('../pages/crud/jobRole/JobRoleList')));
const JobRoleEditor = Loadable(lazy(() => import('../pages/crud/jobRole/JobRoleEditor')));
const JobAppliesList = Loadable(lazy(() => import('../pages/crud/jobApply/JobApplyList')));

const DepartmentList = Loadable(lazy(() => import('../pages/crud/department/DepartmentList')));
const DepartmentEditor = Loadable(lazy(() => import('../pages/crud/department/DepartmentEditor')));

const BookAssessmentList = Loadable(
  lazy(() => import('../pages/crud/bookAssessment/BookAssessmentList'))
);
const BookAssessmentEditor = Loadable(
  lazy(() => import('../pages/crud/bookAssessment/BookAssessmentEditor'))
);

const Newsletter = Loadable(lazy(() => import('../components/newsletter')));

const TagList = Loadable(lazy(() => import('../pages/crud/tag/TagList')));
const TagEditor = Loadable(lazy(() => import('../pages/crud/tag/TagEditor')));

const CompanyList = Loadable(lazy(() => import('../pages/crud/company/CompanyList')));
const CompanyEditor = Loadable(lazy(() => import('../pages/crud/company/CompanyEditor')));
const CompanyDetails = Loadable(lazy(() => import('../pages/crud/company/CompanyDetails')));

const TemplateList = Loadable(lazy(() => import('../pages/crud/template/TemplateList')));
const TemplateEditor = Loadable(lazy(() => import('../pages/crud/template/Editor')));
const TemplateDetails = Loadable(lazy(() => import('../pages/crud/template/TemplateDetails')));

const RoleList = Loadable(lazy(() => import('../pages/crud/roles/RoleList')));
const RoleEditor = Loadable(lazy(() => import('../pages/crud/roles/Editor')));
const RoleDetails = Loadable(lazy(() => import('../pages/crud/roles/RoleDetails')));

const Analytics = Loadable(lazy(() => import('../pages/dashboard/analytics')));

const PostList = Loadable(lazy(() => import('../pages/crud/post/PostList')));
const PostEditor = Loadable(lazy(() => import('../pages/crud/post/PostEditor')));
const PostDetails = Loadable(lazy(() => import('../pages/crud/post/PostDetails')));

const CategoryList = Loadable(lazy(() => import('../pages/crud/category/CategoryList')));
const CategoryEditor = Loadable(lazy(() => import('../pages/crud/category/CategoryEditor')));

const RepairServiceList = Loadable(
  lazy(() => import('../pages/crud/repairService/RepairServiceList'))
);
const RepairServiceEditor = Loadable(
  lazy(() => import('../pages/crud/repairService/RepairServiceEditor'))
);

export const subscribers = {
  path: 'subscribers',
  children: [
    { path: '', element: <SubscriberList showTopBar={true} showActions={true} /> },
    { path: 'new', element: <SubscriberEditor /> },
    { path: ':subscriberId/edit', element: <SubscriberEditor /> },
  ],
};
export const serviceRequests = {
  path: 'service-requests',
  children: [
    { path: '', element: <ServiceRequestList /> },
    { path: 'new', element: <ServiceRequestEditor /> },
    { path: ':serviceRequestId/edit', element: <ServiceRequestEditor /> },
    { path: ':id', element: <SRDetails /> },
  ],
};

export const campaign = {
  path: 'campaigns',
  children: [
    { path: '', element: <CampaignList /> },
    { path: 'new', element: <NewCampaign /> },
    { path: ':id/edit', element: <EditCampaign /> },
    { path: ':id', element: <CampaignDetails /> },
  ],
};

export const promoCodes = {
  path: 'promo-codes',
  children: [
    { path: '', element: <PromoCodeList /> },
    { path: 'new', element: <NewPromoCode /> },
    { path: ':id/edit', element: <EditPromoCode /> },
    { path: ':id', element: <PromoCodeDetails /> },
  ],
};

export const clientReviews = {
  path: 'client-reviews',
  children: [
    { path: '', element: <ClientReviewsList /> },
    { path: 'new', element: <NewClientReview /> },
    { path: ':id/edit', element: <EditClientReview /> },
    { path: ':id', element: <ClientReviewDetails /> },
  ],
};

export const callbackRequests = {
  path: 'callback-requests',
  children: [
    { path: '', element: <CallbackRequestList /> },
    { path: 'new', element: <CallbackRequestEditor /> },
    { path: ':callbackRequestId/edit', element: <CallbackRequestEditor /> },
  ],
};
export const contacts = {
  path: 'contacts',
  children: [
    { path: '', element: <ContactList showTopBar={true} showActions={true} /> },
    { path: 'new', element: <ContactEditor /> },
    { path: ':contactId/edit', element: <ContactEditor /> },
    { path: ':id', element: <ContactDetails /> },
  ],
};
export const appointments = {
  path: 'appointments',
  children: [{ path: '', element: <Calendar /> }],
};
export const clients = {
  path: 'clients',
  children: [
    { path: '', element: <ClientList /> },
    { path: 'new', element: <ClientEditor /> },
    { path: ':clientId/edit', element: <ClientEditor /> },
  ],
};
export const sms = {
  path: 'sms',
  children: [{ path: '', element: <Sms /> }],
};
export const jobs = {
  path: 'jobs',
  children: [
    { path: '', element: <JobList /> },
    { path: 'new', element: <JobEditor /> },
    { path: ':jobId/edit', element: <JobEditor /> },
    { path: ':jobId/edit/job-roles', element: <JobEditor /> },
    { path: ':jobId/add-job-roles', element: <JobEditor /> },
    { path: ':jobId/job-roles', element: <JobEditor /> },
  ],
};
export const jobRroles = {
  path: 'job-roles',
  children: [
    { path: '', element: <JobRoleList job="false" /> },
    { path: ':jobRoleId/edit', element: <JobRoleEditor /> },
    { path: ':jobRoleId/update', element: <JobRoleEditor /> },
    { path: ':jobRoleId/job-applies', element: <JobAppliesList /> },
    { path: ':jobRoleId/job-applies-list', element: <JobAppliesList /> },
  ],
};
export const user = {
  path: 'users',
  children: [
    { path: '', element: <UserList /> },
    { path: 'new', element: <UserCreate /> },
    { path: ':id/edit', element: <UserCreate /> },
    { path: ':id/details', element: <UserDetail /> },
  ],
};
export const profile = {
  path: 'profile',
  children: [
    { element: <Navigate to="/profile" replace />, index: true },
    { path: 'account', element: <UserAccount /> },
  ],
};
export const departments = {
  path: 'departments',
  children: [
    { path: '', element: <DepartmentList /> },
    { path: 'new', element: <DepartmentEditor /> },
    { path: ':departmentId/edit', element: <DepartmentEditor /> },
  ],
};
export const bookAssessments = {
  path: 'book-assessments',
  children: [
    { path: '', element: <BookAssessmentList /> },
    { path: 'new', element: <BookAssessmentEditor /> },
    { path: ':bookAssessmentId/edit', element: <BookAssessmentEditor /> },
  ],
};
export const newsLetter = {
  path: 'newsletter',
  children: [{ path: '', element: <Newsletter /> }],
};
export const tags = {
  path: 'tags',
  children: [
    { path: '', element: <TagList /> },
    { path: 'new', element: <TagEditor /> },
    { path: ':tagId/edit', element: <TagEditor /> },
  ],
};
export const company = {
  path: 'company',
  children: [
    { path: '', element: <CompanyList /> },
    { path: 'new', element: <CompanyEditor /> },
    { path: ':company/edit', element: <CompanyEditor /> },
    { path: ':id/details', element: <CompanyDetails /> },
  ],
};
export const template = {
  path: 'template',
  children: [
    { path: '', element: <TemplateList /> },
    { path: 'new', element: <TemplateEditor /> },
    { path: ':template/edit', element: <TemplateEditor /> },
    { path: ':id/details', element: <TemplateDetails /> },
  ],
};
export const role = {
  path: 'role',
  children: [
    { path: '', element: <RoleList /> },
    { path: 'new', element: <RoleEditor /> },
    { path: ':role/edit', element: <RoleEditor /> },
    { path: ':id/details', element: <RoleDetails /> },
  ],
};
export const analytics = {
  path: 'analytics',
  children: [{ path: '', element: <Analytics /> }],
};
export const categories = {
  path: 'categories',
  children: [
    { path: '', element: <CategoryList /> },
    {
      path: 'new',
      element: <CategoryEditor />,
    },
    {
      path: ':categoryId/edit',
      element: <CategoryEditor />,
    },
  ],
};
export const posts = {
  path: 'posts',
  children: [
    { path: '', element: <PostList showTopBar={true} showActions={true} /> },
    {
      path: 'new',
      element: <PostEditor />,
    },
    {
      path: ':postId/edit',
      element: <PostEditor />,
    },
    { path: ':postId/details', element: <PostDetails /> },
  ],
};

export const subscribersList = {
  path: 'list-subscribers',
  children: [
    { path: '', element: <SubscriberListL showTopBar={true} showActions={true} /> },
    { path: 'new', element: <SubscriberListEditor /> },
    { path: ':subscriberListId/edit', element: <SubscriberListEditor /> },
  ],
};

export const repairServices = {
  path: 'repair-services',
  children: [
    { path: '', element: <RepairServiceList showTopBar={true} showActions={true} /> },
    { path: 'new', element: <RepairServiceEditor /> },
    { path: ':repairServiceId/edit', element: <RepairServiceEditor /> },
  ],
};

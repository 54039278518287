import {
  Button,
  Chip,
  Container,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import {
  DataGrid,
  GridColumns,
  GridRowsProp,
  GridFilterModel,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { DataGridActions } from '../../../utils/dataGrid';
import { DataGridWrapper } from '../../../sections/cruds/dataGridWrapper';
import { useLocation, Link as LI } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import * as apiService from 'src/api/api';
import { fToNow } from 'src/utils/formatTime';
import { debounce } from 'lodash';
import useAuth from 'src/hooks/useAuth';
import { canI } from 'src/utils/canI';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// ----------------------------------------------------------------------

const apiSuffix = 'book-assessments';
const modelName = 'Book Assessment';

export const CustomToolbar: React.FunctionComponent<{
  rowIds?: String[];
  removing?: boolean;
  onBulkRemove?: () => void;
  canDoBulkRemove?: boolean;
  children?: React.ReactNode;
  setFilterButtonEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
}> = ({ rowIds, removing, canDoBulkRemove, children, setFilterButtonEl, onBulkRemove }) => (
  <Box
    sx={{ display: 'flex', alignContent: 'space-between', alignItems: 'center', height: '40px' }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
      {children}
      {canDoBulkRemove && onBulkRemove && rowIds && rowIds.length > 0 && (
        <Button
          startIcon={
            removing ? (
              <CircularProgress
                sx={{
                  width: '15px !important',
                  height: '15px !important',
                  color: 'gray',
                  ml: '5px',
                }}
              />
            ) : (
              <DeleteForeverIcon />
            )
          }
          disabled={removing}
          onClick={onBulkRemove}
          sx={{ textTransform: 'none' }}
        >
          Delete rows
        </Button>
      )}
    </Box>
    {/* @ts-ignore */}
    <GridToolbarFilterButton
      style={{ marginLeft: 'auto', marginRight: 0 }}
      ref={setFilterButtonEl}
    />
  </Box>
);

export default function BookAssessmentList() {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const [dataGridRows, setDataGridRows] = useState<GridRowsProp>([]);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState<number>(15);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    window.localStorage.getItem('selectCp') || ''
  );
  const JWTContext = useAuth();

  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const permissions = JWTContext && JWTContext.user ? JWTContext.user.permissions : [];
  const isSuperAdmin = JWTContext && JWTContext.user ? JWTContext.user.isSuperAdmin : false;
  const isGlobalAdmin = JWTContext && JWTContext.user ? JWTContext.user.isGlobalAdmin : false;

  const dataGridColumns: GridColumns = [
    {
      field: 'id',
      filterable: false,
      hide: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Link href={'mailto:' + params.row.email}>{params.row.email}</Link>
        </Tooltip>
      ),
    },
    {
      field: 'createBy',
      headerName: 'Created By',
      flex: 1,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Chip
            label={params.value}
            color={
              params.value === 'pending'
                ? 'warning'
                : params.value === 'booked'
                ? 'info'
                : params.value === 'done'
                ? 'success'
                : 'error'
            }
          />
        </Tooltip>
      ),
    },
    {
      field: 'createdAt',
      filterable: false,
      headerName: 'Created',
      flex: 1,
      type: 'dateTime',
      renderCell: (params) => <Typography>{fToNow(params.row.createdAt)}</Typography>,
    },
    {
      field: 'actions',
      filterable: false,
      headerName: 'Actions',
      flex: 1,
      renderCell: (params) => (
        <DataGridActions
          apiSuffix={apiSuffix}
          modelName={modelName}
          entityId={params.row.id}
          afterDelete={loadData}
          updatePermissionName={'update.book-assessments'}
          permissions={permissions}
        />
      ),
    },
  ];

  const loadData = async (filters?: {
    filterSearch: string;
    filterOperator: string;
    filterField: string;
  }) => {
    try {
      const queryParams = (apiPath: string) =>
        `${apiPath}?search=${search}&pageSize=${pageSize}&page=${page}&signature=${
          JWTContext?.user ? JWTContext?.user.companyId : ''
        }`;
      let resp: any = [];
      let comp: any = [];
      setIsLoading(true);
      if (filters) {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&filterSearch=${filters.filterSearch}&filterOperator=${
            filters.filterOperator
          }&filterField=${filters.filterField}&selectedCompany=${selectedCompany}`
        );
        const companyPromise = isGlobalAdmin ? apiService.get(`company`) : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      } else {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&selectedCompany=${selectedCompany}`
        );
        const companyPromise: any = isGlobalAdmin ? apiService.get('company') : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      }

      if (resp.data.length === 0) {
        setRowCount(0);
        setDataGridRows([]);
        setIsLoading(false);
        return;
      }
      const responseData = resp.data.results.map(
        (bookAssessment: { createdAt: string | number | Date }, index: any) => ({
          ...bookAssessment,
          // createdAt: fToNow(bookAssessment.createdAt),
        })
      );
      if (isGlobalAdmin) setCompanies(comp.data);
      setRowCount(resp.data.total);
      setDataGridRows(responseData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    if (
      filterModel.items[0].value ||
      filterModel.items[0].operatorValue === 'isEmpty' ||
      filterModel.items[0].operatorValue === 'isNotEmpty'
    ) {
      loadData({
        filterSearch: filterModel.items[0].value,
        filterOperator: String(filterModel.items[0].operatorValue),
        filterField: filterModel.items[0].columnField,
      }).then();
    } else {
      loadData().then();
    }
  }, []);

  useEffect(() => {
    loadData().then();
    return () => window.localStorage.removeItem('selectCp');
  }, [search, page, pageSize, selectedCompany]);

  return (
    <DataGridWrapper pageTitle={'Book Assessment'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <HeaderBreadcrumbs
              heading="Book Assessment"
              links={[
                { name: 'Home', href: PATH_DASHBOARD.root },
                { name: 'Book Assessment', href: PATH_DASHBOARD.website.bookAssessments },
              ]}
            />
            {canI(permissions, 'create.book-assessments', isSuperAdmin) && !isGlobalAdmin && (
              <div>
                <Button variant={'contained'} component={LI} to={`${pathname}/new`}>
                  <AddIcon /> NEW
                </Button>
              </div>
            )}
          </Stack>

          <Stack direction={'row'} justifyContent={'flex-start'}>
            <Grid item xs={12} md={4} sm={6}>
              <TextField
                placeholder="Search"
                onChange={debounce((e) => setSearch(e.target.value), 1000)}
                sx={{ m: 1, width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {isGlobalAdmin && (
              <Grid item xs={12} md={4} sm={6}>
                <FormControl fullWidth sx={{ m: 1, width: '100%', marginLeft: '1rem' }}>
                  <InputLabel id="demo-simple-select-label">Companies</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCompany}
                    label="Company"
                    onChange={(e) => {
                      setSelectedCompany(e.target.value);
                      window.localStorage.setItem('selectCp', e.target.value);
                    }}
                  >
                    <MenuItem value="">All Companies</MenuItem>
                    {companies.map((el: any, index: any) => (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Stack>
          <DataGrid
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                setFilterButtonEl,
              },
            }}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            autoHeight={true}
            density={'comfortable'}
            checkboxSelection={true}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            columns={dataGridColumns}
            rows={dataGridRows}
            rowCount={rowCount}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
            rowsPerPageOptions={[15, 50, 100]}
            pagination
            {...dataGridRows}
            paginationMode="server"
            loading={loading}
          />
        </Stack>
      </Container>
    </DataGridWrapper>
  );
}

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  Link,
  Stack,
} from '@mui/material';
import { FC } from 'react';
import { Email, Phone, Check, Close } from '@mui/icons-material';

export type ContactProps = {
  openDialog: boolean;
  closeDialog: VoidFunction;
  html: any;
};

export const PreviewDialog: FC<ContactProps> = ({ openDialog, closeDialog, html }) => (
  <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'md'} scroll={'body'}>
    <DialogTitle>
      <Typography
        variant="h4"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '20',
        }}
      >
        Template Details
      </Typography>
    </DialogTitle>

    <DialogContent>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ m: 3 }}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{
                __html: html
              }}
             />
          </Box>
        </Grid>
      </Grid>
    </DialogContent>

    <DialogActions>
      <Button variant="contained" color="inherit" onClick={closeDialog}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

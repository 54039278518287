type appProps = {
  publicAppointment: {
    startTime: number;
    endTime: number;
    minutesPerTurns: number;
  };
  user: {
    id: string;
    isGlobalAdmin: boolean;
  } | null;
  company: {
    modules: moduleType[];
  } | null;
};

type moduleType = {
  id: string;
  name: string;
};
export const appConfig: appProps = {
  //   apiUrl: 'http://localhost:3000',
  //   appName: 'Todo App',
  //   appVersion: '1.0.0',
  //   appDescription: 'Todo App',
  //   appAuthor: 'Todo App',
  //   appAuthorEmail: '

  // PUBLIC APPOINTMENT VARIABLES MUST BE 24 FORMATTED HOURS (startTime, endTime)
  publicAppointment: {
    startTime: 8,
    endTime: 17,
    minutesPerTurns: 60,
  },

  user: null,
  company: null,
};

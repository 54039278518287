import React, { FC, useState, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
} from '@mui/material';

import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { post } from 'src/api/api';
import useAuth from 'src/hooks/useAuth';

export type dfaProp = {
  openDialog: boolean;
  closeDialog: React.Dispatch<React.SetStateAction<boolean>>;
  // pNumber: string;
  sendCode: (
    phone: string,
    sid: string,
    token: string,
    id: string,
    enqueueSnackbar: any
  ) => Promise<void>;
};

export const Countdown = (startingMinutes: number, startingSeconds: number) => {
  const [mins, setMinutes] = useState(startingMinutes);
  const [secs, setSeconds] = useState(startingSeconds);

  const reset = () => {
    setMinutes(startingMinutes);
    setSeconds(startingSeconds);
  };
  useEffect(() => {
    let sampleInterval = setInterval(() => {
      if (secs > 0) {
        setSeconds(secs - 1);
      }
      if (secs === 0) {
        if (mins === 0) {
          clearInterval(sampleInterval);
        } else {
          setMinutes(mins - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(sampleInterval);
      //   setMinutes(startingMinutes);
      //   setSeconds(startingSeconds);
    };
  });
  return { timer: `${mins}:${secs < 10 ? `0${secs}` : secs}`, reset };
};

export const DFA: FC<dfaProp> = ({ openDialog, closeDialog,  sendCode }) => {
  const { timer, reset } = Countdown(1, 0);
  const { enqueueSnackbar } = useSnackbar();
  const { company, user } = useAuth();

  const codeConfig = {
    phone: user && user.phone,
    sid: company ? company.config.TWILIO_ACCOUNT_SID : null,
    token: company ? company.config.TWILIO_AUTH_TOKEN : null,
    id: user && user.id,
  };

  const cv = localStorage.getItem('cv');
  const [codeVal, setCodeVal] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (openDialog) {
      reset();
    }
  }, [openDialog]);
  const verify = async () => {
    try {
      setIsLoading(true);
      const res = await post('auth/verifyCode', { code: codeVal, id: user ? user.id : null });

      if (res.data.status) {
        setIsLoading(false);
        closeDialog(false);
        localStorage.setItem('cv', 'verify');
        enqueueSnackbar('2FA is activated');
        return window.location.reload();
      }

      enqueueSnackbar(res.data.msg, { variant: 'error' });
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Error', { variant: 'error' });

      setIsLoading(false);
    }
  };
  return (
    <Dialog open={openDialog} fullWidth={true} maxWidth={'md'} scroll={'body'}>
      <DialogTitle>
        <Typography
          variant="h4"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '20',
            marginBottom: '1rem',
          }}
        >
          Verify your phone
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={3} justifyItems="center">
          <Grid item xs={12} md={12} justifyContent="center">
            <TextField
              onChange={(e: any) => setCodeVal(e.target.value)}
              value={codeVal}
              inputProps={{ maxLength: 6 }}
              placeholder="code"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '0.5rem',
              }}
            />
            {cv === 'verify' ? (
              <Typography
                variant="overline"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {`We have sent a verification code to ${codeConfig.phone} `}
              </Typography>
            ) : (
              <Typography
                variant="overline"
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {`We are trying to send a verification code to ${codeConfig.phone} `}
              </Typography>
            )}
            <Typography
              variant="overline"
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {cv === 'verify' ? `Send new code in ${timer}` : `Try again ${timer}`}
              <LoadingButton
                type="submit"
                variant="text"
                disabled={timer !== '0:00'}
                loading={false}
                onClick={() => {
                  sendCode(
                    codeConfig.phone,
                    codeConfig.sid,
                    codeConfig.token,
                    codeConfig.id,
                    enqueueSnackbar
                  );
                  reset();
                }}
              >
                Send
              </LoadingButton>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="contained" color="inherit" onClick={() => closeDialog(false)}>
          Cancel
        </Button>
        {codeVal.length === 6 && cv === 'sendedcv' && (
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={() => verify()}
          >
            Verify
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

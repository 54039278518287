import { Box, Dialog, DialogContent, DialogTitle, Typography, Divider } from '@mui/material';
// components
import { FC } from 'react';

export type RepairServiceModalProps = {
  repairService: RepairServiceProps;
  openDialog: boolean;
  closeDialog: () => void;
};

type RepairServiceProps = {
  name: string;
  description: string;
  createdAt: string | Date | number;
};
export const RepairServiceModal: FC<RepairServiceModalProps> = ({
  openDialog,
  closeDialog,
  repairService,
}) => (
  <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'xs'}>
    <DialogTitle>
      <Typography
        variant="h4"
        sx={{
          mt: 0.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text.disabled',
          marginBottom: '1rem',
        }}
      >
        Repair Service Details
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'grid', rowGap: 3, marginTop: 3 }} />
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Name
        </Typography>
        <Typography variant="body2">{`${repairService.name}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Description
        </Typography>
        <Typography variant="body2">{`${repairService.description}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Created At
        </Typography>
        <Typography variant="body2">{`${repairService.createdAt}`}</Typography>
      </Box>
      <Divider />
    </DialogContent>
  </Dialog>
);

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Chip, Divider, Stack, TextField, TextFieldProps } from '@mui/material';
import { ColorSinglePicker } from '../color-utils';
import * as apiService from 'src/api/api';
import useAuth from 'src/hooks/useAuth';
import { Controller, ControllerRenderProps, useFormContext } from 'react-hook-form';
import { getFontColorBasedOnBgColor } from 'src/utils/getFontColorBasedOnBgColor';

interface IProps {
  name: string;
  tagName?: string | null;
  tagColor?: string | null;
}

interface ColorMap {
  [key: string]: string;
}

export default function RHFColorPicker({
  name,
  tagName,
  tagColor,
  ...other
}: IProps & TextFieldProps) {
  const { control } = useFormContext();
  const JWTContext = useAuth();
  const [favorites, setFavorites] = useState<string[]>([]);
  const [selectedColor, setSelectedColor] = useState<string | null>(tagColor || null);

  const isHexColor = useCallback((value: string) => /^#[0-9A-F]{6}$/i.test(value), []);

  const getColorValue = (colorName: string) => {
    const colors: ColorMap = {
      blue: '#0000ff',
      green: '#008000',
      red: '#ff0000',
    };
    return isHexColor(colorName) ? colorName : colors[colorName] || '';
  };

  const loadFavorites = async () => {
    try {
      const response = await apiService.get(`tags?&signature=${JWTContext?.user?.companyId || ''}`);

      const colors = response.data
        .map((tag: { color: string }) => tag.color)
        .filter((color: string) => color !== null && color !== '');

      const colorCounts: { [color: string]: number } = {};

      colors.forEach((color: string) => {
        const currentColor = getColorValue(color);
        colorCounts[currentColor] = (colorCounts[currentColor] || 0) + 1;
      });

      const sortedColors = Object.keys(colorCounts).sort(
        (colorA, colorB) => colorCounts[colorB] - colorCounts[colorA]
      );

      setFavorites(sortedColors.slice(0, 10));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadFavorites();
    return () => {
      setFavorites([]);
    };
  }, []);

  const handleChange = (field: ControllerRenderProps, color: string) => {
    field.onChange(color);
    setSelectedColor(color);
  };

  const memoizedFavorites = useMemo(() => favorites, [favorites]);

  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <TextField
              fullWidth
              type="color"
              value={field.value ? getColorValue(field.value) : '#ffffff'}
              onChange={(ev) => handleChange(field, ev.target.value)}
              {...other}
            />
            <ColorSinglePicker
              colors={memoizedFavorites}
              value={getColorValue(field.value)}
              onChange={(ev) => handleChange(field, ev.target.value)}
            />
          </>
        )}
      />
      {tagName && (
        <>
          <Divider />
          <Stack alignItems={'start'}>
            <Chip
              size="small"
              label={tagName}
              sx={{
                backgroundColor: selectedColor,
                color: (t) =>
                  selectedColor === '#FFFFFF' || !selectedColor
                    ? t.palette.text.primary
                    : getFontColorBasedOnBgColor(selectedColor),
                border:
                  selectedColor === '#ffffff'
                    ? (theme) => `solid 1px ${theme.palette.divider}`
                    : 'none',
              }}
            />
          </Stack>
        </>
      )}
    </>
  );
}

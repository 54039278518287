import { Box, Dialog, DialogContent, DialogTitle, Typography, Divider } from '@mui/material';
// components
import { FC } from 'react';

export type BookAssessmentModalProps = {
  bookAssessment: BookAssessmentProps;
  openDialog: boolean;
  closeDialog: () => void;
};

type BookAssessmentProps = {
    createBy: string;
    email: string;
    service: string;
    status: string;
    company: string
    createdAt: string | Date | number;
  }
export const BookAssessmentModal: FC<BookAssessmentModalProps> = ({
  openDialog,
  closeDialog,
  bookAssessment,
}) => (
  <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'xs'}>
    <DialogTitle>
      <Typography
        variant="h4"
        sx={{
          mt: 0.5,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          color: 'text.disabled',
          marginBottom: '1rem',
        }}
      >
        Book Assessment Details
      </Typography>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ display: 'grid', rowGap: 3, marginTop: 3 }} />
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Email
        </Typography>
        <Typography variant="body2">{`${bookAssessment.email}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Created By
        </Typography>
        <Typography variant="body2">{`${bookAssessment.createBy}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Status
        </Typography>
        <Typography variant="body2">{`${bookAssessment.status}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Service
        </Typography>
        <Typography variant="body2">{`${bookAssessment.service}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Company
        </Typography>
        <Typography variant="body2">{`${bookAssessment.company}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Created At
        </Typography>
        <Typography variant="body2">{`${bookAssessment.createdAt}`}</Typography>
      </Box>
      <Divider />
    </DialogContent>
  </Dialog>
);

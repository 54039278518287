import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import { FChipsDate, selectNextMonday, timeBetweenHours } from 'src/utils/formatTime';
import { appConfig } from '../../config/conf';
import SelectDate from '../../pages/appointment/PublicAppointment/steps/SelectDate';
import UserInfo from '../../pages/appointment/PublicAppointment/steps/UserInfo';
import Review from '../../pages/appointment/PublicAppointment/steps/Review';

// ----------------------------------------------------------------------

interface IDateProps {
  isActive: boolean;
  date: string;
}
interface IsmsProps {
  message: string;
  name: string;
  stack: string;
}

interface IErrorProps {
  error: boolean;
  sms: IsmsProps;
}

interface IPropsUser {
  name: string;
  lastName: string;
  phone: string;
  email: string;
  company: string;
}

interface IState {
  selectedSchedule: [IDateProps];
  calendarDate: Date;
  steper: number;
  user: IPropsUser | null;
  isLoading: boolean;
  error: IErrorProps;
  successBooking: boolean;
}

const cantTurns = () => {
  const diffMinutes =
    timeBetweenHours(appConfig.publicAppointment.startTime, appConfig.publicAppointment.endTime) /
    appConfig.publicAppointment.minutesPerTurns;
  return Math.floor(diffMinutes);
};

const initialState: IState = {
  selectedSchedule: FChipsDate(cantTurns()),
  calendarDate: selectNextMonday(new Date()),
  steper: 0,
  user: null,
  isLoading: false,
  error: {
    error: false,
    sms: {
      message: '',
      name: '',
      stack: '',
    },
  },
  successBooking: false,
};

export const steps = [SelectDate, UserInfo, Review];

const slice = createSlice({
  name: 'publicAppointment',
  initialState,
  reducers: {
    setCalendarDateSuccess(state, action) {
      state.calendarDate = action.payload;
    },
    setActiveChipSuccess(state, action) {
      const newHours: any = state.selectedSchedule.map((e) => {
        if (e.date === action.payload) {
          return {
            ...e,
            isActive: true,
          };
        }
        return {
          ...e,
          isActive: false,
        };
      });
      state.selectedSchedule = newHours;
    },

    setAllChipToFalse(state) {
      const newHours: any = state.selectedSchedule.map((e) => ({
        ...e,
        isActive: false,
      }));
      state.selectedSchedule = newHours;
    },
    handleNext(state) {
      state.steper = Math.min(state.steper + 1, steps.length - 1);
    },
    handlePrev(state) {
      state.steper = Math.max(state.steper - 1, 0);
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    storeBookingSuccess(state) {
      state.successBooking = true;
    },
    bookingError(state, action) {
      state.error = {
        error: action.payload.error,
        sms: action.payload.sms,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSuccesBooking(state, action) {
      state.successBooking = action.payload;
    },
    setSteperToStart(state) {
      state.steper = 0;
      state.selectedSchedule = FChipsDate(cantTurns());
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  setActiveChipSuccess,
  setCalendarDateSuccess,
  setAllChipToFalse,
  handlePrev,
  handleNext,
  setUser,
  setSteperToStart,
} = slice.actions;

// ----------------------------------------------------------------------

export const prev = () => {
  dispatch(slice.actions.handlePrev());
};
export const next = () => {
  dispatch(slice.actions.handleNext());
};
export const setUserG = (user: IPropsUser) => {
  dispatch(slice.actions.setUser(user));
};
export const setActiveChip = (date: string) => {
  dispatch(slice.actions.setActiveChipSuccess(date));
};
export const setCalendar = (date: Date | null) => {
  dispatch(slice.actions.setCalendarDateSuccess(date));
  dispatch(slice.actions.setAllChipToFalse());
};
export const setLoading = (isLoading: boolean) => {
  dispatch(slice.actions.setIsLoading(isLoading));
};
export const setBookingStatus = (success: boolean) => {
  dispatch(slice.actions.setSuccesBooking(success));
};
export const setBoookingError = (error: boolean, sms: string | null | [any]) => {
  const payload = { error, sms };
  dispatch(slice.actions.bookingError(payload));
};
export const setSteperTo0 = () => {
  dispatch(slice.actions.setSteperToStart());
};

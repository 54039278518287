// @mui
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Link,
  Box,
  IconButton,
  Tooltip,
  SvgIcon,
  SvgIconProps,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import {
  DataGrid,
  GridColumns,
  GridFilterModel,
  GridRowsProp,
  GridSelectionModel,
} from '@mui/x-data-grid';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { DataGridActions } from '../../../utils/dataGrid';
import { DataGridWrapper } from '../../../sections/cruds/dataGridWrapper';
import { useLocation, useNavigate, Link as LI } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import * as apiService from 'src/api/api';
import { fToNow } from 'src/utils/formatTime';
import { debounce } from 'lodash';
import useAuth from '../../../hooks/useAuth';
import { useDispatch } from 'src/redux/store';
import { addContactPhones } from '../../../redux/slices/bulkSms';
import { canI } from 'src/utils/canI';
import { CustomToolbar } from '../bookAssessment/BookAssessmentList';
import { useSnackbar } from 'notistack';
import { useBulkRemove } from 'src/hooks/useBulkRemove';

// ----------------------------------------------------------------------

const apiSuffix = 'contacts';
const modelName = 'Contact';

type ContactProps = {
  showTopBar: boolean;
  showActions: boolean;
};

export default function ContactList({ showActions, showTopBar }: ContactProps) {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const [dataGridRows, setDataGridRows] = useState<GridRowsProp>([]);
  const [search, setSearch] = useState('');
  const [pageSize, setPageSize] = useState<number>(15);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const JWTContext = useAuth();
  const [selectedContacts, setSelectedContacts] = useState<string[]>([]);
  const dispatch = useDispatch();
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    window.localStorage.getItem('selectCp') || ''
  );
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);

  const { enqueueSnackbar } = useSnackbar();
  const { rowIds, removing, requestBulkRemove, saveRowIds } = useBulkRemove({
    signature: JWTContext?.user?.companyId,
    apiUrl: '/contacts/bulk-remove',
    onSuccess: () => {
      loadData();
      enqueueSnackbar('Deleted successfully', { variant: 'success' });
    },
  });

  const permissions = JWTContext && JWTContext.user ? JWTContext.user.permissions : [];
  const isSuperAdmin = JWTContext && JWTContext.user ? JWTContext.user.isSuperAdmin : false;
  const isGlobalAdmin = JWTContext && JWTContext.user ? JWTContext.user.isGlobalAdmin : false;

  useEffect(() => {
    dispatch(addContactPhones(selectedContacts));
  }, [selectedContacts]);

  function ViewIcon(props: SvgIconProps) {
    return (
      <SvgIcon {...props} sx={{ mt: 1, ml: 0.5 }}>
        <path d="M 17.777344 1.125 L 2.222656 1.125 C 0.996094 1.125 0 2.132812 0 3.375 L 0 14.625 C 0 15.867188 0.996094 16.875 2.222656 16.875 L 17.777344 16.875 C 19.003906 16.875 20 15.867188 20 14.625 L 20 3.375 C 20 2.132812 19.003906 1.125 17.777344 1.125 Z M 6.109375 4.5 C 7.339844 4.5 8.332031 5.507812 8.332031 6.75 C 8.332031 7.992188 7.339844 9 6.109375 9 C 4.882812 9 3.890625 7.992188 3.890625 6.75 C 3.890625 5.507812 4.886719 4.5 6.109375 4.5 Z M 9.445312 13.5 L 2.777344 13.5 C 2.472656 13.5 2.222656 13.246094 2.222656 12.9375 C 2.222656 11.382812 3.464844 10.125 5 10.125 L 7.222656 10.125 C 8.757812 10.125 10 11.382812 10 12.9375 C 10 13.246094 9.75 13.5 9.445312 13.5 Z M 17.222656 11.25 L 12.777344 11.25 C 12.472656 11.25 12.222656 10.996094 12.222656 10.6875 C 12.222656 10.378906 12.472656 10.125 12.777344 10.125 L 17.222656 10.125 C 17.527344 10.125 17.777344 10.378906 17.777344 10.6875 C 17.777344 10.996094 17.527344 11.25 17.222656 11.25 Z M 17.222656 9 L 12.777344 9 C 12.472656 9 12.222656 8.746094 12.222656 8.4375 C 12.222656 8.128906 12.472656 7.875 12.777344 7.875 L 17.222656 7.875 C 17.527344 7.875 17.777344 8.128906 17.777344 8.4375 C 17.777344 8.746094 17.527344 9 17.222656 9 Z M 17.222656 6.75 L 12.777344 6.75 C 12.472656 6.75 12.222656 6.496094 12.222656 6.1875 C 12.222656 5.878906 12.472656 5.625 12.777344 5.625 L 17.222656 5.625 C 17.527344 5.625 17.777344 5.878906 17.777344 6.1875 C 17.777344 6.496094 17.527344 6.75 17.222656 6.75 Z M 17.222656 6.75 " />
      </SvgIcon>
    );
  }

  const dataGridColumns: GridColumns = [
    {
      field: 'id',
      filterable: false,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Link href={'mailto:' + params.row.email}>{params.row.email}</Link>
        </Tooltip>
      ),
    },
    {
      field: 'emailVerified',
      headerName: 'Verified',
      type: 'boolean',
      width: 100,
    },
    {
      field: 'unsubscribed',
      headerName: 'Unsubscribed',
      type: 'boolean',
      width: 100,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
      renderCell: (params) => <Link href={'tel:' + params.row.phone}>{params.row.phone}</Link>,
    },
    {
      field: 'createdAt',
      filterable: false,
      headerName: 'Created',
      flex: 1,
      type: 'dateTime',
      renderCell: (params) => <Typography>{fToNow(params.row.createdAt)}</Typography>,
    },
  ];

  if (showActions) {
    dataGridColumns.push({
      field: 'details',
      filterable: false,
      headerName: 'Details',
      renderCell: (params) => (
        <Box
          width={'100%'}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Tooltip title="Details">
            <IconButton onClick={() => navigate(`/contacts/${params.row.id}`)}>
              <ViewIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    });
    dataGridColumns.push({
      field: 'actions',
      filterable: false,
      headerName: 'Actions',
      renderCell: (params) => (
        <DataGridActions
          apiSuffix={apiSuffix}
          modelName={modelName}
          entityId={params.row.id}
          afterDelete={loadData}
          updatePermissionName={'update.contacts'}
        />
      ),
    });
  }

  const loadData = async (filters?: {
    filterSearch: string;
    filterOperator: string;
    filterField: string;
  }) => {
    try {
      const queryParams = (apiPath: string) =>
        `${apiPath}?search=${search}&pageSize=${pageSize}&page=${page}&signature=${
          JWTContext?.user ? JWTContext?.user.companyId : ''
        }`;
      let resp: any = [];
      let comp: any = [];
      setIsLoading(true);
      if (filters) {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&filterSearch=${filters.filterSearch}&filterOperator=${
            filters.filterOperator
          }&filterField=${filters.filterField}&selectedCompany=${selectedCompany}`
        );
        const companyPromise = isGlobalAdmin ? apiService.get('company') : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      } else {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&selectedCompany=${selectedCompany}`
        );
        const companyPromise: any = isGlobalAdmin ? apiService.get('company') : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      }
      if (resp.data.length === 0) {
        setRowCount(0);
        setDataGridRows([]);
        setIsLoading(false);
        return;
      }
      // const responseData = resp.data.results.map(
      //   (contact: { createdAt: string | number | Date }, index: any) => ({
      //     ...contact,
      //     // createdAt: fToNow(contact.createdAt),
      //   })
      // );
      const responseData = resp.data.map(
        (contact: { createdAt: string | number | Date }, index: any) => ({
          ...contact,
          // createdAt: fToNow(contact.createdAt),
        })
      );
      if (isGlobalAdmin) setCompanies(comp.data);

      setRowCount(resp.data.total);
      setDataGridRows(responseData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    if (
      filterModel.items[0].value ||
      filterModel.items[0].operatorValue === 'isEmpty' ||
      filterModel.items[0].operatorValue === 'isNotEmpty'
    ) {
      loadData({
        filterSearch: filterModel.items[0].value,
        filterOperator: String(filterModel.items[0].operatorValue),
        filterField: filterModel.items[0].columnField,
      }).then();
    } else {
      loadData().then();
    }
  }, []);

  useEffect(() => {
    loadData().then();
    return () => window.localStorage.removeItem('selectCp');
  }, [search, page, pageSize, selectedCompany]);

  const handleRowSelections = (selectionModel: GridSelectionModel) => {
    saveRowIds(selectionModel as string[]);
    selectPhones(selectionModel);
  };

  const selectPhones = (ids: any) => {
    const newPhones = [];
    for (const id of ids) {
      for (const contact of dataGridRows) {
        if (contact.id === id) {
          newPhones.push(contact.phone);
          break;
        }
      }
    }
    setSelectedContacts(newPhones);
  };

  return (
    <DataGridWrapper pageTitle={'Contacts'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack>
          {showTopBar && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <HeaderBreadcrumbs
                heading="Contacts"
                links={[
                  { name: 'Home', href: PATH_DASHBOARD.root },
                  { name: 'Contacts', href: PATH_DASHBOARD.website.contacts },
                ]}
              />
              {canI(permissions, 'create.contacts', isSuperAdmin) && !isGlobalAdmin && (
                <div>
                  <Button variant={'contained'} component={LI} to={`${pathname}/new`}>
                    <AddIcon /> NEW
                  </Button>
                </div>
              )}
            </Stack>
          )}

          <Stack direction={'row'} justifyContent={'flex-start'}>
            <Grid item xs={12} md={4} sm={6}>
              <TextField
                placeholder="Search"
                onChange={debounce((e) => setSearch(e.target.value), 1000)}
                sx={{ m: 1, width: '100%' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            {isGlobalAdmin && (
              <Grid item xs={12} md={4} sm={6}>
                <FormControl fullWidth sx={{ m: 1, width: '100%', marginLeft: '1rem' }}>
                  <InputLabel id="demo-simple-select-label">Companies</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedCompany}
                    label="Company"
                    onChange={(e) => {
                      setSelectedCompany(e.target.value);
                      window.localStorage.setItem('selectCp', e.target.value);
                    }}
                  >
                    <MenuItem value="">All Companies</MenuItem>
                    {companies.map((el: any, index: any) => (
                      <MenuItem key={el.id} value={el.id}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Stack>
          <DataGrid
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                rowIds,
                removing,
                canDoBulkRemove: canI(permissions, 'delete.contacts', isSuperAdmin),
                onBulkRemove: requestBulkRemove,
                setFilterButtonEl,
              },
            }}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            autoHeight={true}
            density={'comfortable'}
            checkboxSelection={true}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            columns={dataGridColumns}
            rows={dataGridRows}
            rowCount={rowCount}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
            rowsPerPageOptions={[15, 50, 100]}
            selectionModel={rowIds as GridSelectionModel}
            onSelectionModelChange={handleRowSelections}
            pagination
            {...dataGridRows}
            // paginationMode="server"
            loading={loading}
            keepNonExistentRowsSelected
          />
        </Stack>
      </Container>
    </DataGridWrapper>
  );
}

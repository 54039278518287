import React, { useMemo, useState } from 'react';
import { Container, Grid, Box, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import useSettings from '../../../hooks/useSettings';
import { post } from 'src/api/api';
import HandlerSteperBtn from 'src/components/newsletter/HandlerSteperBtn';
import { steps, prev, next, setLoading } from 'src/redux/slices/bulkSms';
import { useSelector } from 'src/redux/store';
import useAuth from 'src/hooks/useAuth';

const SendSms = () => {
  const { themeStretch } = useSettings();
  const [message, setMessage] = useState('');
  const contactPhone = useSelector((state) => state.bulkSms.contactPhones);
  const subscriberPhones = useSelector((state) => state.bulkSms.subscriberPhones);
  const csvPhones = useSelector((state) => state.bulkSms.csvPhones);
  const urls = useSelector((state) => state.bulkSms.urls);
  const { enqueueSnackbar } = useSnackbar();
  const { company } = useAuth();

  const sendMessage = async () => {
    try {
      setLoading(true);
      await post('sms/bulk', {
        body: message,
        phoneNumbers: [...contactPhone, ...subscriberPhones, ...csvPhones],
        mediaUrl: urls,
        sid:
          company && typeof company.config !== 'undefined' ? company.config.TWILIO_ACCOUNT_SID : '',
        token:
          company && typeof company.config !== 'undefined' ? company.config.TWILIO_AUTH_TOKEN : '',
      });

      setLoading(false);
      enqueueSnackbar(`The message was sent successfully!`);
      window.location.reload();
    } catch (error) {
      setLoading(false);
      console.log(error);
      enqueueSnackbar(`Ops, something went wrong!`, {
        variant: 'error',
      });
    }
  };

  const totalMessagesToSend = useMemo(() => {
    return contactPhone.length + subscriberPhones.length + csvPhones.length;
  }, [contactPhone, subscriberPhones, csvPhones]);

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7} style={{ marginTop: '0.5rem' }}>
              <TextField
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                multiline={true}
                minRows={3}
                label="Type the message"
                variant="outlined"
              />
            </Grid>
            <Grid item md={12}>
              {`You will send ${totalMessagesToSend} messages`}
            </Grid>
          </Grid>
        </Stack>
      </Container>
      <HandlerSteperBtn
        config={{ steps, prev, next, sliceName: 'bulkSms' }}
        cnext={false}
        labelNext={'Next'}
        showLastStep={message.length > 0}
        confirmFunction={sendMessage}
      />
    </>
  );
};

SendSms.label = 'Send Bulk SMS';

export default SendSms;

import React, { useState } from 'react';
import Editor from '../../editor';
import HandlerSteperBtn from '../HandlerSteperBtn';
import { useSelector, useDispatch } from '../../../redux/store';
import TextField from '@mui/material/TextField';
import { setTitle, steps, prev, next, setSchedule, setSchDate } from 'src/redux/slices/newslatter';
import DatePicker from '@mui/lab/DatePicker';
import { Box, Checkbox, FormControlLabel } from '@mui/material';

const FillData = () => {
  const dispatch = useDispatch();
  const [tl, setTl] = useState('');
  const data = useSelector((state) => state.newsLetter.fillHtml);
  const isSch = useSelector((state) => state.newsLetter.isSchedule);
  const sd = useSelector((state) => state.newsLetter.scheduleDate);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setTitle(event.target.value));
    setTl(event.target.value);
  };

  return (
    <>
      <Box width={'100%'} marginTop={2} display="flex" justifyContent={'space-between'}>
        <FormControlLabel
          style={{
            marginLeft: '0',
          }}
          control={<Checkbox checked={isSch} onChange={setSchedule} />}
          label="Schedule the newsletter"
        />
        {isSch && (
          <DatePicker
            inputFormat="dd/MM/yyyy"
            disablePast={true}
            label="Select Date"
            openTo="day"
            views={['month', 'day']}
            value={sd}
            onChange={(newValue) => {
              if (newValue) {
                setSchDate(newValue);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        )}
      </Box>
      <TextField
        onChange={handleChange}
        sx={{ width: '100%', marginTop: '1rem', marginBottom: '2rem' }}
        label="Title"
        variant="outlined"
      />
      <Editor />
      <HandlerSteperBtn
        config={{ steps, prev, next, sliceName: 'newsLetter' }}
        cnext={data?.length > 0 && data !== '<p><br></p>' && tl.length > 0}
        labelNext={'Next'}
      />
    </>
  );
};

FillData.label = 'Fill Data';

export default FillData;

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { setBookingStatus, setSteperTo0 } from 'src/redux/slices/publicAppointment';

const Success = () => (
  <Box
    sx={{
      width: '100%',
      height: 'calc(100vh - 2rem)',
      display: 'flex',
      flexDirection: 'column',
      alignContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
    }}
  >
    <Iconify
      icon={'clarity:success-line'}
      marginRight={1}
      color="#229A16"
      style={{ width: '10rem', height: '10rem', marginBottom: '10px' }}
    />
    <Typography variant="h4">Your booking is all set.</Typography>
    <Button
      onClick={() => {
        setSteperTo0();
        setBookingStatus(false);
      }}
      style={{ width: '90%', marginTop: '3rem' }}
      variant="contained"
    >
      Make another booking
    </Button>
  </Box>
);

export default Success;

import { lazy } from 'react';
import GuestGuard from '../guards/GuestGuard';
import PublicAppointment from '../pages/appointment/PublicAppointment/index';
import Loadable from './Loadable';
import ClientReviewApproval from 'src/pages/client-review-approval';

const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const ResetPass = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetPasswordForm = Loadable(lazy(() => import('../pages/auth/ResetPasswordFormAfterEmail')));

export const authRoute = {
  path: 'auth',
  children: [
    {
      path: 'login',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
    },
    {
      path: 'resetPassword',
      element: (
        <GuestGuard>
          <ResetPass />
        </GuestGuard>
      ),
    },
    {
      path: 'reset-password/:srtk',
      element: (
        <GuestGuard>
          <ResetPasswordForm />
        </GuestGuard>
      ),
    },
    { path: 'login-unprotected', element: <Login /> },
  ],
};

export const publicAppointment = {
  path: '/',
  children: [
    {
      path: 'booking',
      element: (
        <GuestGuard>
          <PublicAppointment />
        </GuestGuard>
      ),
    },
  ],
};

export const clientReviewApproval = {
  path: '/client-review-approval',
  element: <ClientReviewApproval />,
};

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from '../../../redux/store';
import { next, selectTemplateAction, selectPreview } from '../../../redux/slices/newslatter';
import { Chip, Grid } from '@mui/material';
import Image from 'src/components/Image';
import Iconify from 'src/components/Iconify';
import { alpha, styled } from '@mui/material/styles';
import { PreviewDialog } from '../PreviewDialog';
import { grey } from '@mui/material/colors';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.primary.main,
  backgroundColor: alpha(theme.palette.primary.main, 0.08),
}));

const SelectTemplate = () => {
  const rawHtml = useSelector((state) => state.newsLetter.selectedTemplate);
  const isLoading = useSelector((state) => state.newsLetter.isLoading);
  const previewHtml = rawHtml.filter((e) => e.selectedPreview);
  const [open, setOpen] = useState<boolean>(false);
  const dispatch = useDispatch();
  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={2}>
      <PreviewDialog
        openDialog={open}
        closeDialog={() => setOpen(false)}
        html={previewHtml && previewHtml.length && previewHtml[0].html}
      />
      {isLoading && (
        <Typography style={{ marginTop: '1rem' }} variant="body1">
          ...Loading
        </Typography>
      )}
      {rawHtml.length === 0 && !isLoading && (
        <Typography style={{ marginTop: '1rem' }} variant="body1">
          No templates found
        </Typography>
      )}
      {rawHtml &&
        rawHtml.map((item, index) => (
          <Grid key={index} item xs={12} md={6} marginBottom={2}>
            <Box
              key={index}
              sx={{
                position: 'relative',
                '&:hover .overlayComp': {
                  display: 'block',
                  color: 'purple',
                  opacity: 0.8,
                },
              }}
            >
              {item.selected && item.selected && (
                <Box style={{ position: 'absolute', left: -15, top: -15, zIndex: 10 }}>
                  <IconWrapperStyle
                    sx={{
                      color: 'success.darker',
                      bgcolor: (theme) => alpha(theme.palette.success.darker, 0.8),
                    }}
                  >
                    <Iconify icon={'clarity:success-line'} color={'white'} width={20} height={20} />
                  </IconWrapperStyle>
                </Box>
              )}

              <Image
                alt={'img'}
                src={item.thumbnail}
                ratio={'16/9'}
                sx={{
                  borderRadius: 1,
                  border: 1,
                  borderColor: (t) => t.palette.divider,
                  boxShadow: (t) => t.shadows[1],
                }}
              />
              <Box
                className="overlayComp"
                sx={{
                  transition: 'all 0.3s ease-out',
                  color: 'text.secondary',
                  opacity: 0,
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  top: 0,
                  backgroundColor: '#000',
                  borderRadius: 1,
                }}
              >
                <Box
                  style={{
                    transform: 'translate(-50%, -50%)',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                  }}
                >
                  <Button
                    color={item.selected && item.selected ? 'secondary' : 'primary'}
                    onClick={() => {
                      selectTemplateAction(item.id);
                      next();
                    }}
                    size="small"
                  >
                    {item.selected && item.selected ? 'SELECTED' : 'SELECT'}
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(selectPreview(item.id));
                      setOpen(true);
                    }}
                    color={item.selected && item.selected ? 'secondary' : 'primary'}
                    size="small"
                  >
                    PREVIEW
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
    </Grid>
  );
};

SelectTemplate.label = 'Select Template';

export default SelectTemplate;

import { IconButton, Stack, Tooltip } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import useModal from '../hooks/useModal';
import { useLocation, Link } from 'react-router-dom';
import { FC, useEffect, useState } from 'react';
import * as apiService from 'src/api/api';
import { useSnackbar } from 'notistack';
import { BookAssessmentModal } from 'src/sections/cruds/dialogs/bookAssessmentModal';
import { canI } from 'src/utils/canI';
import useAuth from 'src/hooks/useAuth';
import { RepairServiceModal } from 'src/sections/cruds/dialogs/repairServiceModal';

type DataGridActionsProps = {
  entityId: string;
  apiSuffix: string;
  modelName: string;
  afterDelete: VoidFunction;
  updatePermissionName: string;
  permissions?: string[];
  deletePath?: string;
};
type BookAssessmentProps = {
  createBy: string;
  email: string;
  service: string;
  company: string;
  status: string;
  createdAt: string | number | Date;
};

type RepairServiceProps = {
  name: string;
  description: string;
  createdAt: string | number | Date;
};

export const GetUrlExternalSingleParam = (search: string, param: string) => {
  const searchParams = new URLSearchParams(search);
  return searchParams.get(param);
};
export const DataGridActions: FC<DataGridActionsProps> = ({
  entityId,
  apiSuffix,
  modelName,
  afterDelete,
  updatePermissionName,
  permissions,
  deletePath,
}) => {
  const { showModal, closeModal } = useModal();
  const { pathname, search } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [bookAssessment, setBookAssessment] = useState<BookAssessmentProps>({
    createBy: '',
    email: '',
    service: '',
    company: '',
    status: '',
    createdAt: '',
  });
  const [repairService, setRepairService] = useState<RepairServiceProps>({
    name: '',
    description: '',
    createdAt: '',
  });
  const [open, setOpen] = useState(false);
  const { user } = useAuth();

  const externalRequestBookAssesmentId = GetUrlExternalSingleParam(search, 'externalBAId');

  const externalRequestRepairServiceId = GetUrlExternalSingleParam(search, 'externalRSId');

  const isSuperAdmin = user ? user.isSuperAdmin : false;

  const removeNamePermission = updatePermissionName.split('.')[1];

  const path = pathname.split('/');
  const baseUrl = window.location.origin;
  const isJobPath =
    (path[path.length - 1] === 'job-roles' && path[1] === 'jobs') ||
    (path[path.length - 1] === 'edit' && path[1] === 'jobs');

  const isBookAssessmentPath = path[path.length - 1] === 'book-assessments';

  const isRepairServicePath = path[path.length - 1] === 'repair-services';

  const onDelete = async () => {
    closeModal();
    try {
      isJobPath
        ? await apiService.remove(`job-roles/${entityId}`)
        : await apiService.remove(deletePath ?? `${apiSuffix}/${entityId}`);
      enqueueSnackbar(`${modelName} deleted successfully`);

      afterDelete();
    } catch (e) {
      console.log(e);
      enqueueSnackbar(`Error deleting ${modelName}, please try again later`, {
        variant: 'error',
      });
    }
  };

  const onRequestDelete = () => {
    showModal({
      mainText: `Do you want to delete selected ${modelName} for ever?`,
      onAccept: onDelete,
      onCancel: closeModal,
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const loadBookAssessment = async () => {
    let resp: any;
    if (externalRequestBookAssesmentId) {
      resp = await apiService.get(`book-assessments/${externalRequestBookAssesmentId}`);
    } else {
      resp = await apiService.get(`book-assessments/${entityId}`);
    }
    if (resp) {
      const bookAssessmentOk = {
        ...resp.data,
        // createdAt: fToNow(resp.data.createdAt),
      };
      setBookAssessment(bookAssessmentOk);
    }
    return;
  };
  useEffect(() => {
    if (isBookAssessmentPath) {
      loadBookAssessment()
        .then((e) => {
          if (externalRequestBookAssesmentId) {
            setOpen(true);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [entityId]);

  const loadRepairService = async () => {
    let resp: any;
    if (externalRequestRepairServiceId) {
      resp = await apiService.get(`repair-services/${externalRequestRepairServiceId}`);
    } else {
      resp = await apiService.get(`repair-services/${entityId}`);
    }
    if (resp) {
      const repairserviceOk = {
        ...resp.data,
        // createdAt: fToNow(resp.data.createdAt),
      };
      setRepairService(repairserviceOk);
    }
    return;
  };
  useEffect(() => {
    if (isRepairServicePath) {
      loadRepairService()
        .then((e) => {
          if (externalRequestRepairServiceId) {
            setOpen(true);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [entityId]);

  return (
    <Stack direction={'row'}>
      {isBookAssessmentPath && canI(permissions || [], 'index.book-assessments', isSuperAdmin) ? (
        <>
          <Tooltip title="Details">
            <IconButton color={'info'} onClick={handleClickOpen}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <BookAssessmentModal
            openDialog={open}
            closeDialog={handleClose}
            bookAssessment={bookAssessment}
          />
        </>
      ) : (
        ''
      )}
      {isRepairServicePath && canI(permissions || [], 'index.repair-service', isSuperAdmin) ? (
        <>
          <Tooltip title="Details">
            <IconButton color={'info'} onClick={handleClickOpen}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
          <RepairServiceModal
            openDialog={open}
            closeDialog={handleClose}
            repairService={repairService}
          />
        </>
      ) : (
        ''
      )}
      <Tooltip title="Edit">
        {isJobPath && canI(permissions || [], 'update.jobs-role', isSuperAdmin) ? (
          <IconButton
            color={'default'}
            onClick={() => window.location.replace(`${baseUrl}/job-roles/${entityId}/edit`)}
          >
            <EditIcon />
          </IconButton>
        ) : canI(permissions || [], updatePermissionName, isSuperAdmin) ? (
          <IconButton color={'default'} component={Link} to={`${pathname}/${entityId}/edit`}>
            <EditIcon />
          </IconButton>
        ) : (
          <></>
        )}
      </Tooltip>

      {canI(permissions || [], `delete.${removeNamePermission}`, isSuperAdmin) ? (
        <Tooltip title="Delete">
          <IconButton color={'error'} onClick={onRequestDelete}>
            <DeleteForeverIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <></>
      )}
    </Stack>
  );
};

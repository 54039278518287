import React from 'react';
import { Stepper, Step, StepLabel, Button, Container, Box } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { styled } from '@mui/material/styles';
import { useSelector } from '../../../redux/store';

import {
  prev,
  setBookingStatus,
  setBoookingError,
  setLoading,
  steps,
} from 'src/redux/slices/publicAppointment';
import { addMinutes, convertStartDate } from 'src/utils/formatTime';
import { appConfig } from 'src/config/conf';
import * as apiService from 'src/api/api';
import { LoadingButton } from '@mui/lab';
import Success from './Success';
import Error from './Error';

const ContainerStyles = styled(Container)({
  margin: 'auto',
  height: 'auto',
  paddingTop: '2rem',
});

const PublicAppointment = () => {
  const activeStep = useSelector((state) => state.publicAppointment.steper);
  const calendarDate = useSelector((state) => state.publicAppointment.calendarDate);
  const selectedSchedule = useSelector((state) => state.publicAppointment.selectedSchedule);
  const user = useSelector((state) => state.publicAppointment.user);
  const isLoading = useSelector((state) => state.publicAppointment.isLoading);
  const isSuccess = useSelector((state) => state.publicAppointment.successBooking);
  const hasError = useSelector((state) => state.publicAppointment.error);
  const selectedS = selectedSchedule.filter((e) => e.isActive);

  const isLastStep = () => activeStep === steps.length - 1;

  const storeBooking = async () => {
    const startDate = convertStartDate(selectedS[0]?.date, calendarDate);
    const endDate = addMinutes(startDate, appConfig.publicAppointment.minutesPerTurns);

    setLoading(true);

    await apiService
      .post('appointments/store', {
        startDate,
        endDate,
        title: 'Booking Public',
        user,
      })
      .then((res) => {
        setLoading(false);
        setBookingStatus(true);
      })
      .catch((e) => {
        setLoading(false);
        setBookingStatus(false);
        setBoookingError(true,e);
      });
  };

  return (
    <ContainerStyles>
      {hasError.error && <Error/>}
      {isSuccess && (
        <Success />
      ) }
      {!hasError.error && !isSuccess && (
        <>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{steps[index].label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ width: '70%', margin: 'auto', padding: '5px' }}>
            <SwipeableViews disabled={true} index={activeStep}>
              {steps.map((step, index) => {
                const Component = steps[index];
                if (index === 1) {
                  return <Component key={index} />;
                }
                return <Component key={index} />;
              })}
            </SwipeableViews>
          </Box>
          <Box
            sx={{
              width: '70%',
              margin: 'auto',
              padding: '5px',
              paddingTop: '10px',
              paddingBottom: '2rem',
            }}
            justifyContent={'space-between'}
            display={'flex'}
          >
            {activeStep > 0 && (
              <Button disabled={activeStep === 0} onClick={prev}>
                Back
              </Button>
            )}

            {isLastStep() && (
              <LoadingButton loading={isLoading} variant="outlined" onClick={() => storeBooking()}>
                Confirm Booking
              </LoadingButton>
            )}
          </Box>
        </>
      )}
    </ContainerStyles>
  );
};

export default PublicAppointment;

import axios from 'axios';
import { Types } from 'src/contexts/Auth0Context';
import { dispatch } from 'src/redux/store';
// config
import { HOST_API } from '../config';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
});

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.message === 'Network Error') {
      return Promise.reject((error.response && error.response.data) || 'Network Error');
    }
    const originalRequest = error.config;
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      delete axios.defaults.headers.common.Authorization;
      dispatch({ type: Types.logout });
      window.localStorage.removeItem('accessToken');
      window.localStorage.removeItem('cmp');
      window.location.href = "/";
      return axiosInstance(originalRequest);
    }
    return Promise.reject((error.response && error.response.data) || 'Something went wrong');
  }
);

export default axiosInstance;

import React, { useMemo, useState } from 'react';

import { Grid, Box, Stack } from '@mui/material';
import FormProvider from '../../../../components/hook-form/FormProvider';
import RHFTextField from '../../../../components/hook-form/RHFTextField';
import { LoadingButton } from '@mui/lab';

import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { next, setUserG } from 'src/redux/slices/publicAppointment';
import { useSelector } from 'src/redux/store';
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/material.css'
import { Phone, PhoneRequired, PhoneRequiredMessage } from "../../../crud/callbackRequest/CallbackRequestEditor";

type FormValuesProps = {
  name: string;
  lastName: string;
  phone: string;
  email: string;
  company: string;
};

const UserInfo = () => {

  const user = useSelector((state) => state.publicAppointment.user);
  const [requiredPhone, setRequiredPhone] = useState(false);

  const ClientSchema = Yup.object().shape({
    name: Yup.string().max(50).required('Name is required'),
    lastName: Yup.string().max(150).required('Last name is required'),
    phone: Yup.string(),
    email: Yup.string().required('Email is required').email(),
    company: Yup.string().required('Company is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: user?.name,
      lastName: user?.lastName,
      phone: user?.phone,
      email: user?.email,
      company: user?.company,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ClientSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = (data: FormValuesProps) => {
    const dataplus = {
      name: data.name,
      lastName: data.lastName,
      phone: '+' + data.phone,
      email: data.email,
      company: data.company
    }

    if (data.phone === '' || data.phone === undefined || data.phone === null){
      setRequiredPhone(true);
      return;
    }else{
      setRequiredPhone(false);
    }

    setUserG(dataplus);
    next();
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} justifyContent={'center'} paddingTop={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'grid',
              columnGap: 2,
              rowGap: 3,
              gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
            }}
          >
            <RHFTextField name="name" label="Name *" />
            <RHFTextField name="lastName" label="Last Name *" />
            <Controller
              name="phone"
              control={control}
              render={({ field }) =>
                requiredPhone ? (
                  <>
                    <PhoneRequired>
                      <PhoneInput
                        {...field}
                        country={'us'}
                        specialLabel="Phone Number *"
                        placeholder=" +1 (702) 123-4567"
                        inputProps={{
                          id: 'phone-input',
                        }}
                        enableSearch={true}
                      />
                      <PhoneRequiredMessage>Phone number is required</PhoneRequiredMessage>
                    </PhoneRequired>
                  </>
                ) : (
                  <Phone>
                    <PhoneInput
                      {...field}
                      country={'us'}
                      specialLabel="Phone Number *"
                      placeholder=" +1 (702) 123-4567"
                      inputProps={{
                        id: 'phone-input',
                      }}
                      enableSearch={true}
                    />
                  </Phone>
                )
              }
            />
            <RHFTextField name="email" label="Email *" />
            <RHFTextField name="company" label="Company *" />
          </Box>

          <Stack alignItems="center" sx={{ mt: 3 }}>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Continue
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
UserInfo.label = 'Your Information';

export default UserInfo;

// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgIconStyle from '../../../components/SvgIconStyle';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import CampaignRoundedIcon from '@mui/icons-material/CampaignRounded';
import DesignServicesRoundedIcon from '@mui/icons-material/DesignServicesRounded';
import Groups2RoundedIcon from '@mui/icons-material/GroupsRounded';
import AdminPanelSettingsRoundedIcon from '@mui/icons-material/AdminPanelSettingsRounded';
import CasesRoundedIcon from '@mui/icons-material/CasesRounded';
import TrendingUpRoundedIcon from '@mui/icons-material/TrendingUpRounded';
import SourceRoundedIcon from '@mui/icons-material/SourceRounded';
import RateReviewIcon from '@mui/icons-material/RateReview';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  subscribers: getIcon('ic_subscribers'),
  contacts: getIcon('ic_contacts'),
  callbackRequests: getIcon('ic_callback'),
  serviceRequests: getIcon('ic_service_request'),
  campaign: getIcon('ic_campaign'),
  promoCodes: getIcon('ic_promocode'),
  clients: getIcon('ic_client'),
  jobs: getIcon('ic_job'),
  jobRoles: getIcon('ic_job_role'),
  departments: getIcon('ic_department'),
  bookAssessments: getIcon('ic_assessment'),
  tag: getIcon('ic_tag'),
  role: getIcon('ic_role'),
  post: getIcon('ic_post'),
  categories: getIcon('ic_category'),
  repairServices: getIcon('ic_repair_service'),
};

export interface RoutesGroup {
  subheader: string;
  items: Route[];
}

export interface Route {
  title: string;
  icon: JSX.Element;
  children: SubRoute[];
}

export interface SubRoute {
  title: string;
  path: string;
  icon: JSX.Element;
  slug: string;
  superAdminRoute: boolean;
  rootAccessByPermission?: string | string[];
}

// const linksWebsite = [
//   // {
//   //   title: 'book assessments',
//   //   path: PATH_DASHBOARD.website.bookAssessments,
//   //   icon: ICONS.bookAssessments,
//   //   show: true,
//   //   slug: 'WEBSITE_MODULE',
//   //   superAdminRoute: false,
//   //   rootAccessByPermission: 'index.book-assessments',
//   // },
//   {
//     title: 'service requests',
//     path: PATH_DASHBOARD.website.serviceRequests,
//     icon: ICONS.serviceRequests,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.service-request',
//   },
//   {
//     title: 'campaigns',
//     path: PATH_DASHBOARD.website.campaign,
//     icon: <CampaignRoundedIcon />,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.campaign',
//   },
//   {
//     title: 'promo codes',
//     path: PATH_DASHBOARD.website.promoCodes,
//     icon: <LocalActivityIcon />,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.promo-code',
//   },
//   {
//     title: 'callback requests',
//     path: PATH_DASHBOARD.website.callbackRequests,
//     icon: ICONS.callbackRequests,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.callback-request',
//   },
//   {
//     title: 'subscribers',
//     path: PATH_DASHBOARD.website.subscribers,
//     icon: ICONS.subscribers,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.subscriber',
//   },
//   {
//     title: 'subscriber list',
//     path: PATH_DASHBOARD.website.subscriberList,
//     icon: ICONS.subscribers,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.subscriber-list',
//   },
//   {
//     title: 'contacts',
//     path: PATH_DASHBOARD.website.contacts,
//     icon: ICONS.contacts,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.contacts',
//   },
//   {
//     title: 'tags',
//     path: PATH_DASHBOARD.website.tags,
//     icon: ICONS.tag,
//     show: true,
//     slug: 'WEBSITE_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.tags',
//   },
//   {
//     title: 'categories',
//     path: PATH_DASHBOARD.website.categories,
//     icon: ICONS.categories,
//     show: true,
//     slug: 'POSTS_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.category',
//   },
//   {
//     title: 'posts',
//     path: PATH_DASHBOARD.website.posts,
//     icon: ICONS.post,
//     show: true,
//     slug: 'POSTS_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.post',
//   },
//   {
//     title: 'Sms Bulk',
//     path: PATH_DASHBOARD.website.sms,
//     icon: ICONS.chat,
//     show: true,
//     slug: 'BULK_SMS_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.bulksms',
//   },
//   {
//     title: 'appointments',
//     path: PATH_DASHBOARD.website.appointments,
//     icon: ICONS.calendar,
//     show: true,
//     slug: 'APPOINTMENT_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.appointments',
//   },
//   {
//     title: 'clients',
//     path: PATH_DASHBOARD.website.clients,
//     icon: ICONS.clients,
//     show: true,
//     slug: 'APPOINTMENT_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.clients',
//   },
//   {
//     title: 'Newsletter',
//     path: PATH_DASHBOARD.website.newsletter,
//     icon: ICONS.mail,
//     show: true,
//     slug: 'NEWSLATTER_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.newslatter',
//   },
//   {
//     title: 'Company',
//     path: PATH_DASHBOARD.website.company,
//     icon: ICONS.kanban,
//     show: true,
//     slug: 'NONE',
//   },
//   {
//     title: 'Template',
//     path: PATH_DASHBOARD.website.template,
//     icon: ICONS.blog,
//     show: true,
//     slug: 'NONE',
//   },
//   {
//     title: 'Role',
//     path: PATH_DASHBOARD.website.role,
//     icon: ICONS.role,
//     show: true,
//     slug: 'USER_ADMINISTRATION',
//     superAdminRoute: true,
//   },
//   {
//     title: 'User',
//     path: PATH_DASHBOARD.user.list,
//     icon: ICONS.user,
//     show: true,
//     slug: 'USER_ADMINISTRATION',
//     superAdminRoute: true,
//   },
//   {
//     title: 'Analytics',
//     path: PATH_DASHBOARD.website.analytics,
//     icon: ICONS.analytics,
//     show: true,
//     slug: 'ANALYTICS_MODULE',
//     superAdminRoute: true,
//   },
//   {
//     title: 'repair services',
//     path: PATH_DASHBOARD.website.repairServices,
//     icon: ICONS.repairServices,
//     show: true,
//     slug: 'REPAIR_SERVICES_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.repair-service',
//   },
// ];

// const linksManagement = [
//   {
//     title: 'Departments',
//     path: PATH_DASHBOARD.website.departments,
//     icon: ICONS.departments,
//     show: true,
//     slug: 'JOBS_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.departments',
//   },
//   {
//     title: 'jobs',
//     path: PATH_DASHBOARD.website.jobs,
//     icon: ICONS.jobs,
//     show: true,
//     slug: 'JOBS_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.jobs',
//   },
//   {
//     title: 'jobs roles',
//     path: PATH_DASHBOARD.website.jobRoles,
//     icon: ICONS.jobRoles,
//     show: true,
//     slug: 'JOBS_MODULE',
//     superAdminRoute: false,
//     rootAccessByPermission: 'index.jobs-role',
//   },
// ];

const userManagmentLinks: Route[] = [
  {
    title: 'User Management',
    icon: <AdminPanelSettingsRoundedIcon />,
    children: [
      {
        title: 'Role',
        path: PATH_DASHBOARD.website.role,
        icon: ICONS.role,
        // show: true,
        slug: 'USER_ADMINISTRATION',
        superAdminRoute: true,
      },
      {
        title: 'User',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.user,
        // show: true,
        slug: 'USER_ADMINISTRATION',
        superAdminRoute: true,
      },
      {
        title: 'Company',
        path: PATH_DASHBOARD.website.company,
        icon: ICONS.kanban,
        slug: 'ONLY_US',
        superAdminRoute: false,
      },
    ],
  },
];

const marketingLinks: Route[] = [
  {
    title: 'marketing',
    icon: <TrendingUpRoundedIcon />,
    children: [
      {
        title: 'campaigns',
        path: PATH_DASHBOARD.website.campaign,
        icon: <CampaignRoundedIcon />,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'list.campaign',
      },
      {
        title: 'promo codes',
        path: PATH_DASHBOARD.website.promoCodes,
        icon: <LocalActivityIcon />,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.promo-code',
      },
      {
        title: 'Newsletter',
        path: PATH_DASHBOARD.website.newsletter,
        icon: ICONS.mail,
        // show: true,
        slug: 'NEWSLATTER_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.newslatter',
      },
      {
        title: 'Template',
        path: PATH_DASHBOARD.website.template,
        icon: ICONS.blog,
        slug: 'ONLY_US',
        superAdminRoute: false,
      },
      {
        title: 'Sms Bulk',
        path: PATH_DASHBOARD.website.sms,
        icon: ICONS.chat,
        // show: true,
        slug: 'BULK_SMS_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: ['index.bulksms', 'index.contacts', 'index.subscriber'],
      },
    ],
  },
];

const clientManagmentLinks: Route[] = [
  {
    title: 'Clients Management',
    icon: <Groups2RoundedIcon />,
    children: [
      {
        title: 'clients',
        path: PATH_DASHBOARD.website.clients,
        icon: ICONS.clients,
        // show: true,
        slug: 'APPOINTMENT_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.clients',
      },
      {
        title: 'client reviews',
        path: PATH_DASHBOARD.website.clientReviews,
        icon: <RateReviewIcon />,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.client_review',
      },
      {
        title: 'contacts',
        path: PATH_DASHBOARD.website.contacts,
        icon: ICONS.contacts,
        // show: true,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.contacts',
      },
      {
        title: 'subscribers',
        path: PATH_DASHBOARD.website.subscribers,
        icon: ICONS.subscribers,
        // show: true,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.subscriber',
      },
      {
        title: 'subscriber list',
        path: PATH_DASHBOARD.website.subscriberList,
        icon: ICONS.subscribers,
        // show: true,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.subscriber-list',
      },
      {
        title: 'appointments',
        path: PATH_DASHBOARD.website.appointments,
        icon: ICONS.calendar,
        slug: 'APPOINTMENT_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.appointments',
      },
    ],
  },
];

const contentManagmentLinks: Route[] = [
  {
    title: 'Content Management',
    icon: <SourceRoundedIcon />,
    children: [
      {
        title: 'tags',
        path: PATH_DASHBOARD.website.tags,
        icon: ICONS.tag,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.tags',
      },
      {
        title: 'categories',
        path: PATH_DASHBOARD.website.categories,
        icon: ICONS.categories,
        slug: 'POSTS_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.category',
      },
      {
        title: 'posts',
        path: PATH_DASHBOARD.website.posts,
        icon: ICONS.post,
        slug: 'POSTS_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.post',
      },
    ],
  },
];

const requestsLinks: Route[] = [
  {
    title: 'services',
    icon: ICONS.serviceRequests,
    children: [
      {
        title: 'service requests',
        path: PATH_DASHBOARD.website.serviceRequests,
        icon: <DesignServicesRoundedIcon />,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.service-request',
      },

      {
        title: 'callback requests',
        path: PATH_DASHBOARD.website.callbackRequests,
        icon: ICONS.callbackRequests,
        slug: 'WEBSITE_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.callback-request',
      },
    ],
  },
];

const jobsLinks: Route[] = [
  {
    title: 'Jobs',
    icon: <CasesRoundedIcon />,
    children: [
      {
        title: 'Departments',
        path: PATH_DASHBOARD.website.departments,
        icon: ICONS.departments,
        // show: true,
        slug: 'JOBS_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.departments',
      },
      {
        title: 'jobs',
        path: PATH_DASHBOARD.website.jobs,
        icon: ICONS.jobs,
        // show: true,
        slug: 'JOBS_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.jobs',
      },
      {
        title: 'jobs roles',
        path: PATH_DASHBOARD.website.jobRoles,
        icon: ICONS.jobRoles,
        // show: true,
        slug: 'JOBS_MODULE',
        superAdminRoute: false,
        rootAccessByPermission: 'index.jobs-role',
      },
    ],
  },
];

const navConfig: RoutesGroup[] = [
  {
    subheader: 'website',
    items: [
      ...marketingLinks,
      ...clientManagmentLinks,
      ...contentManagmentLinks,
      ...requestsLinks,
      ...userManagmentLinks,
      ...jobsLinks,
    ],
  },
  // // MANAGEMENT
  // {
  //   subheader: linksManagement.length > 0 ? 'job management' : '',
  //   items: linksManagement.length > 0 ? [...linksManagement] : [],
  // },
  // ----------------------------------------------------------------------
  // {
  //   subheader: 'management',
  //   items: [
  //     // MANAGEMENT : USER
  //     {
  //       title: 'user',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       children: [
  //         { title: 'profile', path: PATH_DASHBOARD.user.profile },
  //         { title: 'cards', path: PATH_DASHBOARD.user.cards },
  //         { title: 'list', path: PATH_DASHBOARD.user.list },
  //         { title: 'create', path: PATH_DASHBOARD.user.newUser },
  //         { title: 'edit', path: PATH_DASHBOARD.user.editById },
  //         { title: 'account', path: PATH_DASHBOARD.user.account },
  //       ],
  //     },
  //
  //     // MANAGEMENT : E-COMMERCE
  //     {
  //       title: 'e-commerce',
  //       path: PATH_DASHBOARD.eCommerce.root,
  //       icon: ICONS.cart,
  //       children: [
  //         { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
  //         { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
  //         { title: 'list', path: PATH_DASHBOARD.eCommerce.list },
  //         { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
  //         { title: 'edit', path: PATH_DASHBOARD.eCommerce.editById },
  //         { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout },
  //         { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice },
  //       ],
  //     },
  //
  //     // MANAGEMENT : BLOG
  //     {
  //       title: 'blog',
  //       path: PATH_DASHBOARD.blog.root,
  //       icon: ICONS.blog,
  //       children: [
  //         { title: 'posts', path: PATH_DASHBOARD.blog.posts },
  //         { title: 'post', path: PATH_DASHBOARD.blog.postById },
  //         { title: 'new post', path: PATH_DASHBOARD.blog.newPost },
  //       ],
  //     },
  //   ],
  // },

  // APP
  // ----------------------------------------------------------------------
];
export default navConfig;

import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import * as apiService from 'src/api/api';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Box, Card, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import { FormProvider, RHFSwitch, RHFTextField, RHFSelect } from '../../../components/hook-form';

import { LoadingButton } from '@mui/lab';
import { PATH_DASHBOARD } from '../../../routes/paths';
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import Page from '../../../components/Page';
import useSettings from '../../../hooks/useSettings';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import styled from 'styled-components';
import useAuth from 'src/hooks/useAuth';
import { canI } from 'src/utils/canI';
import CampaignSelect from 'src/components/campaign/campaign-select';

type FormValuesProps = {
  name?: string;
  phone: string;
  company?: string;
  state?: string;
  campaignId?: string;
};

const apiSuffix = 'callback-requests';
const modelName = 'Callback Request';

export default function CallbackRequestEditor() {
  const navigate = useNavigate();
  const { themeStretch } = useSettings();
  const { enqueueSnackbar } = useSnackbar();
  const { callbackRequestId } = useParams();
  const [callbackRequest, setCallbackRequest] = useState<any>({});
  const [requiredPhone, setRequiredPhone] = useState(false);
  const { user } = useAuth();
  const permissions = user ? user.permissions : [];
  const isSuperAdmin = user ? user.isSuperAdmin : false;

  const CallbackRequestSchema = Yup.object().shape({
    name: Yup.string().max(50),
    phone: Yup.string(),
    company: Yup.string(),
    state: Yup.string(),
    campaignId: Yup.string().nullable(),
  });

  const defaultValues = useMemo(
    () => ({
      name: callbackRequest.name || '',
      phone: callbackRequest.phone || '',
      company: callbackRequest.company || '',
      state: callbackRequest.state || 'pending',
      campaignId: callbackRequest?.campaign?.id ?? null,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [callbackRequest]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(CallbackRequestSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = methods;

  const loadCallbackRequest = async () => {
    const resp = await apiService.get(`${apiSuffix}/${callbackRequestId}`);
    setCallbackRequest(resp.data);
  };

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const dataplus = {
        name: data.name,
        phone: data.phone.includes('+') || data.phone === '' ? data.phone : '+' + data.phone,
        company: data.company,
        campaignId: data?.campaignId,
        signature: user ? user.companyId : '',
      };

      if (data.phone === '' || data.phone === undefined || data.phone === null) {
        setRequiredPhone(true);
        return;
      } else {
        setRequiredPhone(false);
      }

      if (callbackRequestId) {
        await apiService.put(`${apiSuffix}/${callbackRequestId}`, dataplus);
      } else {
        await apiService.post(apiSuffix, dataplus);
      }

      reset();

      const action = callbackRequestId ? 'edited' : 'created';
      enqueueSnackbar(`${modelName} ${action} successfully!`);

      navigate(PATH_DASHBOARD.website.callbackRequests);
    } catch (error) {
      console.error(error);

      const action = callbackRequestId ? 'editing' : 'creating';
      enqueueSnackbar(`Error ${action} ${modelName}, please try again later`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (callbackRequestId) {
      loadCallbackRequest().then();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackRequestId]);

  useEffect(() => {
    if (callbackRequest) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callbackRequest]);
  return (
    <Page title={modelName + ': Create a ' + modelName}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <HeaderBreadcrumbs
          heading={(!callbackRequest ? 'Create ' : 'Edit ') + modelName}
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            { name: 'Callback Requests', href: PATH_DASHBOARD.website.callbackRequests },
            { name: (!callbackRequestId ? 'New ' : 'Edit ') + modelName },
          ]}
        />

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3} justifyContent={'center'}>
            <Grid item xs={12} md={8}>
              <Card sx={{ p: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField name="name" label="Full Name" />
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) =>
                      requiredPhone ? (
                        <>
                          <PhoneRequired>
                            <PhoneInput
                              {...field}
                              country={'us'}
                              specialLabel="Phone Number *"
                              placeholder=" +1 (702) 123-4567"
                              inputProps={{
                                id: 'phone-input',
                              }}
                              enableSearch={true}
                            />
                            <PhoneRequiredMessage>Phone number is required</PhoneRequiredMessage>
                          </PhoneRequired>
                        </>
                      ) : (
                        <Phone>
                          <PhoneInput
                            {...field}
                            country={'us'}
                            specialLabel="Phone Number *"
                            placeholder=" +1 (702) 123-4567"
                            inputProps={{
                              id: 'phone-input',
                            }}
                            enableSearch={true}
                          />
                        </Phone>
                      )
                    }
                  />
                  <RHFTextField name="company" label="Company" />
                  <CampaignSelect
                    name="campaignId"
                    label="Campaign"
                    defaultValue={callbackRequest?.campaign}
                  />
                </Box>
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  {canI(permissions, 'create.callback-request', isSuperAdmin) &&
                    !callbackRequestId && (
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {'Create'}
                      </LoadingButton>
                    )}
                  {canI(permissions, 'update.callback-request', isSuperAdmin) &&
                    callbackRequestId && (
                      <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                        {'Save Changes'}
                      </LoadingButton>
                    )}
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </FormProvider>
      </Container>
    </Page>
  );
}
export const Phone = styled.div`
  #phone-input:focus {
    border-color: #00ab45;
    border-width: 2px;
    box-shadow: none;
  }
`;
export const PhoneRequired = styled.div`
  #phone-input {
    border-color: #ff4842;
    border-width: 1px;
    box-shadow: none;
  }
`;
export const PhoneRequiredMessage = styled.span`
  color: #ff4842;
  font-size: 0.75rem;
  line-height: 1.5;
  font-size: 0.75rem;
  text-align: left;
  margin-left: 14px;
`;

import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFSwitch } from '../../../../components/hook-form';
import useAuth from 'src/hooks/useAuth';
import { DFA } from './modalActivate2Fa';
import { useState, useEffect } from 'react';
import { post } from 'src/api/api';

// ----------------------------------------------------------------------

const APPLICATION_OPTIONS = [{ value: 'twofa', label: 'Two-factor authentication (2FA)' }] as const;

const NOTIFICATION_SETTINGS = {
  twofa: false,
};

// ----------------------------------------------------------------------

type FormValuesProps = {
  twofa: boolean;
};

export const sendCode = async (
  phone: string,
  sid: string,
  token: string,
  id: string,
  enqueueSnackbar: any,
  isLocal?: boolean
) => {
  try {
    await post('auth/sendCode', {
      phone,
      sid,
      token,
      id,
      isLocal: isLocal || false,
    });
    enqueueSnackbar('We have sent a verification code');
    localStorage.setItem('cv', 'sendedcv');
  } catch (error) {
    enqueueSnackbar('We were unable to send your verification code', { variant: 'error' });
    localStorage.setItem('cv', 'error');
    console.error(error);
  }
};

export default function AccountSecurity() {
  const { enqueueSnackbar } = useSnackbar();
  const [openModal, setOpenModal] = useState(false);
  const { company, user } = useAuth();

  const codeConfig = {
    phone: user && user.phone,
    sid: company ? company.config.TWILIO_ACCOUNT_SID : null,
    token: company ? company.config.TWILIO_AUTH_TOKEN : null,
    id: user && user.id,
  };

  const defaultValues = {
    twofa: user ? user.dfactorstatus : NOTIFICATION_SETTINGS.twofa,
  };

  useEffect(() => {
    defaultValues.twofa = user ? user.dfactorstatus : NOTIFICATION_SETTINGS.twofa;
  }, []);

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,

    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const cv = localStorage.getItem('cv');
      if (data.twofa && cv !== 'verify') {
        await sendCode(
          codeConfig.phone,
          codeConfig.sid,
          codeConfig.token,
          codeConfig.id,
          enqueueSnackbar
        );
        return setOpenModal(true);
      }

      await post('company/upFactor', {
        id: user ? user.id : null,
        dfactorstatus: data.twofa,
      });

      if (!data.twofa) {
        localStorage.setItem('cv', '');
      }

      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card sx={{ p: 3 }}>
      <DFA
        openDialog={openModal}
        closeDialog={setOpenModal}
        // pNumber={user ? user.phone : ''}
        sendCode={sendCode}
      />
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={3} alignItems="flex-end">
          <Stack spacing={2} sx={{ width: 1 }}>
            <Typography variant="overline" sx={{ color: 'text.secondary' }}>
              Application
            </Typography>
            <Stack spacing={1}>
              {APPLICATION_OPTIONS.map((application) => (
                <RHFSwitch
                  key={application.value}
                  name={application.value}
                  label={application.label}
                  sx={{ m: 0 }}
                />
              ))}
            </Stack>
          </Stack>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Save Changes
          </LoadingButton>
        </Stack>
      </FormProvider>
    </Card>
  );
}

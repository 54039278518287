// @mui
import { styled } from '@mui/material/styles';
import { Box, Link, Container, Typography, Button } from '@mui/material';

import Page from '../../components/Page';
// sections
import { VerifyCodeForm } from '../../sections/auth/verify-code';
import useAuth from 'src/hooks/useAuth';
import { useEffect } from 'react';
import { sendCode } from 'src/sections/@dashboard/user/account/AccountSecurity';
import { useSnackbar } from 'notistack';
import { Countdown } from 'src/sections/@dashboard/user/account/modalActivate2Fa';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function VerifyCode() {
  const { company, user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { timer, reset } = Countdown(1, 0);

  const codeConfig = {
    phone: user && user.phone,
    sid: company ? company.config.TWILIO_ACCOUNT_SID : null,
    token: company ? company.config.TWILIO_AUTH_TOKEN : null,
    id: user && user.id,
  };
  useEffect(() => {
    const cv = localStorage.getItem('cv');
    const ft = localStorage.getItem('ftrender');

    if (cv !== 'sendedcv' || ft !== 'ok') {
      sendCode(
        codeConfig.phone,
        codeConfig.sid,
        codeConfig.token,
        codeConfig.id,
        enqueueSnackbar,
        true
      );
      localStorage.setItem('ftrender', 'ok');
    }
    reset();
  }, []);

  return (
    <Page title="Verify" sx={{ height: 1, width: '100vw' }}>
      <RootStyle>
        <Container>
          <Box sx={{ maxWidth: 480, mx: 'auto' }}>
            <Typography variant="h3" paragraph>
              Please check your phone!
            </Typography>
            <Typography sx={{ color: 'text.secondary' }}>
              {`We have sended a 6-digit confirmation code to ${
                String(codeConfig.phone).slice(0, 5) +
                String(codeConfig.phone).slice(5).replace(/\d/g, 'X')
              }, please enter the codcompanye in
              below box to verify your phone.`}
            </Typography>

            <Box sx={{ mt: 5, mb: 3 }}>
              <VerifyCodeForm />
            </Box>

            <Typography variant="body2" align="center">
              {`Don’t have a code? Send a new code in ${timer} `}
              <Button
                disabled={timer !== '0:00'}
                onClick={() => {
                  sendCode(
                    codeConfig.phone,
                    codeConfig.sid,
                    codeConfig.token,
                    codeConfig.id,
                    enqueueSnackbar
                  );
                  reset();
                }}
                style={{ transform: 'translateY(-1.2px)' }}
              >
                Resend code
              </Button>
              <Button
                onClick={() => {
                  logout();
                  reset();
                }}
                style={{ marginLeft: 'auto', marginRight: 'auto' }}
              >
                Back to login
              </Button>
            </Typography>
          </Box>
        </Container>
      </RootStyle>
    </Page>
  );
}

import { format, getTime, formatDistanceToNow, add, differenceInMinutes, getDay } from 'date-fns';
import { appConfig } from '../config/conf';

// ----------------------------------------------------------------------

export function fScheduled(date: Date | string | number) {
  return format(new Date(date), 'MMMM do, yyyy');;
}
export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}
export function fDateTimeS(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}
export function FChipsDate(turns: number) {
  let formatDate: any = [];
  let currentDate = new Date();
  currentDate.setHours(8, 0, 0, 0);
  for (let index = 0; index <= turns; index++) {
    formatDate.push({
      isActive: false,
      date: format(currentDate, 'H:mm'),
    });
    currentDate = add(currentDate, { minutes: appConfig.publicAppointment.minutesPerTurns });
  }
  return formatDate;
}

export function timeBetweenHours(start: number, end: number) {
  return differenceInMinutes(
    new Date().setHours(end, 0, 0, 0),
    new Date().setHours(start, 0, 0, 0)
  );
}

export function getDayOfWeek(date: Date) {
  return getDay(date);
}

// get name day of week
export function getDayOfWeekName(date: Date) {
  const day = getDayOfWeek(date);
  switch (day) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return 'Sunday';
  }
}


// select next monday
export function selectNextMonday(date: Date) {
  const day = getDayOfWeek(date);
  if (day === 0) {
    return add(date, { days: 1 });
  }
  if (day === 6) {
    return add(date, { days: 2 });
  }
  return date;
}

export function convertStartDate(time: string,date:Date): Date {
  const [hour, minute] = time.split(':');
  return new Date(date.setHours(parseInt(hour), parseInt(minute), 0, 0));
}

export function addMinutes(date: Date, minutes: number) {
  return add(date, { minutes });
}


import useAuth from 'src/hooks/useAuth';
import { RHFTextField } from '../hook-form';
import * as apiService from 'src/api/api';
import { useEffect, useState } from 'react';
import { Autocomplete, CircularProgress } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'lodash';
import { Campaign } from 'src/types/campaign.types';

const CampaignSelect = ({
  name,
  label,
  defaultValue,
}: {
  label: string;
  name: string;
  defaultValue?: Campaign | null;
}) => {
  const { setValue } = useFormContext<{ campaignId: string | null }>();
  const { user } = useAuth();
  const [campaigns, setCampaigns] = useState<Campaign[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState<Campaign | null>(null);

  const queryCampaigns = async (defaultCampaign?: string | null) => {
    try {
      setLoading(true);
      const queries: Record<string, any> = {
        search,
        page: 0,
        pageSize: 15,
        signature: user?.companyId,
      };
      const queryString = Object.keys(queries).reduce((acc, key) => {
        return acc + `&${key}=${queries[key]}`;
      }, '');

      const res = await apiService.get('campaigns?' + queryString);

      setCampaigns(res.data.results);

      if (defaultCampaign) {
        const findCampaign = res.data.results.find((e: Campaign) => e.id === defaultCampaign);
        if (findCampaign) {
          setSelectedCampaign(findCampaign);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    queryCampaigns();
  }, [search]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedCampaign(defaultValue);
      setSearch(defaultValue.name);
    }
  }, [defaultValue]);

  return (
    <Autocomplete
      id="campaign-select"
      options={campaigns}
      isOptionEqualToValue={(op, value) => op.id === value.id}
      getOptionLabel={(op) => op.name}
      loading={loading}
      filterOptions={(x) => x}
      value={selectedCampaign}
      onChange={(_e, value) => {
        setSelectedCampaign(value);
        setValue('campaignId', value?.id ?? null);
      }}
      renderInput={(params) => (
        <RHFTextField
          name={name}
          {...params}
          label={label}
          onChange={debounce((e) => {
            setSearch(e.target.value);
          }, 500)}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};
export default CampaignSelect;

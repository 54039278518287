export const canI = (
  listOfPermissions: string[],
  permission: string | string[],
  isSuperAdmin: boolean
): boolean => {
  if (isSuperAdmin) {
    return true;
  }

  if (typeof permission === 'string') {
    return listOfPermissions.includes(permission);
  } else {
    return permission.reduce((acc, perm) => {
      if (!listOfPermissions.includes(perm)) {
        return false;
      }
      return acc;
    }, true);
  }
};

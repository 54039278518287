import HandlerSteperBtn from 'src/components/newsletter/HandlerSteperBtn';
import ContactList from '../../../pages/crud/contact/ContactList';
import { steps, prev, next } from 'src/redux/slices/bulkSms';

const SelectContacts = () => {
  return (
    <>
      <ContactList showTopBar={false} showActions={false} />
      <HandlerSteperBtn
        config={{ steps, prev, next, sliceName: 'bulkSms' }}
        cnext={true}
        labelNext={'Next'}
      />
    </>
  );
};
SelectContacts.label = 'Select contacts';
export default SelectContacts;

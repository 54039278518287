import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import selectContacts from '../../components/BulkSms/steps/selectContacts';
import SelectSubscribers from 'src/components/BulkSms/steps/selectSubscribers';
import selectCsv from 'src/components/BulkSms/steps/selectCsv';
import sendSms from 'src/components/BulkSms/steps/sendSms';
import * as apiService from 'src/api/api';

// ----------------------------------------------------------------------
const apiSuffix = 'sms';

type IsmsProps = {
  message: string;
  name: string;
  stack: string;
};

type IErrorProps = {
  error: boolean;
  sms: IsmsProps;
};

type IState = {
  contactPhones: string[];
  subscriberPhones: string[];
  csvPhones: string[];
  urls: string[];
  body: string;
  mediaUrl?: string;
  steper: number;
  isLoading: boolean;
  error: IErrorProps;
  success: boolean;
};

const initialState: IState = {
  contactPhones: [],
  subscriberPhones: [],
  csvPhones: [],
  urls: [],
  body: '',
  mediaUrl: '',
  steper: 0,
  isLoading: false,
  error: {
    error: false,
    sms: {
      message: '',
      name: '',
      stack: '',
    },
  },
  success: false,
};

export const steps = [selectContacts, SelectSubscribers, selectCsv, sendSms];

const slice = createSlice({
  name: 'bulkSms',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    addContactPhones(state, action) {
      state.contactPhones = action.payload;
    },
    addSubscribersPhones(state, action) {
      state.subscriberPhones = action.payload;
    },
    addCsvPhones(state, action) {
      state.csvPhones = action.payload;
    },
    addUrls(state, action) {
      state.urls = action.payload;
    },
    handleNext(state) {
      state.steper = Math.min(state.steper + 1, steps.length - 1);
    },
    handlePrev(state) {
      state.steper = Math.max(state.steper - 1, 0);
    },

    Error(state, action) {
      state.error = {
        error: action.payload.error,
        sms: action.payload.sms,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addContactPhones,
  addSubscribersPhones,
  handlePrev,
  handleNext,
  setIsLoading,
  addCsvPhones,
  addUrls,
} = slice.actions;

// ----------------------------------------------------------------------

export const prev = () => {
  dispatch(slice.actions.handlePrev());
};
export const next = () => {
  dispatch(slice.actions.handleNext());
};

export const setLoading = (isLoading: boolean) => {
  dispatch(slice.actions.setIsLoading(isLoading));
};

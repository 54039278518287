export function getFontColorBasedOnBgColor(bgColor: string) {
  // Convert RGB color to HEX
  let hexColor;
  if (bgColor.startsWith('#')) {
    hexColor = bgColor;
  } else {
    const rgb = bgColor.match(/\d+/g)?.map(Number) ?? [];
    hexColor = `#${((1 << 24) + (rgb[0] << 16) + (rgb[1] << 8) + rgb[2]).toString(16).slice(1)}`;
  }

  // Get the brightness of the background color
  const brightness = getBrightness(hexColor);

  // Set the font color based on the brightness
  return brightness > 128 ? 'black' : 'white';
}

function getBrightness(hexColor: string) {
  let r = parseInt(hexColor.slice(1, 3), 16);
  let g = parseInt(hexColor.slice(3, 5), 16);
  let b = parseInt(hexColor.slice(5, 7), 16);
  // Calculate the brightness using the formula
  return (r * 299 + g * 587 + b * 114) / 1000;
}

import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useAuth from '../../../../hooks/useAuth';
// utils
import { fData } from '../../../../utils/formatNumber';
// _mock
import { countries } from '../../../../_mock';
// components
import {
  FormProvider,
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';
import TextMaxLine from 'src/components/TextMaxLine';

// ----------------------------------------------------------------------
export const nimbusLogo = 'https://www.nimbuspc.com/images/nimbus-logo.webp';

type FormValuesProps = {
  displayName: string;
  email: string;
  photoURL: File | any;
  phoneNumber: string | null;
  country: string | null;
  address: string | null;
  state: string | null;
  city: string | null;
  zipCode: string | null;
  about: string | null;
  isPublic: boolean;
};

export default function AccountGeneral() {
  const { enqueueSnackbar } = useSnackbar();

  const { user, company } = useAuth();

  const isGlobal = user && user.isGlobalAdmin;

  const UpdateUserSchema = Yup.object().shape({
    displayName: Yup.string().required('Name is required'),
  });

  const defaultValues = {
    id: !isGlobal ? (company && company.id) || '' : user.id,
    name: !isGlobal ? (company && company.name) || '' : user.name,
    email: !isGlobal ? (company && company.email) || '' : user.email,
    description: (company && company.description) || '',
    address: (company && company.address) || '',
    phone: !isGlobal ? (company && company.phone) || '' : user.phone,
    fax: (company && company.fax) || '',
    photo: !isGlobal ? (company && company.photo) || '' : nimbusLogo,
    outlookicsurl: (company && company.outlookicsurl) || '',
    state: (company && company.state) || 'Active',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 500));
      enqueueSnackbar('Update success!');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      if (file) {
        setValue(
          'photoURL',
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        );
      }
    },
    [setValue]
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ py: 10, px: 3, textAlign: 'center' }}>
            <RHFUploadAvatar
              name="photo"
              disabled={true}
              accept="image/*"
              maxSize={3145728}
              onDrop={handleDrop}
              // helperText={
              //   <Typography
              //     variant="caption"
              //     sx={{
              //       mt: 2,
              //       mx: 'auto',
              //       display: 'block',
              //       textAlign: 'center',
              //       color: 'text.secondary',
              //     }}
              //   >
              //     Allowed *.jpeg, *.jpg, *.png, *.gif
              //     <br /> max size of {fData(3145728)}
              //   </Typography>
              // }
            />
          </Card>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              sx={{
                display: 'grid',
                rowGap: 3,
                columnGap: 2,
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
              }}
            >
              <RHFTextField name="name" label="Name" disabled={true} />
              <RHFTextField name="email" label="Email Address" disabled={true} />

              <RHFTextField name="phone" label="Phone Number" disabled={true} />
              {!isGlobal && (
                <>
                  <RHFTextField name="address" label="Address" disabled={true} />

                  <RHFTextField name="fax" label="Fax" disabled={true} />
                </>
              )}
            </Box>

            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
              {!isGlobal && (
                <RHFTextField
                  name="description"
                  multiline
                  rows={4}
                  label="Description"
                  disabled={true}
                />
              )}

              {/* <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                Save Changes
              </LoadingButton> */}
            </Stack>
          </Card>
        </Grid>
        {!isGlobal && (
          <Card sx={{ p: 3, marginTop: '2rem' }}>
            <Typography variant="h6" style={{ textAlign: 'center' }}>
              Modules
            </Typography>
            {company &&
              typeof company.modules !== 'undefined' &&
              company.modules.map((val: any, i: number) => (
                <Box key={i} sx={{ marginBottom: '1rem' }}>
                  <Typography variant="subtitle1">{val.name.split('_').join(' ')}</Typography>
                  <TextMaxLine line={5} variant="body1" sx={{ color: '#696969' }}>
                    {val.description}
                  </TextMaxLine>
                </Box>
              ))}
          </Card>
        )}
      </Grid>
    </FormProvider>
  );
}

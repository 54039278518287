import { Suspense, ElementType } from 'react';
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';



const Loadable = (Component: ElementType) => (props: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation();
  
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isAuthenticated } = useAuth();
  
    const isDashboard = pathname.includes('/dashboard') && isAuthenticated;
  
    return (
      <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
        <Component {...props} />
      </Suspense>
    );
  };

  export default Loadable;
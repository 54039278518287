import { lazy, useMemo } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
import AuthGuard from '../guards/AuthGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import { authRoute, clientReviewApproval, publicAppointment } from './routes';
import {
  subscribers,
  serviceRequests,
  campaign,
  promoCodes,
  callbackRequests,
  appointments,
  clients,
  contacts,
  departments,
  jobRroles,
  jobs,
  user,
  bookAssessments,
  newsLetter,
  sms,
  tags,
  company,
  profile,
  template,
  role,
  analytics,
  posts,
  categories,
  subscribersList,
  repairServices,
  clientReviews,
} from './dashboardRoutes';

import Loadable from './Loadable';
import { MODULES } from 'src/utils/modules';
import jwtDecode from 'jwt-decode';
import { canI } from 'src/utils/canI';

// ----------------------------------------------------------------------

const Notifications = Loadable(lazy(() => import('../pages/notifications')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const routes = [
  //auth routes
  authRoute,

  //PUBLIC
  publicAppointment,
  clientReviewApproval,

  // Dashboard Routes
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { element: <Navigate to={'/campaigns'} replace />, index: true },
      subscribers,
      serviceRequests,
      campaign,
      promoCodes,
      clientReviews,
      callbackRequests,
      contacts,
      bookAssessments,
      sms,
      appointments,
      clients,
      newsLetter,
      jobs,
      jobRroles,
      tags,
      user,
      company,
      profile,
      template,
      role,
      analytics,
      posts,
      categories,
      subscribersList,
      repairServices,
      {
        path: 'notifications',
        element: <Notifications />,
      },
      departments,
    ],
  },

  // Main Routes
  {
    path: '*',
    element: <LogoOnlyLayout />,
    children: [
      { path: '500', element: <Page500 /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

export default function Router() {
  let isGlobal = false;
  let isSuperAdmin = false;
  let permissions = [];
  const token = localStorage.getItem('accessToken');
  if (token) {
    const user = jwtDecode<any>(token);
    if (user && user.isGlobalAdmin) {
      isGlobal = true;
    }
    if (user && user.isSuperAdmin) {
      isSuperAdmin = true;
    }
    if (user && user.permissions && user.permissions.length) {
      permissions = user.permissions;
    }
  }

  let allowedRoutePath = '/campaigns';

  const modulePath = [
    // {
    //   moduleName: 'WEBSITE_MODULE',
    //   path: '/book-assessments',
    //   slug: 'index.book-assessments',
    // },
    // {
    //   moduleName: 'WEBSITE_MODULE',
    //   path: '/booking',
    //   slug: 'none',
    // },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/campaigns',
      slug: 'list.campaign',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/promo-codes',
      slug: 'index.promo-code',
    },
    {
      moduleName: 'NEWSLATTER_MODULE',
      path: '/newsletter',
      slug: 'index.newslatter',
    },
    {
      moduleName: 'BULK_SMS_MODULE',
      path: '/sms',
      slug: 'index.bulksms',
    },
    {
      moduleName: 'APPOINTMENT_MODULE',
      path: '/clients',
      slug: 'index.clients',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/client-reviews',
      slug: 'index.client_review',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/contacts',
      slug: 'index.contacts',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/subscribers',
      slug: 'index.subscriber',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/list-subscribers',
      slug: 'index.subscriber-list',
    },
    {
      moduleName: 'APPOINTMENT_MODULE',
      path: '/appointments',
      slug: 'index.appointments',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/tags',
      slug: 'index.tags',
    },
    {
      moduleName: 'POSTS_MODULE',
      path: '/categories',
      slug: 'index.category',
    },
    {
      moduleName: 'POSTS_MODULE',
      path: '/posts',
      slug: 'index.post',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/service-requests',
      slug: 'index.service-request',
    },
    {
      moduleName: 'WEBSITE_MODULE',
      path: '/callback-requests',
      slug: 'index.callback-request',
    },
    {
      moduleName: 'USER_ADMINISTRATION',
      path: '/role',
      slug: 'none',
    },
    {
      moduleName: 'JOBS_MODULE',
      path: '/departments',
      slug: 'index.departments',
    },
    {
      moduleName: 'JOBS_MODULE',
      path: '/jobs',
      slug: 'index.jobs',
    },
    {
      moduleName: 'JOBS_MODULE',
      path: '/job-roles',
      slug: 'index.jobs-role',
    },
    // {
    //   moduleName: 'ANALYTICS_MODULE',
    //   path: '/analytics',
    //   slug: 'none',
    // },
    // {
    //   moduleName: 'REPAIR_SERVICES_MODULE',
    //   path: '/repair-services',
    //   slug: 'index.repair-service',
    // },
  ];

  if (!isGlobal) {
    for (let index = 0; index < modulePath.length; index++) {
      if (
        MODULES(modulePath[index].moduleName) &&
        canI(permissions, modulePath[index].slug, isSuperAdmin)
      ) {
        allowedRoutePath = modulePath[index].path;
        break;
      }
    }
  }

  const customRoutes = [
    //auth routes
    authRoute,

    //PUBLIC
    publicAppointment,
    clientReviewApproval,

    // Dashboard Routes
    {
      path: '/',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={allowedRoutePath} replace />, index: true },
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.subscriber', isSuperAdmin)
          ? subscribers
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.subscriber-list', isSuperAdmin)
          ? subscribersList
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.service-request', isSuperAdmin)
          ? serviceRequests
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.promo-code', isSuperAdmin)
          ? promoCodes
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.client_review', isSuperAdmin)
          ? clientReviews
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'list.campaign', isSuperAdmin)
          ? campaign
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.callback-request', isSuperAdmin)
          ? callbackRequests
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.contacts', isSuperAdmin)
          ? contacts
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.book-assessments', isSuperAdmin)
          ? bookAssessments
          : {},
        MODULES('BULK_SMS_MODULE') && canI(permissions, 'index.bulksms', isSuperAdmin) ? sms : {},
        MODULES('APPOINTMENT_MODULE') && canI(permissions, 'index.appointments', isSuperAdmin)
          ? appointments
          : {},
        MODULES('APPOINTMENT_MODULE') && canI(permissions, 'index.clients', isSuperAdmin)
          ? clients
          : {},
        MODULES('NEWSLATTER_MODULE') && canI(permissions, 'index.newslatter', isSuperAdmin)
          ? newsLetter
          : {},
        MODULES('JOBS_MODULE') && canI(permissions, 'index.jobs', isSuperAdmin) ? jobs : {},
        MODULES('JOBS_MODULE') && canI(permissions, 'index.jobs-role', isSuperAdmin)
          ? jobRroles
          : {},
        MODULES('JOBS_MODULE') && canI(permissions, 'index.departments', isSuperAdmin)
          ? departments
          : {},
        MODULES('WEBSITE_MODULE') && canI(permissions, 'index.tags', isSuperAdmin) ? tags : {},
        isGlobal ? company : {},
        isGlobal ? template : {},
        MODULES('POSTS_MODULE') && canI(permissions, 'index.post', isSuperAdmin) ? posts : {},
        MODULES('POSTS_MODULE') && canI(permissions, 'index.category', isSuperAdmin)
          ? categories
          : {},
        MODULES('USER_ADMINISTRATION') && isSuperAdmin ? role : {},
        MODULES('USER_ADMINISTRATION') && isSuperAdmin ? user : {},
        profile,
        MODULES('ANALYTICS_MODULE') && canI(permissions, 'index.analytics', isSuperAdmin)
          ? analytics
          : {},
        {
          path: 'notifications',
          element: <Notifications />,
        },
        MODULES('REPAIR_SERVICES_MODULE') && canI(permissions, 'index.repair-service', isSuperAdmin)
          ? repairServices
          : {},
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ];

  return useRoutes(isGlobal ? [...routes] : [...customRoutes]);
}

import { Box, Grow, Typography, Zoom, styled } from '@mui/material';
import { useImportSubscriberContext } from './context';
import CloudDoneIcon from '@mui/icons-material/CloudDone';

const Wrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    height: '80%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    p: '20px',
  })
);

const ResultItem = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',
    gap: '10px',
  })
);

const ResultItemLabel = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontSize: 12,
  })
);

const ResultItemValue = styled(Box, { shouldForwardProp: (prop) => prop != 'type' })<{
  type: 'info' | 'error';
}>(({ theme, type }) =>
  theme.unstable_sx({
    color: type === 'error' ? theme.palette.error.main : theme.palette.info.main,
  })
);

const SuccessImports = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    width: 'fit-content',
    p: '20px',
    borderRadius: '100%',
    border: 2,
    borderColor: '#00ab55',
    color: '#00ab55',
  })
);

const ResultsStep = () => {
  const { results } = useImportSubscriberContext();

  if (typeof results === 'string') {
    return (
      <Wrapper>
        <Zoom in>
          <CloudDoneIcon sx={{ width: '50px', height: '50px', color: '#00ab55' }} />
        </Zoom>
        <Grow in timeout={500}>
          <Typography fontSize={14} textAlign="center" sx={{ maxWidth: '250px' }}>
            {results}
          </Typography>
        </Grow>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Zoom in>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <SuccessImports>{results?.successImports}</SuccessImports>
          <Typography fontSize={16} sx={{ color: '#00ab55' }}>
            Subscribers imported!
          </Typography>
        </Box>
      </Zoom>

      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr', gap: '5px' }}>
        <Grow in timeout={300}>
          <ResultItem>
            <ResultItemLabel>Valid extracted rows:</ResultItemLabel>
            <ResultItemValue type="info">{results?.filteredRows}</ResultItemValue>
          </ResultItem>
        </Grow>
        <Grow in timeout={600}>
          <ResultItem>
            <ResultItemLabel>Invalid rows:</ResultItemLabel>
            <ResultItemValue type="error">{results?.invalidRowsData}</ResultItemValue>
          </ResultItem>
        </Grow>
        <Grow in timeout={900}>
          <ResultItem>
            <ResultItemLabel>Failed imports by email conflicts:</ResultItemLabel>
            <ResultItemValue type="error">{results?.invalidImportsByEmailConflict}</ResultItemValue>
          </ResultItem>
        </Grow>
        <Grow in timeout={1200}>
          <ResultItem>
            <ResultItemLabel>Failed imports by unknwon errors:</ResultItemLabel>
            <ResultItemValue type="error">{results?.invalidImportsByUnknwonError}</ResultItemValue>
          </ResultItem>
        </Grow>
      </Box>
    </Wrapper>
  );
};
export default ResultsStep;

import React from 'react';
import { Box, Button } from '@mui/material';
import { useSelector } from '../../redux/store';
import { LoadingButton } from '@mui/lab';

type Props = {
  cnext: boolean;
  labelNext?: string;
  confirmFunction?: () => void;
  config: {
    steps: any;
    prev: any;
    next: any;
    sliceName: string;
  };
  showLastStep?:boolean;
};
const HandlerSteperBtn: React.FC<Props> = ({ config, cnext, labelNext, confirmFunction ,showLastStep }) => {
  const activeStep = useSelector((state) => {
    if (config.sliceName === 'newsLetter') {
      return state.newsLetter.steper;
    }
    if (config.sliceName === 'bulkSms') {
      return state.bulkSms.steper;
    }
    return null;
  });
  const isLoading = useSelector((state) => {
    if (config.sliceName === 'newsLetter') {
      return state.newsLetter.isLoading;
    }
    if (config.sliceName === 'bulkSms') {
      return state.bulkSms.isLoading;
    }
    return null;
  });

  const isLastStep = () => activeStep === config.steps.length - 1;

  return (
    <Box
      sx={{
        margin: 'auto',
        padding: '5px',
        paddingTop: '10px',
        paddingBottom: '2rem',
      }}
      justifyContent={'space-between'}
      display={'flex'}
    >
      {activeStep && activeStep > 0 ? (
        <Button disabled={activeStep === 0} onClick={config.prev}>
          Back
        </Button>
      ) : (
        ''
      )}

      {activeStep === 0 && cnext ? (
        <Button onClick={config.next}>
          {labelNext}
        </Button>
      ) : (
        ''
      )}

      {activeStep && activeStep > 0 && cnext  ? (
        <Button disabled={activeStep === 0} onClick={config.next}>
          {labelNext}
        </Button>
      ) : (
        ''
      )}

      {isLastStep() && showLastStep && (
        <LoadingButton
          loading={isLoading ? isLoading : false}
          variant="outlined"
          onClick={confirmFunction}
        >
          Send
        </LoadingButton>
      )}
    </Box>
  );
};

export default HandlerSteperBtn;

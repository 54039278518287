import { Box, Typography, styled } from '@mui/material';
import TagsSelect from 'src/components/tags/tags-select';
import { useImportSubscriberContext } from './context';

const Wrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    gap: '20px',
    p: '30px',
  })
);

const AssignTagsStep = () => {
  const { setTags } = useImportSubscriberContext();

  const updateTags = (value: string[]) => {
    setTags(value);
  };

  return (
    <Wrapper>
      <Typography fontSize={14}>
        You can assign a group of tags to the subscribers you will import, but this is no required.
      </Typography>

      <TagsSelect label="Tags" name="tags" onSelect={updateTags} />
    </Wrapper>
  );
};
export default AssignTagsStep;

import HandlerSteperBtn from 'src/components/newsletter/HandlerSteperBtn';
import SubscriberList from 'src/pages/crud/subscriber/SubscriberList';
import { steps, prev, next, addSubscribersPhones } from 'src/redux/slices/bulkSms';
import { useDispatch, useSelector } from 'src/redux/store';

const SelectSubscribers = () => {
  const selectedContactPhones = useSelector((state) => state.bulkSms.contactPhones);
  const selectedSubscriberPhones = useSelector((state) => state.bulkSms.subscriberPhones);
  const dispatch = useDispatch();

  return (
    <>
      <SubscriberList
        showActions={false}
        showTopBar={false}
        filterOnlyWithPhones
        onRowSelected={(rows) => {
          dispatch(addSubscribersPhones(rows.map((row) => row.phone)));
        }}
      />
      <HandlerSteperBtn
        config={{ steps, prev, next, sliceName: 'bulkSms' }}
        cnext={selectedContactPhones.length > 0 || selectedSubscriberPhones.length > 0}
        labelNext={'Next'}
      />
    </>
  );
};
SelectSubscribers.label = 'Select Subscribers';
export default SelectSubscribers;

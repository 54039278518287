import React from 'react';
import { TextField, Chip, Box } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import Stack from '@mui/material/Stack';

import { useSelector } from '../../../../redux/store';
import { setActiveChip, setCalendar, next } from '../../../../redux/slices/publicAppointment';

const SelectDate = () => {
  const calendar = useSelector((state) => state.publicAppointment.calendarDate);
  const schedule = useSelector((state) => state.publicAppointment.selectedSchedule);

  const disableWeekends = (date: Date) => date.getDay() === 0 || date.getDay() === 6;

  const renderChips = () => (
    <Box paddingX={3}>
      {schedule.map((day, index) => (
        <Chip
          style={{ marginLeft: '0.75rem', marginBottom: '0.75rem' }}
          key={index}
          label={day.date}
          color={!day.isActive ? 'primary' : 'default'}
          variant="filled"
          size="medium"
          onClick={() => {
            setActiveChip(day.date);
            next();
          }}
        />
      ))}
    </Box>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Stack spacing={3} paddingTop={5} paddingX={1}>
        <DatePicker
          inputFormat="dd/MM/yyyy"
          shouldDisableDate={disableWeekends}
          disablePast={true}
          label="Select Date"
          openTo="day"
          views={['month', 'day']}
          value={calendar}
          onChange={(newValue) => {
            setCalendar(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />
        {renderChips()}
      </Stack>
    </LocalizationProvider>
  );
};

SelectDate.label = 'Select Date';

export default SelectDate;

// export const JOBS_MODULE: Boolean = process.env.REACT_APP_JOBS_MODULE === 'ON';
// export const WEBSITE_MODULE: Boolean = process.env.REACT_APP_WEBSITE_MODULE === 'ON';
// export const APPOINTMENT_MODULE: Boolean = process.env.REACT_APP_APPOINTMENT_MODULE === 'ON';
// export const BULK_SMS_MODULE: Boolean = process.env.REACT_APP_BULK_SMS_MODULE === 'ON';
// export const NEWSLATTER_MODULE: Boolean = process.env.REACT_APP_NEWSLATTER_MODULE === 'ON';

export type moduleProp = {
  id: string;
  name: string;
};

export const MODULES = (module: string) => {
  let final = false;
  
  const storedValue = localStorage.getItem('cmp');
  const st = storedValue === null ? [] : JSON.parse(storedValue);
  const modules = st && st.hasOwnProperty('modules') ? st.modules : [];

  if (modules.length > 0) {
    modules.forEach((el: moduleProp) => {
      if (el.name === module) {
        final = true;
      }
    });
  }
  return final;
};

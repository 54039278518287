import React, { useRef } from 'react';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { LoadingButton } from '@mui/lab';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import Stack from '@mui/material/Stack';
import { useSnackbar } from 'notistack';

const Input = styled('input')({
  display: 'none',
});

export default function UploadButtons({
  setChips,
  setMessage,
  chips,
  setUrls,
}: any) {
  const inputRef = useRef<HTMLInputElement>(null);

  const reset = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  const validateInternationalFormatNumber = (phone: string) =>
    /^\+(?:[0-9] ?){6,14}[0-9]$/.test(phone);

  const csvFileToArray = (phones: string) => {
    const csvHeader = phones.slice(0, phones.indexOf('\n')).split(',');
    const csvRows = phones.slice(phones.indexOf('\n') + 1).split('\n');

    const array = csvRows.map((i) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object: any, header, index) => {
        object = values[index].split('\r')[0];
        return object;
      }, {});
      return obj;
    });
    const dataArr = array.splice(0, array.length - 1).map((e) => `+${e}`);
    let result = dataArr.filter((item, index) => dataArr.indexOf(item) === index);
    let cleanPhones = result.filter((e) => validateInternationalFormatNumber(e));
    setChips(cleanPhones);
  };

  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let reader: FileReader = new FileReader();
    const files: FileList = e.target.files!;

    reader.onload = (_event: Event) => {
      if (files[0].type === 'text/csv') {
        csvFileToArray(reader.result as string);
      } else {
        enqueueSnackbar(`Opss, you must select a csv file`, {
          variant: 'error',
        });
      }
    };

    reader.readAsText(files[0], 'UTF-8');
  };

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <label htmlFor="fileInput">
        <Input
          id="fileInput"
          onChange={handleChangeFile}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          multiple={false}
          type="file"
          ref={inputRef}
          onClick={() => reset()}
        />
        <Button variant="contained" component="span" endIcon={<CloudUploadIcon />}>
          Upload CSV
        </Button>
      </label>
      {inputRef.current?.value && chips.length ? (
        <Button
          variant="contained"
          endIcon={<HighlightOffIcon />}
          color="error"
          onClick={() => {
            reset();
            setChips([]);
            setMessage('');
            setUrls([]);
          }}
        >
          Cancel
        </Button>
      ) : null}

      {/* {inputRef.current?.value && message.length ? (
        <LoadingButton
          loading={loading}
          onClick={() => sendMessage()}
          variant="contained"
          endIcon={<SendIcon />}
        >
          Send
        </LoadingButton>
      ) : null} */}
    </Stack>
  );
}

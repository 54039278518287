import { useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import * as apiService from 'src/api/api';
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  SxProps,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import TagForm from 'src/pages/crud/tag/TagForm';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export interface Tag {
  id: string;
  name: string;
  slug: string;
  color: string;
  signature: string;
}

const TagsSelect = ({
  name,
  label,
  defaultValue,
  onSelect,
  sx,
  size,
}: {
  label: string;
  name: string;
  defaultValue?: Tag[];
  onSelect?: (value: string[]) => void;
  size?: 'small' | 'medium';
  sx?: SxProps;
}) => {
  // const { setValue } = useFormContext<{ tagsId: string[] }>();
  const { user } = useAuth();
  const [tagsOptions, setTagsOptions] = useState<Tag[]>([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [openTagModal, setOpenTagModal] = useState(false);

  const queryTags = async (defaultTags?: string | null) => {
    try {
      setLoading(true);
      const queries: Record<string, any> = {
        search,
        page: 0,
        pageSize: 15,
        signature: user?.companyId,
      };
      const queryString = Object.keys(queries).reduce((acc, key) => {
        return acc + `&${key}=${queries[key]}`;
      }, '');

      const res = await apiService.get('tags?' + queryString);

      setTagsOptions(res.data.results);

      // if (defaultTags) {
      //   const findPromoCode = res.data.results.find((e: PromoCode) => e.id === defaultTags);
      //   if (findPromoCode) {
      //     setSelectedPromoCode(findPromoCode);
      //   }
      // }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    queryTags();
  }, [search]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedTags(defaultValue);
      // setSearch(defaultValue.code);
    }
  }, [defaultValue]);

  const handleCloseModal = () => {
    setOpenTagModal(false);
  };

  const handleTagCreated = (newTag: Tag) => {
    const currentTags = [...selectedTags];
    currentTags.push(newTag);
    setSelectedTags(currentTags);
    onSelect && onSelect(currentTags.map((val) => val.id));
    handleCloseModal();
  };

  return (
    <>
      <Autocomplete
        id="tags-select"
        options={tagsOptions}
        isOptionEqualToValue={(op, value) => op.id === value.id}
        getOptionLabel={(op) => op.name}
        loading={loading}
        filterOptions={(x) => x}
        value={selectedTags}
        multiple
        onChange={(_e, value) => {
          setSelectedTags(value);
          onSelect && onSelect(value.map((val) => val.id));
          setSearch('');
        }}
        noOptionsText={
          <Button variant="text" color="inherit" onClick={() => setOpenTagModal(true)}>
            <AddIcon color="primary" /> Create Tag
          </Button>
        }
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            label={label}
            size={size}
            onChange={debounce((e) => {
              setSearch(e.target.value);
            }, 500)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            sx={sx}
          />
        )}
      />

      <Dialog open={openTagModal} onClose={handleCloseModal} fullWidth={true} maxWidth={'sm'}>
        <DialogTitle>Create Tag</DialogTitle>
        <DialogContent sx={{ mt: 3 }}>
          <TagForm
            isEdit={false}
            tag={null}
            id={undefined}
            isTagPath={false}
            onTagCreated={(tag) => handleTagCreated(tag as unknown as Tag)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};
export default TagsSelect;

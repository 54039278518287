import React, { useState, useEffect } from 'react';
import HandlerSteperBtn from 'src/components/newsletter/HandlerSteperBtn';
import { steps, prev, next, addCsvPhones, addUrls } from 'src/redux/slices/bulkSms';
import { Chip, Container, Grid, Box, Stack } from '@mui/material';
import { useSnackbar } from 'notistack';
import useSettings from '../../../hooks/useSettings';
import LoadPhones from './LoadPhone';
import { post } from 'src/api/api';
import { DynamicList } from '../../../pages/crud/job/DynamicList';
import { useDispatch } from 'src/redux/store';

const SelectCSV = () => {
  const { themeStretch } = useSettings();
  const [urls, setUrls] = useState<string[]>([]);
  const [chips, setChips] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsloading] = useState(false);
  const [validUrl, setValidUrl] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(addCsvPhones(chips));
  }, [chips]);

  useEffect(() => {
    const listUrl = urls.filter((url) => validateUrl(url));
    if (listUrl.length !== urls.length) {
      setValidUrl(false);
    } else {
      setValidUrl(true);
      dispatch(addUrls(urls));
    }
  }, [urls]);

  const validateUrl = (url: string) => {
    const re =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return re.test(String(url).toLowerCase());
  };

  const handleUrlChange = (i: number, e: any) => {
    const newUrl = [...urls];
    newUrl[i] = e.target.value;
    if (newUrl[i].length === 0) {
      newUrl.splice(i, 1);
    }
    setUrls(newUrl);
  };

  const addUrlFields = () => {
    setUrls([...urls, '']);
  };

  const removeUrlFields = (i: number) => {
    const newUrl = [...urls];
    newUrl.splice(i, 1);
    setUrls(newUrl);
  };
  const renderChips = () => (
    <Box paddingX={3} marginTop={5}>
      {chips.map((phone, index) => (
        <Chip
          style={{ marginLeft: '0.75rem', marginBottom: '0.75rem' }}
          key={index}
          label={phone}
          color={'primary'}
          variant="outlined"
          size="medium"
          onClick={() => {
            console.log('click');
          }}
        />
      ))}
    </Box>
  );

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack>
          <Grid container spacing={3}>
            <Grid item md={12}>
              <LoadPhones
                setChips={setChips}
                chips={chips}
                setMessage={setMessage}
                setUrls={setUrls}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <Box style={{ marginBottom: '2rem' }}>
                {chips.length ? (
                  <DynamicList
                    fieldName="Url"
                    listName={urls}
                    handleAdd={addUrlFields}
                    handleRemove={removeUrlFields}
                    handleList={handleUrlChange}
                  />
                ) : null}
              </Box>
            </Grid>
          </Grid>
        </Stack>
        {renderChips()}
      </Container>
      <HandlerSteperBtn
        config={{ steps, prev, next, sliceName: 'bulkSms' }}
        //cnext={chips.length > 0 && validUrl}
        cnext={true}
        labelNext={'Next'}
      />
    </>
  );
};
SelectCSV.label = 'Select from CSV';
export default SelectCSV;

import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Typography,
  ListSubheader,
} from '@mui/material';

import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import { getNotifications, markAllAsReaded } from '../../../redux/slices/notification';
import { useSelector } from '../../../redux/store';
import { useNavigate } from 'react-router-dom';
import { NotificationItem } from '../../../components/notification/NotificationItem';
import * as apiService from 'src/api/api';
import useAuth from '../../../hooks/useAuth';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const navigate = useNavigate();
  const notifications = useSelector((state) => state.notifications.notifications);

  const totalUnRead = notifications.filter((item) => item.status === 'unReaded').length;

  const totalReaded = notifications.filter((item) => item.status === 'readed').length;

  const [open, setOpen] = useState<HTMLElement | null>(null);
  const [toShowNotify, setToShowNotify] = useState<number>(0);
  const JWTContext = useAuth();

  const signature = JWTContext?.user ? JWTContext?.user.companyId : '';

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    localStorage.setItem('initial_not', String(notifications.length));
    setToShowNotify(0);
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  useEffect(() => {
    if (!localStorage.getItem('initial_not') && notifications.length > 0) {
      localStorage.setItem('initial_not', String(notifications.length));
    }
    if (
      notifications.length > 0 &&
      Number(localStorage.getItem('initial_not')) < notifications.length
    ) {
      setToShowNotify(notifications.length - Number(localStorage.getItem('initial_not')));
    }
    if (
      notifications.length > 0 &&
      Number(localStorage.getItem('initial_not')) >= notifications.length
    ) {
      localStorage.setItem('initial_not', String(notifications.length));
      setToShowNotify(0);
    }
  }, [totalUnRead]);

  useEffect(() => {
    getNotifications(signature);
    const fetchNotification = setInterval(() => {
      if (signature && signature.length > 0) {
        getNotifications(signature);
      }
      return;
    }, 10000);
    return () => clearInterval(fetchNotification);
  }, []);

  useEffect(() => {
    const FetchIcsOutlook = setInterval(async () => {
      if (signature && signature.length > 0) {
        apiService.get(`appointments/ics?signature=${signature}`);
      }
    }, 300000 * 4);
   
    return () => {
      clearInterval(FetchIcsOutlook);
    };
  }, []);

  const renderUnreaded = () => (
    <List
      disablePadding
      subheader={
        <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          unReaded
        </ListSubheader>
      }
    >
      {notifications
        .filter((notification) => notification.status === 'unReaded')
        .map((notification) => (
          <NotificationItem key={notification.id} notification={notification} setOpen={setOpen} />
        ))
        .slice(0, 3)}
    </List>
  );

  const sliceNumber = (): number => {
    if (totalUnRead === 0) {
      return 5;
    }
    if (totalReaded === 0) {
      return 5;
    }
    return 2;
  };

  const renderReaded = () => (
    <List
      disablePadding
      subheader={
        <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
          Readed
        </ListSubheader>
      }
    >
      {notifications
        .filter((notification) => notification.status === 'readed')
        .map((notification) => (
          <NotificationItem key={notification.id} notification={notification} setOpen={setOpen} />
        ))
        .slice(0, sliceNumber())}
    </List>
  );

  const renderNotifications = () => {
    if (notifications.length === 0) {
      return (
        <Box sx={{ p: 3, textAlign: 'center' }}>
          <Typography variant="h6">No notifications</Typography>
        </Box>
      );
    }
    if (totalUnRead === 0 && totalReaded > 0) {
      return renderReaded();
    }
    if (totalUnRead > 0 && totalReaded === 0) {
      return renderUnreaded();
    }
    if (totalUnRead > 0 && totalReaded > 0) {
      return (
        <>
          {renderUnreaded()}
          <Divider />
          {renderReaded()}
        </>
      );
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={toShowNotify} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButtonAnimate color="primary" onClick={() => markAllAsReaded()}>
                <Iconify icon="eva:done-all-fill" width={20} height={20} />
              </IconButtonAnimate>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {renderNotifications()}
          {/* 
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {notifications.slice(0, 2).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} setOpen={setOpen}/>
            ))}
          </List> 
          */}

          {/* 
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} setOpen={setOpen} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button
            onClick={() => {
              navigate('/notifications');
              setOpen(null);
            }}
            fullWidth
            disableRipple
          >
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

import { createContext, FC, useState } from 'react';
// @type
import { CustomModalProps } from "../sections/cruds/dialogs/customModal";

// ----------------------------------------------------------------------

type ModalContextProps = {
  params: CustomModalProps,
  showModal: (params: CustomModalProps) => unknown,
  closeModal: () => unknown
}

const initialState: ModalContextProps = {
  params: {},
  showModal: () => {},
  closeModal: () => {},
};

const ModalContext = createContext(initialState);

const ModalProvider: FC = ({ children }) => {
  const [params, setParams] = useState({});

  const show = (params: CustomModalProps) => setParams({ ...params, open: true });

  const close = () => setParams({ ...params, open: false });

  return (
    <ModalContext.Provider
      value={{
        params,
        closeModal: close,
        showModal: show
      }}
    >
      {children}
    </ModalContext.Provider>
  );
}

export { ModalProvider, ModalContext };

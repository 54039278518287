import React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from '../../../redux/store';
import { setIsLoading, setSuccess, steps, prev, next } from '../../../redux/slices/newslatter';
import HandlerSteperBtn from '../HandlerSteperBtn';
import * as apiService from 'src/api/api';
import { useSnackbar } from 'notistack';
import useAuth from '../../../hooks/useAuth';
import { fScheduled } from 'src/utils/formatTime';

const Review = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const rawHtml = useSelector((state) => state.newsLetter.selectedTemplate);
  const tl = useSelector((state) => state.newsLetter.title);
  // const logo = useSelector((state) => state.newsLetter.logo);
  // const companyName = useSelector((state) => state.newsLetter.cName);
  const sEmails = useSelector((state) => state.newsLetter.selectedEmails);
  const listTableEmail = useSelector((state) => state.subscriberList.selectedSubscribers);
  const completedHtml = useSelector((state) => state.newsLetter.fillHtml);
  const isSch = useSelector((state) => state.newsLetter.isSchedule);
  const scheduledDate = useSelector((state) => state.newsLetter.scheduleDate);

  const { user, company } = useAuth();

  const selectedTemplate = rawHtml
    .find((item) => item.selected)
    ?.html.replace('{content}', completedHtml)
    .replace('{title}', tl)
    .replace('{title}', tl)
    .replace('{imageSrc}', company && typeof company.photo !== 'undefined' ? company.photo : '')
    .replace(
      'NPC App',
      `${company && typeof company.name !== 'undefined' ? company.name : ''} | NPC Admin`
    );

  const sendNewsletter = async () => {
    try {
      dispatch(setIsLoading(true));
      await apiService.post(
        'newsletters',
        isSch
          ? {
              content: completedHtml,
              emails: [...new Set([...sEmails, ...listTableEmail])],
              title: tl,
              templateId: rawHtml.find((item) => item.selected)?.id,
              schema: JSON.stringify(rawHtml.find((item) => item.selected)?.schema),
              signature: user ? user.companyId : '',
              scheduledAt: scheduledDate,
            }
          : {
              content: completedHtml,
              emails: [...new Set([...sEmails, ...listTableEmail])],
              title: tl,
              templateId: rawHtml.find((item) => item.selected)?.id,
              schema: JSON.stringify(rawHtml.find((item) => item.selected)?.schema),
              signature: user ? user.companyId : '',
            }
      );
      dispatch(setIsLoading(false));
      enqueueSnackbar('Newsletter sent successfully', { variant: 'success' });
      dispatch(setSuccess());
    } catch (error) {
      dispatch(setIsLoading(false));
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isSch && (
        <Typography
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
          variant="body1"
          textAlign={'center'}
        >
          {`The newsletter will be scheduled for ${fScheduled(scheduledDate)}.`}
        </Typography>
      )}

      <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '2rem' }}>
        <CardContent>
          <Typography
            variant="body1"
            dangerouslySetInnerHTML={{
              __html: `${selectedTemplate}`,
            }}
          />
        </CardContent>
      </Card>
      <HandlerSteperBtn
        config={{ steps, prev, next, sliceName: 'newsLetter' }}
        cnext={false}
        confirmFunction={sendNewsletter}
        showLastStep={true}
      />
    </Box>
  );
};

Review.label = 'Preview';

export default Review;

import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { fDateTimeS, getDayOfWeekName } from 'src/utils/formatTime';
import { useSelector } from 'src/redux/store';
import { appConfig } from 'src/config/conf';

const Review = () => {
  const calendarDate = useSelector((state) => state.publicAppointment.calendarDate);
  const selectedSchedule = useSelector((state) => state.publicAppointment.selectedSchedule);
  const user = useSelector((state) => state.publicAppointment.user);
  const selectedS = selectedSchedule.filter((e) => e.isActive);

  return (
    <Box sx={{ width: '100%', height: '100%', padding: '10px' }}>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <Typography
          variant="h5"
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            color: 'text.disabled',
          }}
        >
          BOOKING SUMMARY
        </Typography>
      </Box>
      <Divider />
      <Box paddingY={1} display={'flex'} alignItems={'center'}>
        <Iconify icon={'fa:hourglass-2'} marginRight={1} />
        <Typography variant="body2">{`${appConfig.publicAppointment.minutesPerTurns} min`}</Typography>
      </Box>
      <Divider />

      <Box paddingY={1} display={'flex'} alignItems={'center'}>
        <Iconify icon={'dashicons:calendar-alt'} marginRight={1} />{' '}
        <Typography variant="body2" marginRight={0.5}>{`${getDayOfWeekName(
          calendarDate
        )}, ${fDateTimeS(calendarDate)}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1} display={'flex'} alignItems={'center'}>
        <Iconify icon={'ant-design:clock-circle-filled'} marginRight={1} />{' '}
        <Typography variant="body2">{`at ${selectedS[0]?.date}`}</Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '5px',
          marginTop: '1rem',
        }}
      >
        <Typography
          variant="h5"
          sx={{
            mt: 0.5,
            display: 'flex',
            alignItems: 'center',
            color: 'text.disabled',
            marginBottom: '1rem',
          }}
        >
          YOUR CONTACT DETAILS
        </Typography>
      </Box>
      <Divider />

      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          First name
        </Typography>
        <Typography variant="body2">{`${user?.name}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Last name
        </Typography>
        <Typography variant="body2">{`${user?.lastName}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Email
        </Typography>
        <Typography variant="body2">{`${user?.email}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Mobile
        </Typography>
        <Typography variant="body2">{`${user?.phone}`}</Typography>
      </Box>
      <Divider />
      <Box paddingY={1}>
        <Typography variant="body2" style={{ fontWeight: 900 }}>
          Company
        </Typography>
        <Typography variant="body2">{`${user?.company}`}</Typography>
      </Box>
      <Divider />
    </Box>
  );
};

Review.label = 'Summary';

export default Review;

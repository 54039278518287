import { useEffect, useState } from 'react';
import { canI } from 'src/utils/canI';
import { MODULES } from 'src/utils/modules';

import useAuth from './useAuth';
import { Route, RoutesGroup, SubRoute } from 'src/layouts/dashboard/navbar/NavConfig';

const useModules = (navConfig: RoutesGroup[]) => {
  const { user } = useAuth();
  const [newConfig, setConfig] = useState<any>(navConfig);
  const isGlobalAdmin = user?.isGlobalAdmin;
  const isSuperAdmin = user && user.isSuperAdmin;
  const permissions = user && user.permissions;

  useEffect(() => {
    if (user && user.isGlobalAdmin) {
      return newConfig;
    }

    const validatedRoutes = navConfig.reduce<RoutesGroup[]>((acc, group) => {
      const routes = group.items.reduce<Route[]>((_acc, route) => {
        const subRoutes = route.children.reduce<SubRoute[]>((_a, subRoute) => {
          if (MODULES(subRoute.slug)) {
            if (isSuperAdmin) {
              return [..._a, subRoute];
            }
            if (
              (!subRoute?.rootAccessByPermission && !subRoute.superAdminRoute) ||
              (!subRoute.superAdminRoute &&
                canI(permissions, subRoute.rootAccessByPermission ?? '', isSuperAdmin))
            ) {
              return [..._a, subRoute];
            }
            return _a;
          } else if (subRoute.slug == 'NONE') {
            return [..._a, subRoute];
          } else if (subRoute.slug == 'ONLY_US' && isGlobalAdmin) {
            return [..._a, subRoute];
          } else {
            return _a;
          }
        }, []);

        if (subRoutes.length == 0) {
          return _acc;
        } else {
          return [..._acc, { ...route, children: subRoutes }];
        }
      }, []);

      return [...acc, { ...group, items: routes }];
    }, []);

    const cleanConfig = validatedRoutes.map((e: any) => {
      if (e.items.length > 0) {
        return e;
      }
      return { ...e, subheader: '' };
    });

    setConfig(cleanConfig);
  }, []);

  return newConfig;
};

export default useModules;

import React, { createContext, useContext, useState } from 'react';

interface IImportSubscriberContext {
  file: File | null;
  tags: string[];
  results:
    | string
    | null
    | {
        successImports: number;
        filteredRows: number;
        invalidImportsByEmailConflict: number;
        invalidImportsByUnknwonError: number;
        invalidRowsData: number;
      };
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  setTags: React.Dispatch<React.SetStateAction<string[]>>;
  setResults: React.Dispatch<React.SetStateAction<IImportSubscriberContext['results']>>;
  reset: () => void;
}

const ImportSubscriberContext = createContext<IImportSubscriberContext | null>(null);

const useImportSubscriberContext = () => {
  const context = useContext(ImportSubscriberContext);

  if (!context) {
    throw new Error('useImportSubscriberContext must be used within a ImportSubscriberProvider');
  }

  return context;
};

const ImportSubscriberProvider = ({ children }: { children: React.ReactNode }) => {
  const [file, setFile] = useState<File | null>(null);
  const [tags, setTags] = useState<string[]>([]);
  const [results, setResults] = useState<IImportSubscriberContext['results']>(null);

  const reset = () => {
    setFile(null);
    setTags([]);
  };

  return (
    <ImportSubscriberContext.Provider
      value={{ file, tags, results, setFile, setTags, reset, setResults }}
    >
      {children}
    </ImportSubscriberContext.Provider>
  );
};
export default ImportSubscriberProvider;

export { useImportSubscriberContext };

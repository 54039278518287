import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Typography,
  styled,
} from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import { useCallback, useMemo, useState } from 'react';
import UploadFileStep from './upload-file-step';
import ImportSubscriberProvider, { useImportSubscriberContext } from './context';
import AssignTagsStep from './assign-tags-step';
// import * as apiService from 'src/api/api';
import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import ResultsStep from './results-step';
import axios from 'src/utils/axios';

interface Props {
  open: boolean;
  onClose: (anyImport?: boolean) => void;
}

type StepView = {
  title: string;
  component: JSX.Element;
};

const Wrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    boxShadow: 24,
    py: '20px',
    px: '40px',
  })
);

const Header = styled(Typography)(({ theme }) =>
  theme.unstable_sx({
    fontWeight: 500,
    fontSize: '20px',
    mb: '20px',
    display: 'flex',
    alignContent: 'center',
    gap: '10px',
  })
);

const Body = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: '400px',
  })
);

const Footer = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'space-between',
    gap: '10px',
  })
);

const ImportSubscribersModal = (props: Props) => {
  return (
    <ImportSubscriberProvider>
      <ImportSubscribersModalContent {...props} />
    </ImportSubscriberProvider>
  );
};
export default ImportSubscribersModal;

const ImportSubscribersModalContent = ({ open, onClose }: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const [uploading, setUploading] = useState(false);
  const { user } = useAuth();
  const [completedSteps, setCompletedSteps] = useState<{ [x: number]: boolean }>({});
  const { file, tags, results, reset, setResults } = useImportSubscriberContext();
  const { enqueueSnackbar } = useSnackbar();

  const stepViews: Record<number, StepView> = useMemo(
    () => ({
      0: {
        title: 'Upload Excel file',
        component: <UploadFileStep />,
      },
      1: {
        title: 'Assign tags',
        component: <AssignTagsStep />,
      },
      2: {
        title: 'Results',
        component: <ResultsStep />,
      },
    }),
    []
  );

  const showBackButton = useMemo(() => {
    return activeStep > 0 && activeStep < 2;
  }, [activeStep]);

  const handleNextStep = useCallback(() => {
    if (activeStep > 1) return;

    if (activeStep === 1) {
      return uploadFile();
    }

    setActiveStep((prev) => prev + 1);
  }, [activeStep, tags]);

  const handleBackStep = useCallback(() => {
    if (activeStep > 1 || activeStep === 2) return;

    setActiveStep((prev) => prev - 1);
  }, [activeStep]);

  const handleCancel = useCallback(() => {
    if (uploading) return;

    reset();
    setActiveStep(0);
    onClose(!!results && typeof results !== 'string');
  }, [uploading, results]);

  const isNextButtonDisabled = useMemo(() => {
    if (uploading) return true;

    if (activeStep === 0) {
      return !file;
    }
  }, [activeStep, file, uploading]);

  const nextButtonLabel = useMemo(() => {
    if (activeStep === 1) {
      return 'Import';
    }
    return 'Next';
  }, [activeStep]);

  const cancelButtonLabel = useMemo(() => {
    if (activeStep === 2) {
      return 'Close';
    }

    return 'Cancel';
  }, [activeStep]);

  const uploadFile = useCallback(async () => {
    setUploading(true);
    try {
      const formData = new FormData();
      formData.append('file', file as Blob);

      const res = await axios({
        method: 'POST',
        url: 'subscribers/import',
        params: {
          signature: user?.companyId,
          tags: tags,
        },
        data: formData,
      });

      if (res.status === 200) {
        setResults(res.data.msg);
      } else {
        setResults(res.data);
      }

      setActiveStep((prev) => prev + 1);
    } catch (error) {
      enqueueSnackbar(error?.message ?? 'Something went wrong. Please, try again.', {
        variant: 'error',
      });
    } finally {
      setUploading(false);
    }
  }, [file, tags]);

  return (
    <Modal open={open} BackdropProps={{ open, onClick: handleCancel }} onClose={handleCancel}>
      <Wrapper>
        <Header>
          <UploadFileIcon />
          Import subscribers
        </Header>

        <Body>
          <Stepper activeStep={activeStep} alternativeLabel>
            {Object.values(stepViews).map((step) => (
              <Step key={step.title} completed={completedSteps[activeStep]}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {stepViews[activeStep].component}
        </Body>

        <Footer>
          {showBackButton ? (
            <Button
              variant="outlined"
              disabled={uploading}
              onClick={handleBackStep}
              sx={{ display: 'flex', alignContent: 'center', gap: '5px' }}
            >
              <ArrowLeftRoundedIcon /> Back
            </Button>
          ) : (
            <span />
          )}
          <Box sx={{ display: 'flex', gap: '10px', alignContent: 'center' }}>
            {
              <Button disabled={uploading} onClick={handleCancel}>
                {cancelButtonLabel}
              </Button>
            }
            {activeStep !== 2 && (
              <Button
                variant="contained"
                disabled={isNextButtonDisabled}
                onClick={handleNextStep}
                sx={{ display: 'flex', alignContent: 'center', gap: '10px' }}
              >
                {nextButtonLabel}{' '}
                {uploading && (
                  <CircularProgress
                    sx={{
                      height: '20px !important',
                      width: '20px !important',
                      color: 'white',
                    }}
                  />
                )}
              </Button>
            )}
          </Box>
        </Footer>
      </Wrapper>
    </Modal>
  );
};

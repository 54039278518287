import { FC } from "react";
import { Box, Button, TextField } from "@mui/material";

import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';


export type DynamicListProps = {
    listName: string[];
    fieldName: string;
    handleAdd: any;
    handleRemove: any;
    handleList: any;
  };

  export const DynamicList: FC<DynamicListProps> = ({ 
      listName,
      fieldName, 
      handleAdd,
      handleList, 
      handleRemove }) => (
    <Box>
      <Box>
        <Button variant="text" color="inherit" onClick={() => handleAdd()}>
          <AddIcon color="primary"/> {fieldName}
        </Button>
      </Box>
                   
      {listName.map((element: string, index: number) => (
        <Box   
          sx={{ display: 'flex', mt: 3 }}
          key={index}>
          <TextField 
            value={element} 
            label={fieldName} 
            placeholder={fieldName} 
            onChange={e => handleList(index, e)}
            fullWidth/>
          <Button color="error" onClick={() => 
            handleRemove(index)}>
            <CloseIcon fontSize="small"/>
          </Button>
      </Box>
    ))}
  </Box>
);
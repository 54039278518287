import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import TagsSelect, { Tag } from './tags-select';
import { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';

const BulkAssignTagsModal = ({
  openModal,
  onConfirm,
  onSuccess,
  onClose,
}: {
  openModal: boolean;
  onConfirm: (tagIds: string[]) => Promise<void>;
  onSuccess: () => void;
  onClose: () => void;
}) => {
  const [tagIds, setTagIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = useCallback(() => {
    if (loading) return;
    onClose();
  }, [loading]);

  const handleSelection = useCallback((ids: string[]) => {
    setTagIds(ids);
  }, []);

  const handleConfirmation = useCallback(async () => {
    setLoading(true);
    try {
      await onConfirm(tagIds);
      onSuccess();
      setTagIds([]);
      onClose();
    } catch (error) {
      enqueueSnackbar('Error assigning tags to subscribers', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }, [tagIds, onConfirm, onSuccess, onClose]);

  return (
    <Dialog open={openModal} onClose={handleClose}>
      <DialogTitle>Assign Tags</DialogTitle>

      <DialogContent>
        <TagsSelect
          label="Tags"
          name="tags"
          onSelect={handleSelection}
          sx={{ width: '350px', my: '20px' }}
        />
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress
                sx={{ width: '20px !important', height: '20px !important', color: 'white' }}
              />
            ) : undefined
          }
          onClick={handleConfirmation}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default BulkAssignTagsModal;

import { createSlice } from '@reduxjs/toolkit';
import * as apiService from 'src/api/api';
// @types
import { SubscriberList } from 'src/@types/subscriber-list';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const apiSuffix = 'subscriber-list';

const initialState: SubscriberList = {
  subscribers: [],
  selectedSubscribers: [],
  name: '',
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'subscriber-list',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Notifications
    getSubListSuccess(state, action) {
      state.isLoading = true;
      state.subscribers = action.payload;
      state.isLoading = false;
    },

    addSelectedSubscribers(state, action) {
      const final = [...state.selectedSubscribers, ...action.payload];
      if (action.payload.length === 0) {
        state.selectedSubscribers = [];
      } else {
        state.selectedSubscribers = [...new Set(final)];
      }
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { getSubListSuccess, addSelectedSubscribers } = slice.actions;

// ----------------------------------------------------------------------

export const getSubscribersList = async (signature: string) => {
  dispatch(slice.actions.startLoading());
  try {
    const response = await apiService.get(`${apiSuffix}?signature=${signature}`);
    dispatch(getSubListSuccess(response.data.subscribers));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
export const addSubscriberSelected = async (subscribers: string[]) => {
  try {
    dispatch(addSelectedSubscribers(subscribers));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

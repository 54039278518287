// @mui
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Chip,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid, GridColumns, GridFilterModel, GridRowsProp } from '@mui/x-data-grid';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { DataGridActions } from '../../../utils/dataGrid';
import { DataGridWrapper } from '../../../sections/cruds/dataGridWrapper';
import { useLocation, Link as LI } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import * as apiService from 'src/api/api';
import { NewsletterModal } from '../../../sections/cruds/dialogs/newsletterModal';
import { fToNow } from 'src/utils/formatTime';
import { debounce } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { setSelectedEmails, setSelEmails } from '../../../redux/slices/newslatter';
import { useDispatch, useSelector } from '../../../redux/store';
import { MODULES } from '../../../utils/modules';
import useAuth from 'src/hooks/useAuth';
import { canI } from 'src/utils/canI';
import { CustomToolbar } from '../bookAssessment/BookAssessmentList';
import { grey } from '@mui/material/colors';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ImportSubscribersModal from 'src/components/subscribers/import-subscribers-modal';
import { useSnackbar } from 'notistack';
import { useBulkRemove } from 'src/hooks/useBulkRemove';
import BulkAssignTagsModal from 'src/components/tags/bulk-assign-tags-modal';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import axios from 'src/utils/axios';
import { getFontColorBasedOnBgColor } from 'src/utils/getFontColorBasedOnBgColor';
// ----------------------------------------------------------------------

const apiSuffix = 'subscribers';
const modelName = 'Subscriber';

type Subscriber = {
  showTopBar: boolean;
  showActions: boolean;
  selectedElements?: string[];
  filterOnlyWithPhones?: boolean;
  onRowSelected?: (selections: any[]) => void;
};
export default function SubscriberList({
  showTopBar,
  showActions,
  selectedElements = [],
  filterOnlyWithPhones,
  onRowSelected,
}: Subscriber) {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openBulkTagsModal, setOpenBulkTagsModal] = useState(false);
  const [dataGridRows, setDataGridRows] = useState<GridRowsProp>([]);
  const [emails, setEmails] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [verified, setVerified] = useState(false);
  const [pageSize, setPageSize] = useState<number>(15);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const JWTContext = useAuth();
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    window.localStorage.getItem('selectCp') || ''
  );
  const permissions = JWTContext && JWTContext.user ? JWTContext.user.permissions : [];
  const isSuperAdmin = JWTContext && JWTContext.user ? JWTContext.user.isSuperAdmin : false;
  const isGlobalAdmin = JWTContext && JWTContext.user ? JWTContext.user.isGlobalAdmin : false;

  const [dataGridSelection, setDataGridSelection] = useState<any[] | null>(null);
  const sEmails = useSelector((state) => state.newsLetter.selectedEmails);

  const { enqueueSnackbar } = useSnackbar();
  const { rowIds, removing, requestBulkRemove, saveRowIds } = useBulkRemove({
    signature: JWTContext?.user?.companyId,
    apiUrl: '/subscribers/bulk-remove',
    onSuccess: () => {
      loadData();
      setDataGridSelection([]);
      enqueueSnackbar('Deleted successfully', { variant: 'success' });
    },
  });

  useEffect(() => {
    if (
      selectedElements.length > 0 &&
      sEmails.length === 0 &&
      dataGridSelection === null &&
      dataGridRows.length > 0
    ) {
      setSelEmails(selectedElements);

      const selectedIds = dataGridRows
        .filter((row) => selectedElements.includes(row.email))
        .map((row) => row.id);

      setDataGridSelection(selectedIds);
    }
  }, [selectedElements, dataGridRows.length]);

  const handleDataGridSelectionModelChange = (ids: any) => {
    selectEmails(ids);
    setDataGridSelection(ids);
    saveRowIds(ids as string[]);

    const selectedRows = ids.map((id: string) => {
      const row = dataGridRows.find((row) => row.id === id);
      return row;
    });

    onRowSelected && onRowSelected(selectedRows);
  };
  // const handleDataGridSelectionModelChange = useCallback(
  //   ({ selectionModel }) => {
  //     setDataGridSelection(selectionModel);
  //     selectEmails(selectionModel)
  //   },
  //   []
  // );

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const selectEmails = (ids: any) => {
    const newEmails = [];
    for (const id of ids) {
      for (const subscriber of dataGridRows) {
        if (subscriber.id === id) {
          newEmails.push(subscriber.email);
          break;
        }
      }
    }
    setEmails(newEmails);
  };

  const moreTags = (tags: any) => tags.map((tag: any) => tag.name).join(', ');

  const dataGridColumns: GridColumns = [
    {
      field: 'id',
      filterable: false,
      hide: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 2,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Link href={'mailto:' + params.row.email}>{params.row.email}</Link>
        </Tooltip>
      ),
    },
    {
      field: 'campaign',
      headerName: 'Campaign',
      width: 160,
      renderCell: (params) =>
        params?.value ? (
          <Tooltip title={params.value?.name}>
            <Link href={`/campaigns/${params.value?.id}`}>{params.value?.name}</Link>
          </Tooltip>
        ) : (
          <span></span>
        ),
    },
    {
      field: 'emailVerified',
      filterable: false,
      headerName: 'Verified',
      type: 'boolean',
      width: 80,
    },
    {
      field: 'unsubscribed',
      headerName: 'Unsubscribed',
      type: 'boolean',
      width: 120,
    },
    {
      field: 'tags',
      headerName: 'Tags',
      flex: 1,
      renderCell: (params) =>
        params.row.tags ? (
          <Stack
            direction={'row'}
            // spacing={0.5}
            gap={0.5}
            sx={{
              flexWrap: 'wrap',
            }}
          >
            {params.row.tags.length > 0
              ? params.row.tags.slice(0, 2).map((tag: any, i: number) => (
                  <>
                    <Tooltip key={tag.id} title={tag.name}>
                      <Chip
                        label={tag.name}
                        size="small"
                        sx={{
                          backgroundColor: tag.color === '#FFFFFF' ? 'transparent' : tag.color,
                          border: 1,
                          color: (t) =>
                            tag.color === '#FFFFFF' || !tag?.color
                              ? t.palette.text.primary
                              : getFontColorBasedOnBgColor(tag.color),
                          borderColor: (t) => t.palette.divider,
                        }}
                      />
                    </Tooltip>
                    {params.row.tags.length > 2 && i === 1 && (
                      <Tooltip title={moreTags(params.row.tags.slice(2))}>
                        <Typography>...</Typography>
                      </Tooltip>
                    )}
                  </>
                ))
              : ''}
          </Stack>
        ) : (
          ''
        ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      renderCell: (params) => (
        <Tooltip title={params.value}>
          <Link href={'tel:' + params.row.phone}>{params.row.phone}</Link>
        </Tooltip>
      ),
    },
  ];

  if (showActions) {
    dataGridColumns.push({
      filterable: false,
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <DataGridActions
          apiSuffix={apiSuffix}
          modelName={modelName}
          entityId={params.row.id}
          afterDelete={loadData}
          updatePermissionName={'update.subscriber'}
        />
      ),
    });
  }

  const loadData = async (filters?: {
    filterSearch: string;
    filterOperator: string;
    filterField: string;
  }) => {
    try {
      const queryParams = (apiPath: string) =>
        `${apiPath}?search=${search}&pageSize=${pageSize}&page=${page}${
          verified ? '&verified=true' : ''
        }${filterOnlyWithPhones ? '&withPhone=true' : ''}&signature=${
          JWTContext?.user ? JWTContext?.user.companyId : ''
        }`;
      let resp: any = [];
      let comp: any = [];
      setIsLoading(true);
      if (filters) {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&filterSearch=${filters.filterSearch}&filterOperator=${
            filters.filterOperator
          }&filterField=${filters.filterField}&selectedCompany=${selectedCompany}`
        );
        const companyPromise = isGlobalAdmin ? apiService.get('company') : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      } else {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&selectedCompany=${selectedCompany}`
        );
        const companyPromise: any = isGlobalAdmin ? apiService.get('company') : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      }

      if (resp.data.length === 0) {
        setRowCount(0);
        setDataGridRows([]);
        setIsLoading(false);
        return;
      }
      // const responseData = resp.data.results.map(
      //   (subscriber: { createdAt: string | number | Date }, index: any) => ({
      //     ...subscriber,
      //     // createdAt: fToNow(subscriber.createdAt),
      //   })
      // );
      const responseData = resp.data.map(
        (subscriber: { createdAt: string | number | Date }, index: any) => ({
          ...subscriber,
          // createdAt: fToNow(subscriber.createdAt),
        })
      );
      if (isGlobalAdmin) setCompanies(comp.data);

      setRowCount(resp.data.total);
      setDataGridRows(responseData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    if (
      filterModel.items[0].value ||
      filterModel.items[0].operatorValue === 'isEmpty' ||
      filterModel.items[0].operatorValue === 'isNotEmpty'
    ) {
      loadData({
        filterSearch: filterModel.items[0].value,
        filterOperator: String(filterModel.items[0].operatorValue),
        filterField: filterModel.items[0].columnField,
      }).then();
    } else {
      loadData().then();
    }
  }, []);

  useEffect(() => {
    loadData().then();
    return () => window.localStorage.removeItem('selectCp');
  }, [search, page, pageSize, verified, selectedCompany]);

  useEffect(() => {
    dispatch(setSelectedEmails(emails));
    return () => {
      dispatch(setSelectedEmails([]));
    };
  }, [emails]);

  const handleChangeVerified = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVerified(event.target.checked);
  };

  const requestBulkAssignTags = useCallback(
    async (tagIds: string[]) => {
      await axios({
        method: 'PUT',
        url: '/subscribers/bulk-assign-tags',
        data: {
          tagIds,
          subscriberIds: rowIds,
          signature: JWTContext?.user?.companyId,
        },
      });
    },
    [rowIds, JWTContext]
  );

  return (
    <DataGridWrapper pageTitle={'Subscribers'}>
      <ImportSubscribersModal
        open={openImportModal}
        onClose={(anyImport) => {
          setOpenImportModal(false);

          if (anyImport) loadData();
        }}
      />

      <BulkAssignTagsModal
        openModal={openBulkTagsModal}
        onConfirm={requestBulkAssignTags}
        onSuccess={() => {
          saveRowIds([]);
          setDataGridSelection([]);
          enqueueSnackbar('Tags assigned successfully!', { variant: 'success' });
          loadData();
        }}
        onClose={() => setOpenBulkTagsModal(false)}
      />

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack>
          {showTopBar && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <HeaderBreadcrumbs
                heading="Subscribers"
                links={[
                  { name: 'Home', href: PATH_DASHBOARD.root },
                  { name: 'Subscribers', href: PATH_DASHBOARD.website.subscribers },
                ]}
              />

              <Stack
                direction={'row'}
                justifyContent={'space-between'}
                alignContent={'start'}
                spacing={2}
              >
                {canI(permissions, 'create.subscriber', isSuperAdmin) && !isGlobalAdmin && (
                  <Button
                    sx={{
                      display: 'flex',
                      alignContent: 'center',
                      gap: '7px',
                      height: 'fit-content',
                    }}
                    onClick={() => setOpenImportModal(true)}
                  >
                    <UploadFileIcon /> Import
                  </Button>
                )}
                {canI(permissions, 'create.subscriber', isSuperAdmin) && !isGlobalAdmin && (
                  <div>
                    <Button variant={'contained'} component={LI} to={`${pathname}/new`}>
                      <AddIcon /> NEW
                    </Button>
                  </div>
                )}
                {MODULES('NEWSLATTER_MODULE') &&
                  !isGlobalAdmin &&
                  canI(permissions, 'send.newslatter', isSuperAdmin) && (
                    <div>
                      <Button
                        disabled={emails.length === 0}
                        variant="contained"
                        startIcon={<SendIcon />}
                        onClick={handleClickOpen}
                      >
                        Send Newsletter
                      </Button>
                      <NewsletterModal
                        openDialog={open}
                        closeDialog={handleClose}
                        emails={emails}
                      />
                    </div>
                  )}
              </Stack>
            </Stack>
          )}

          <Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    placeholder="Search"
                    onChange={debounce((e) => setSearch(e.target.value), 1000)}
                    sx={{ m: 1, width: '100%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {isGlobalAdmin && (
                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl fullWidth sx={{ m: 1, width: '100%' }}>
                      <InputLabel id="demo-simple-select-label">Companies</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCompany}
                        label="Company"
                        onChange={(e) => {
                          setSelectedCompany(e.target.value);
                          window.localStorage.setItem('selectCp', e.target.value);
                        }}
                      >
                        <MenuItem value="">All Companies</MenuItem>
                        {companies.map((el: any, index: any) => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                <Grid item xs={12} md={4} sm={6}>
                  <FormControlLabel
                    style={{
                      transform: 'scale(1.5)',
                      paddingTop: '0.8rem',
                      marginLeft: '2rem',
                    }}
                    control={<Checkbox checked={verified} onChange={handleChangeVerified} />}
                    label="Verified"
                  />
                </Grid>
              </Grid>
            </Stack>
          </Stack>

          <DataGrid
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                rowIds,
                removing,
                canDoBulkRemove: canI(permissions, 'delete.subscriber', isSuperAdmin),
                onBulkRemove: requestBulkRemove,
                setFilterButtonEl,
                children: canI(permissions, 'update.subscriber', isSuperAdmin) &&
                  rowIds.length > 0 && (
                    <Button
                      variant="contained"
                      startIcon={<LocalOfferRoundedIcon />}
                      onClick={() => setOpenBulkTagsModal(true)}
                    >
                      Assign Tags
                    </Button>
                  ),
              },
            }}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            autoHeight={true}
            density={'comfortable'}
            checkboxSelection={true}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            columns={dataGridColumns}
            rows={dataGridRows}
            rowCount={rowCount}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
            rowsPerPageOptions={[15, 50, 100]}
            pagination
            {...dataGridRows}
            // paginationMode="server"
            // onSelectionModelChange={(ids) => selectEmails(ids)}
            loading={loading}
            selectionModel={dataGridSelection ?? []}
            onSelectionModelChange={handleDataGridSelectionModelChange}
            keepNonExistentRowsSelected
            sx={{ mt: '30px' }}
          />
        </Stack>
      </Container>
    </DataGridWrapper>
  );
}

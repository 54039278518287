import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import Iconify from 'src/components/Iconify';
import {
  setBookingStatus,
  setBoookingError,
  setSteperTo0,
} from 'src/redux/slices/publicAppointment';
import { useSelector } from 'src/redux/store';

const Error = () => {
  const error = useSelector((state) => state.publicAppointment.error);

  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh - 2rem)',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <Iconify
        icon={'el:error'}
        marginRight={1}
        color="#B72136"
        style={{ width: '10rem', height: '10rem', marginBottom: '10px' }}
      />
      <Typography variant="h4">{`Sorry, we couldn't save your appointment. ${error.sms.message}`}</Typography>
      <Button
        onClick={() => {
          setSteperTo0();
          setBookingStatus(false);
          setBoookingError(false, ' ');
        }}
        style={{ width: '90%', marginTop: '3rem' }}
        variant="contained"
      >
        Try again
      </Button>
    </Box>
  );
};

export default Error;

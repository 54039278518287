import { ItemNotification } from 'src/@types/notification';
import { useNavigate } from 'react-router-dom';
import {
  Avatar,
  Typography,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Box,
} from '@mui/material';
// import { noCase } from 'change-case';
import { fToNow } from '../../utils/formatTime';
import Iconify from '../Iconify';
import { setSingleNotification } from 'src/redux/slices/notification';
import { IconMenu } from './OptionMenu';
import { useState } from 'react';

export function NotificationItem({
  notification,
  setOpen,
}: {
  notification: ItemNotification;
  setOpen?: (value: null) => void;
}) {
  const { avatar, title } = renderContent(notification);
  const navigate = useNavigate();
  const [child, setChild] = useState(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!child) {
      setSingleNotification(notification.id);
      navigate(notification.action);
      setOpen && setOpen(null);
    }
    return;
  };

  return (
    <ListItemButton
      onClick={(e: any) => handleClick(e)}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.status === 'unReaded' && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />

      <IconMenu cl={setChild} id={notification.id} />
    </ListItemButton>
  );
}

function renderContent(notification: ItemNotification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.type}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {notification.content}
      </Typography>
    </Typography>
  );

  if (notification.type === 'service_request') {
    return {
      avatar: <img alt={notification.type} src="/icons/new-service-request.webp" />,
      title,
    };
  }
  if (notification.type === 'callback_request') {
    return {
      avatar: <img alt={notification.type} src="/icons/call-back.webp" />,
      title,
    };
  }
  if (notification.type === 'subscribers') {
    return {
      avatar: <img alt={notification.content} src="/icons/new-subscriber.webp" />,
      title,
    };
  }
  if (notification.type === 'contacts') {
    return {
      avatar: <img alt={notification.type} src="/icons/new-contact.webp" />,
      title,
    };
  }
  if (notification.type === 'appointments') {
    return {
      avatar: (
        <img
          alt={notification.type}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'clients') {
    return {
      avatar: <img alt={notification.type} src="/icons/new-customer.webp" />,
      title,
    };
  }
  if (notification.type === 'job_applies') {
    return {
      avatar: (
        <img
          alt={notification.type}
          src="https://minimals.cc/assets/illustrations/illustration_empty_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === 'book_assessments') {
    return {
      avatar: (
        <img
          alt={notification.type}
          src="https://minimals.cc/assets/illustrations/illustration_empty_mail.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: null,
    // avatar: notification.avatar ? <img alt={notification.type} src={notification.avatar} /> : null,
    title,
  };
}

// @mui
import {
  Button,
  Container,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import {
  DataGrid,
  GridColumns,
  GridFilterModel,
  GridRowsProp,
  GridSelectionModel,
} from '@mui/x-data-grid';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// hooks
import useSettings from '../../../hooks/useSettings';
// components
import HeaderBreadcrumbs from '../../../components/HeaderBreadcrumbs';
import { DataGridActions } from '../../../utils/dataGrid';
import { DataGridWrapper } from '../../../sections/cruds/dataGridWrapper';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import * as apiService from 'src/api/api';
import { fToNow } from 'src/utils/formatTime';
import { debounce } from 'lodash';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import { setSelectedEmails } from '../../../redux/slices/newslatter';
// import { useDispatch } from '../../../redux/store';
import useAuth from 'src/hooks/useAuth';
import { canI } from 'src/utils/canI';
import { CustomToolbar } from '../bookAssessment/BookAssessmentList';
import { addSubscriberSelected } from 'src/redux/slices/subscribers-list';
import { useSnackbar } from 'notistack';
import { useBulkRemove } from 'src/hooks/useBulkRemove';

// ----------------------------------------------------------------------

const apiSuffix = 'subscriber-list';
const modelName = 'Subscriber List';

type Subscriber = {
  showTopBar: boolean;
  showActions: boolean;
};
export default function SubscriberListL({ showTopBar, showActions }: Subscriber) {
  const { themeStretch } = useSettings();
  const { pathname } = useLocation();
  const [dataGridRows, setDataGridRows] = useState<GridRowsProp>([]);
  // const [emails, setEmails] = useState<string[]>([]);
  // const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  // const [verified, setVerified] = useState(false);
  const [pageSize, setPageSize] = useState<number>(15);
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState<number | undefined>(undefined);
  // const dispatch = useDispatch();
  const [loading, setIsLoading] = useState(false);
  const JWTContext = useAuth();
  const [filterButtonEl, setFilterButtonEl] = useState<HTMLButtonElement | null>(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(
    window.localStorage.getItem('selectCp') || ''
  );

  const { enqueueSnackbar } = useSnackbar();
  const { rowIds, removing, requestBulkRemove, saveRowIds } = useBulkRemove({
    signature: JWTContext?.user?.companyId,
    apiUrl: '/subscriber-list/bulk-remove',
    onSuccess: () => {
      loadData();
      enqueueSnackbar('Deleted successfully', { variant: 'success' });
    },
  });

  const permissions = JWTContext && JWTContext.user ? JWTContext.user.permissions : [];
  const isSuperAdmin = JWTContext && JWTContext.user ? JWTContext.user.isSuperAdmin : false;
  const isGlobalAdmin = JWTContext && JWTContext.user ? JWTContext.user.isGlobalAdmin : false;
  const navigate = useNavigate();

  const dataGridColumns: GridColumns = [
    {
      field: 'id',
      filterable: false,
      hide: true,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 2,
    },
    {
      filterable: false,
      field: 'createdAt',
      headerName: 'Created',
      flex: 1,
      type: 'dateTime',
      renderCell: (params) => <Typography>{fToNow(params.row.createdAt)}</Typography>,
    },
  ];

  if (showActions) {
    dataGridColumns.push({
      filterable: false,
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => (
        <DataGridActions
          apiSuffix={apiSuffix}
          modelName={modelName}
          entityId={params.row.id}
          afterDelete={loadData}
          updatePermissionName={'update.subscriber-list'}
        />
      ),
    });
  }

  const loadData = async (filters?: {
    filterSearch: string;
    filterOperator: string;
    filterField: string;
  }) => {
    try {
      const queryParams = (apiPath: string) =>
        `${apiPath}?search=${search}&pageSize=${pageSize}&page=${page}&signature=${
          JWTContext?.user ? JWTContext?.user.companyId : ''
        }`;
      let resp: any = [];
      let comp: any = [];
      setIsLoading(true);
      if (filters) {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&filterSearch=${filters.filterSearch}&filterOperator=${
            filters.filterOperator
          }&filterField=${filters.filterField}&selectedCompany=${selectedCompany}`
        );
        const companyPromise = isGlobalAdmin ? apiService.get('company') : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      } else {
        const bookPromise = apiService.get(
          `${queryParams(apiSuffix)}&selectedCompany=${selectedCompany}`
        );
        const companyPromise: any = isGlobalAdmin ? apiService.get('company') : null;

        const [bookassessment, company] = await Promise.all([bookPromise, companyPromise]);
        resp = bookassessment;
        comp = company;
      }

      if (resp.data.length === 0) {
        setRowCount(0);
        setDataGridRows([]);
        setIsLoading(false);
        return;
      }
      const responseData = resp.data.results.map(
        (subscriber: { createdAt: string | number | Date }, index: any) => ({
          ...subscriber,
          // createdAt: fToNow(subscriber.createdAt),
        })
      );

      if (isGlobalAdmin) setCompanies(comp.data);

      setRowCount(resp.data.total);
      setDataGridRows(responseData);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    if (
      filterModel.items[0].value ||
      filterModel.items[0].operatorValue === 'isEmpty' ||
      filterModel.items[0].operatorValue === 'isNotEmpty'
    ) {
      loadData({
        filterSearch: filterModel.items[0].value,
        filterOperator: String(filterModel.items[0].operatorValue),
        filterField: filterModel.items[0].columnField,
      }).then();
    } else {
      loadData().then();
    }
  }, []);

  useEffect(() => {
    loadData().then();
    return () => {
      addSubscriberSelected([]);
      window.localStorage.removeItem('selectCp');
    };
  }, [search, page, pageSize, selectedCompany]);

  // useEffect(() => {
  //   dispatch(setSelectedEmails(emails));
  // }, [emails]);

  const handleRowSelections = (selectionModel: GridSelectionModel) => {
    saveRowIds(selectionModel as string[]);
    selectEmails(selectionModel);
  };

  const selectEmails = (ids: any) => {
    const newEmails = dataGridRows
      .filter((subscriber) => ids.includes(subscriber.id))
      .map((subscriber) => subscriber.subscribers);
    addSubscriberSelected([].concat(...newEmails));
  };

  return (
    <DataGridWrapper pageTitle={'Subscribers List'}>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Stack>
          {showTopBar && (
            <Stack direction={'row'} justifyContent={'space-between'}>
              <HeaderBreadcrumbs
                heading="Subscribers List"
                links={[
                  { name: 'Home', href: PATH_DASHBOARD.root },
                  { name: 'Subscribers List', href: PATH_DASHBOARD.website.subscriberList },
                ]}
              />

              <Stack direction={'row'} justifyContent={'space-between'} spacing={2}>
                {canI(permissions, 'create.subscriber-list', isSuperAdmin) && !isGlobalAdmin && (
                  <div>
                    <Button
                      variant={'contained'}
                      onClick={(e) => {
                        e.preventDefault();
                        navigate(`${pathname}/new`);
                      }}
                    >
                      <AddIcon /> NEW
                    </Button>
                  </div>
                )}
              </Stack>
            </Stack>
          )}

          <Stack>
            <Stack direction={'row'} justifyContent={'space-between'}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4} sm={6}>
                  <TextField
                    placeholder="Search"
                    onChange={debounce((e) => setSearch(e.target.value), 1000)}
                    sx={{ m: 1, width: '100%' }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                {isGlobalAdmin && (
                  <Grid item xs={12} md={4} sm={6}>
                    <FormControl fullWidth sx={{ m: 1, width: '100%' }}>
                      <InputLabel id="demo-simple-select-label">Companies</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedCompany}
                        label="Company"
                        onChange={(e) => {
                          setSelectedCompany(e.target.value);
                          window.localStorage.setItem('selectCp', e.target.value);
                        }}
                      >
                        <MenuItem value="">All Companies</MenuItem>
                        {companies.map((el: any, index: any) => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Stack>
          </Stack>

          <DataGrid
            components={{
              Toolbar: CustomToolbar,
            }}
            componentsProps={{
              panel: {
                anchorEl: filterButtonEl,
              },
              toolbar: {
                rowIds,
                removing,
                canDoBulkRemove: canI(permissions, 'delete.subscriber-list', isSuperAdmin),
                onBulkRemove: requestBulkRemove,
                setFilterButtonEl,
              },
            }}
            filterMode="server"
            onFilterModelChange={onFilterChange}
            autoHeight={true}
            density={'comfortable'}
            checkboxSelection={true}
            disableSelectionOnClick={true}
            disableColumnMenu={true}
            columns={dataGridColumns}
            rows={dataGridRows}
            rowCount={rowCount}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            onPageChange={(newPage) => setPage(newPage)}
            rowsPerPageOptions={[15, 50, 100]}
            pagination
            {...dataGridRows}
            paginationMode="server"
            loading={loading}
            selectionModel={rowIds as GridSelectionModel}
            onSelectionModelChange={handleRowSelections}
            keepNonExistentRowsSelected
          />
        </Stack>
      </Container>
    </DataGridWrapper>
  );
}

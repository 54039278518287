import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import * as apiService from 'src/api/api';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Card, Grid, Stack } from '@mui/material';
import { FormProvider, RHFColorPicker, RHFTextField } from '../../../components/hook-form';
import { LoadingButton } from '@mui/lab';
import { PATH_DASHBOARD } from '../../../routes/paths';

import useAuth from '../../../hooks/useAuth';
import { canI } from 'src/utils/canI';
import { Tag } from 'src/components/TagInput';

export type TagsProps = {
  name: string;
  slug: string;
  color: string;
};

type Props = {
  isEdit: boolean;
  isTagPath: boolean;
  tag: TagsProps | null;
  id: string | undefined;
  defaultName?: string | '';
  onTagCreated?: ((newTag: Tag) => void) | undefined;
};

const apiSuffix = 'tags';
const modelName = 'Tag';

export default function TagForm({ tag, id, isEdit, isTagPath, defaultName, onTagCreated }: Props) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const [newTag, setNewTag] = useState<Tag | null>(null);
  const [tagName, setTagName] = useState<string | null>(null);

  const permissions = user ? user.permissions : [];
  const isSuperAdmin = user ? user.isSuperAdmin : false;

  const TagSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    slug: Yup.string().required('Slug is required'),
  });

  const defaultValues = useMemo(
    () => ({
      name: tag?.name || defaultName,
      slug: tag?.slug || '',
      color: tag?.color || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tag]
  );

  const methods = useForm<TagsProps>({
    resolver: yupResolver(TagSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: TagsProps) => {
    try {
      if (id) {
        await apiService.put(`${apiSuffix}/${id}`, data);
      } else {
        const res = await apiService.post(apiSuffix, {
          ...data,
          signature: user ? user.companyId : '',
        });
        setNewTag({ show: true, ...res?.data });
        onTagCreated && onTagCreated(res?.data);
      }

      reset();

      const action = id ? 'edited' : 'created';
      enqueueSnackbar(`${modelName} ${action} successfully!`);

      isTagPath && navigate(PATH_DASHBOARD.website.tags);
    } catch (error) {
      console.error(error);

      const action = id ? 'editing' : 'creating';
      enqueueSnackbar(`Error ${action} ${modelName}, please try again later`, {
        variant: 'error',
      });
    }
  };

  useEffect(() => {
    if (tag) {
      reset(defaultValues);
      setTagName(tag.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tag]);

  // useEffect(() => {
  //   if (onTagCreated && newTag) {
  //     onTagCreated(newTag);
  //   }
  // }, [newTag, onTagCreated]);

  const TagForm = () => (
    <>
      <Box
        sx={{
          display: 'grid',
          rowGap: 3,
        }}
      >
        <RHFTextField name="name" label="Name *" onBlur={(ev) => setTagName(ev.target.value)} />
        <RHFTextField name="slug" label="Slug *" />
        <RHFColorPicker name="color" label="Color" tagName={tagName} tagColor={tag?.color} />
      </Box>

      <Stack alignItems="flex-end" sx={{ mt: 3 }}>
        {canI(permissions, 'create.tags', isSuperAdmin) && !id && (
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {'Create'}
          </LoadingButton>
        )}
        {canI(permissions, 'update.tags', isSuperAdmin) && id && (
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {'Save Changes'}
          </LoadingButton>
        )}
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} justifyContent={'center'}>
        <Grid item xs={12} md={isTagPath ? 6 : 12}>
          {isTagPath ? (
            <Card sx={{ p: 3 }}>
              <TagForm />
            </Card>
          ) : (
            <Box sx={{ p: 3 }}>
              <TagForm />
            </Box>
          )}
        </Grid>
      </Grid>
    </FormProvider>
  );
}

// @mui
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
// hooks
import { useForm } from 'react-hook-form';
import { FormProvider, RHFTextField } from 'src/components/hook-form';
// components
import { FC, useState } from 'react';
import * as apiService from 'src/api/api';
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';

export type NewsletterModalProps = {
  emails: string[];
  openDialog: boolean;
  closeDialog: () => void;
};

type NewsletterFormProps = {
  emails: string[];
  title: string;
  content: string;
};

const apiSuffix = 'newsletters';
const modelName = 'Newsletter';

export const NewsletterModal: FC<NewsletterModalProps> = ({ openDialog, closeDialog, emails }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const NewsletterSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    content: Yup.string().required('Content is required'),
    emails: Yup.array().required(),
    schema: Yup.string(),
  });

  const defaultValues = {
    title: '',
    content: '',
    emails: emails,
    schema: '{}',
  };

  const methods = useForm<NewsletterFormProps>({
    resolver: yupResolver(NewsletterSchema),
    defaultValues,
  });

  const {
    reset,
    handleSubmit,
    formState: { isSubmitSuccessful },
  } = methods;

  const onSubmit = async (data: NewsletterFormProps) => {
    try {
      setIsSubmitting(true);
      await apiService.post(apiSuffix, { ...data, emails });

      reset();

      const action = 'sent';
      enqueueSnackbar(`${modelName} ${action} successfully!`);
      closeDialog();
    } catch (error) {
      console.error(error);
      enqueueSnackbar(`Error sending newsletter`, {
        variant: 'error',
      });
    }

    setIsSubmitting(false);
  };

  const handleDialog = async () => {
    if (isSubmitSuccessful) {
      closeDialog();
    }
  };

  return (
    <Dialog open={openDialog} onClose={closeDialog} fullWidth={true} maxWidth={'sm'}>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Send Newsletter</DialogTitle>

        <DialogContent>
          <Box sx={{ display: 'grid', rowGap: 3, marginTop: 3 }}>
            <RHFTextField name="title" label="Title" fullWidth />
            <RHFTextField name="content" label="Content" multiline minRows={10} fullWidth />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={closeDialog}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Send
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/resetPassword'),
  resetPasswordAfterEmail: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  website: {
    subscribers: path(ROOTS_DASHBOARD, '/subscribers'),
    contacts: path(ROOTS_DASHBOARD, '/contacts'),
    callbackRequests: path(ROOTS_DASHBOARD, '/callback-requests'),
    serviceRequests: path(ROOTS_DASHBOARD, '/service-requests'),
    campaign: path(ROOTS_DASHBOARD, '/campaigns'),
    promoCodes: path(ROOTS_DASHBOARD, '/promo-codes'),
    clientReviews: path(ROOTS_DASHBOARD, '/client-reviews'),
    appointments: path(ROOTS_DASHBOARD, '/appointments'),
    clients: path(ROOTS_DASHBOARD, '/clients'),
    notifications: path(ROOTS_DASHBOARD, '/notifications'),
    departments: path(ROOTS_DASHBOARD, '/departments'),
    bookAssessments: path(ROOTS_DASHBOARD, '/book-assessments'),
    jobs: path(ROOTS_DASHBOARD, '/jobs'),
    jobRoles: path(ROOTS_DASHBOARD, '/job-roles'),
    jobApplies: path(ROOTS_DASHBOARD, '/job-applies'),
    sms: path(ROOTS_DASHBOARD, '/sms'),
    newsletter: path(ROOTS_DASHBOARD, '/newsletter'),
    tags: path(ROOTS_DASHBOARD, '/tags'),
    company: path(ROOTS_DASHBOARD, '/company'),
    template: path(ROOTS_DASHBOARD, '/template'),
    role: path(ROOTS_DASHBOARD, '/role'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    posts: path(ROOTS_DASHBOARD, '/posts'),
    categories: path(ROOTS_DASHBOARD, '/categories'),
    subscriberList: path(ROOTS_DASHBOARD, '/list-subscribers'),
    repairServices: path(ROOTS_DASHBOARD, '/repair-services'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/users'),
    // profile: path(ROOTS_DASHBOARD, '/user/profile'),
    // cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/users'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    // editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    // account: path(ROOTS_DASHBOARD, '/user/account'),
  },
  profile: {
    account: path(ROOTS_DASHBOARD, '/profile/account'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
export const PATH_WEBSITE = 'https://nimbuspc.com';

import { createSlice } from '@reduxjs/toolkit';
//
import { dispatch } from '../store';
import SelectTemplate from '../../components/newsletter/steps/selectTemplate';
import SelectSubscribers from '../../components/newsletter/steps/selectSubscribers';
import FillData from '../../components/newsletter/steps/fillData';
import Review from '../../components/newsletter/steps/review';
import * as apiService from 'src/api/api';

// ----------------------------------------------------------------------
const apiSuffix = 'templates';

type templateProps = {
  id: string;
  type: string;
  html: string;
  schema: string;
  selected: boolean;
  selectedPreview: boolean;
  thumbnail: string;
  thumbnail_key: string;
};
type IsmsProps = {
  message: string;
  name: string;
  stack: string;
};

type IErrorProps = {
  error: boolean;
  sms: IsmsProps;
};

type IState = {
  selectedTemplate: [templateProps] | [];
  selectedEmails: [string] | [];
  fillHtml: string;
  title: string;
  logo: string;
  cName: string;
  steper: number;
  isLoading: boolean;
  error: IErrorProps;
  success: boolean;
  isSchedule: boolean;
  scheduleDate: Date;
};

export function getTemplates() {
  return async () => {
    const storedValue = localStorage.getItem('cmp');
    const st = storedValue === null ? '' : JSON.parse(storedValue);
    dispatch(slice.actions.startLoading());
    try {
      const response = await apiService.get(`${apiSuffix}?signature=${st ? st.id : ''}`);
      dispatch(slice.actions.getTemplateSuccess(response.data));
      dispatch(slice.actions.setIsLoading(false));
    } catch (error) {
      dispatch(slice.actions.Error(error));
      dispatch(slice.actions.setIsLoading(false));
    }
  };
}

const initialState: IState = {
  selectedTemplate: [],
  selectedEmails: [],
  fillHtml: '',
  title: '',
  logo: '',
  cName: '',
  steper: 0,
  isLoading: false,
  error: {
    error: false,
    sms: {
      message: '',
      name: '',
      stack: '',
    },
  },
  success: false,
  isSchedule: false,
  scheduleDate: new Date()
};

export const steps = [SelectTemplate, SelectSubscribers, FillData, Review];

const slice = createSlice({
  name: 'newsLatter',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    setTitle(state, action) {
      state.title = action.payload;
    },
    setSelectedEmails(state, action) {
      state.selectedEmails = action.payload;
    },
    getTemplateSuccess(state, action) {
      state.isLoading = false;
      state.selectedTemplate = action.payload.templates;
      state.logo = action.payload.logo;
      state.cName = action.payload.cName;
    },
    handleNext(state) {
      state.steper = Math.min(state.steper + 1, steps.length - 1);
    },
    handlePrev(state) {
      state.steper = Math.max(state.steper - 1, 0);
    },
    selectTemplate(state, action) {
      const newT: any = state.selectedTemplate.map((e) => ({
        ...e,
        selected: e.id === action.payload,
      }));
      state.selectedTemplate = newT;
    },
    selectPreview(state, action) {
      const newT: any = state.selectedTemplate.map((e) => ({
        ...e,
        selectedPreview: e.id === action.payload,
      }));
      state.selectedTemplate = newT;
    },
    fillD(state, action) {
      state.fillHtml = action.payload;
    },
    Error(state, action) {
      state.error = {
        error: action.payload.error,
        sms: action.payload.sms,
      };
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setSuccess(state) {
      const clear: any = state.selectedTemplate.map((e) => ({
        ...e,
        selected: false,
      }));
      state.selectedTemplate = clear;
      state.steper = 0;
      state.success = true;
    },
    setIsSchedule(state) {
      state.isSchedule = !state.isSchedule;
    },
    setScheduleDate(state,action) {
      state.scheduleDate = action.payload
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  selectTemplate,
  handlePrev,
  handleNext,
  setSelectedEmails,
  fillD,
  setIsLoading,
  setSuccess,
  setTitle,
  selectPreview,
  setIsSchedule,
  setScheduleDate
} = slice.actions;

// ----------------------------------------------------------------------

export const prev = () => {
  dispatch(slice.actions.handlePrev());
};
export const next = () => {
  dispatch(slice.actions.handleNext());
};
export const selectTemplateAction = (id: string) => {
  dispatch(slice.actions.selectTemplate(id));
};
export const setLoading = (isLoading: boolean) => {
  dispatch(slice.actions.setIsLoading(isLoading));
};
export const setSelEmails = (emails: string[]) => {
  dispatch(slice.actions.setSelectedEmails(emails));
};
export const setSchedule = () => {
  dispatch(slice.actions.setIsSchedule());
};
export const setSchDate = (date: Date) => {
  dispatch(slice.actions.setScheduleDate(date));
};

// import axios, { AxiosRequestHeaders } from "axios";
import axios from '../utils/axios';
// import { API_URL } from '../config';

export const get = async (url: string) => axios.get(`/${url}`);

export const post = async (url: string, data: any) => axios.post(`/${url}`, data);

export const put = async (url: string, data: any) => axios.put(`/${url}`, data);

export const remove = async (url: string) => axios.delete(`/${url}`);

// const getHeaders = (): AxiosRequestHeaders => {
//   const accessToken = localStorage.getItem('accessToken');
//   return accessToken ? {
//     'Authorization': `Bearer ${accessToken}`
//   } : {};
// }

import { Box, Button, Typography, styled } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { useCallback, useMemo, useState } from 'react';
import Image from 'src/components/Image';
import { Add } from '@mui/icons-material';
import { useImportSubscriberContext } from './context';

const Wrapper = styled(Box)(({ theme }) =>
  theme.unstable_sx({
    height: '300px',
    p: '20px',
    display: 'grid',
    gridTemplateColumns: '1fr',
    justifyItems: 'center',
    alignContent: 'start',
  })
);

const FileInputWrapper = styled(Box)<{
  htmlFor: string;
}>(({ theme }) =>
  theme.unstable_sx({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'unset',
    justifyContent: 'center',
    alignContent: 'center',
    width: '100%',
    height: '190px',
    border: 1,
    borderColor: theme.palette.divider,
    borderRadius: '10px',
    p: '20px',
  })
);

const UploadFileStep = () => {
  const { file, setFile } = useImportSubscriberContext();
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.files?.[0]);
    const selectedFile = e.target.files?.[0];
    if (!selectedFile) return;

    if (selectedFile.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      return setError('The file must be an excel');
    } else {
      setError(null);
    }

    setFile(selectedFile);
  }, []);

  const filename = useMemo(() => file?.name ?? '', [file]);

  return (
    <Wrapper>
      <a
        href="https://npc-api.s3.amazonaws.com/Excel+template+for+import+subscribers+NPC-Admin.xlsx"
        target="_blank"
        download
        style={{ textDecoration: 'none' }}
      >
        <Button
          variant="outlined"
          sx={{ display: 'flex', alignContent: 'center', gap: '5px', height: 'fit-content' }}
        >
          <VisibilityOutlinedIcon />
          See Excel example
        </Button>
      </a>

      <Button sx={{ width: '100%', maxWidth: '300px', mt: '10px' }}>
        <FileInputWrapper component="label" htmlFor="file-input">
          <Box sx={{ mx: 'auto', display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <Image
              src={!filename ? '/icons/excel-outline.webp' : '/icons/excel-filled.webp'}
              alt="excel"
              sx={{ width: '90px', height: '90px', color: (t) => t.palette.divider, mx: 'auto' }}
            />
            {!filename ? (
              <Typography
                component="span"
                textAlign="center"
                fontSize="12px"
                sx={{ display: 'flex', alignContent: 'center', gap: '5px', height: 'fit-content' }}
              >
                <Add />
                <Typography fontSize="12px" sx={{ my: 'auto' }}>
                  Select file
                </Typography>
              </Typography>
            ) : (
              <Typography fontSize="12px" sx={{ my: 'auto', textTransform: 'none' }}>
                {filename}
              </Typography>
            )}
          </Box>
          <input
            id="file-input"
            type="file"
            accept=".xlsx, .xls"
            hidden
            onChange={handleFileChange}
          />
        </FileInputWrapper>
      </Button>

      {error && (
        <Typography fontSize={14} sx={{ color: (t) => t.palette.error.main }}>
          {error}
        </Typography>
      )}
    </Wrapper>
  );
};
export default UploadFileStep;

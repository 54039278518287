import { createSlice } from '@reduxjs/toolkit';
import * as apiService from 'src/api/api';
// @types
import { Notification } from '../../@types/notification';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const apiSuffix = 'notifications';

const initialState: Notification = {
  notifications: [],
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET Notifications
    getNotificationsSuccess(state, action) {
      state.isLoading = true;
      state.notifications = action.payload;
      state.isLoading = false;
    },

    setReadedSingleNotificationSuccess(state, action) {
      state.notifications = state.notifications.map((e) =>
        e.id === action.payload ? { ...e, status: 'readed' } : e
      );
    },

    setAllAsReaded(state, action) {
      state.notifications = state.notifications.map((e) => ({ ...e, status: 'readed' }));
    },

    deleteNotificationSuccess(state, action) {
      state.notifications = state.notifications.filter((e) => e.id !== action.payload);
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getNotificationsSuccess,
  setReadedSingleNotificationSuccess,
  setAllAsReaded,
  deleteNotificationSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export const getNotifications = async (signature:string) => {
  dispatch(slice.actions.startLoading());

  try {
    const response = await apiService.get(`${apiSuffix}?signature=${signature}`);
    dispatch(getNotificationsSuccess(response.data));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
export const setSingleNotification = async (id: string) => {
  try {
    await apiService.put(`${apiSuffix}/${id}`, { status: 'readed' });
    dispatch(setReadedSingleNotificationSuccess(id));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};
export const markAllAsReaded = async () => {
  try {
    await apiService.put(`${apiSuffix}`, { markAllAsReaded: 'true' });
    dispatch(slice.actions.setAllAsReaded('true'));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};

export const deleteNotification = async (id:string) => {
  try {
    await apiService.remove(`${apiSuffix}/${id}`);
    dispatch(deleteNotificationSuccess(id));
  } catch (error) {
    dispatch(slice.actions.hasError(error));
  }
};


import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import axios from 'src/utils/axios';
import useModal from './useModal';

interface Props {
  signature: string;
  apiUrl: string;
  onSuccess?: () => void;
  onError?: (err: any) => void;
}

export const useBulkRemove = ({ signature, apiUrl, onSuccess, onError }: Props) => {
  const [rowIds, setRowIds] = useState<string[]>([]);
  const [removing, setRemovig] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { showModal, closeModal } = useModal();

  const saveRowIds = useCallback((ids: string[]) => {
    setRowIds(ids);
  }, []);

  const requestBulkRemove = useCallback(async () => {
    showModal({
      mainText: 'Do you want to delete selected rows forever?',
      onAccept: executeBulkRemove,
      onCancel: closeModal,
    });
  }, [rowIds, signature]);

  const executeBulkRemove = useCallback(async () => {
    setRemovig(true);
    closeModal();
    try {
      await axios({
        method: 'DELETE',
        url: apiUrl,
        data: {
          ids: rowIds,
          signature,
        },
      });

      if (onSuccess) {
        onSuccess();
      } else {
        enqueueSnackbar('Deleted succesfully', { variant: 'success' });
      }

      setRowIds([]);
    } catch (error) {
      if (onError) {
        onError(error);
      } else {
        enqueueSnackbar('Failed the bulk deletion', { variant: 'error' });
      }
    } finally {
      setRemovig(false);
    }
  }, [rowIds, signature]);

  return { rowIds, removing, saveRowIds, requestBulkRemove };
};

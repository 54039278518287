import { styled } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Grow } from '@mui/material';
import axios from 'axios';
import { HOST_API } from 'src/config';

type ApprovalPayload = {
  signature: string;
  token: string;
  accepted: boolean;
};

const Wrapper = styled('div')(({ theme }) =>
  theme.unstable_sx({
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#ebeef3',
    px: '20px',
  })
);

const Container = styled('div')(({ theme }) =>
  theme.unstable_sx({
    background: 'white',
    py: '20px',
    px: '30px',
    borderRadius: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '10px',
    width: '100%',
    maxWidth: '300px',
    minHeight: '170px',
  })
);

const Image = styled('img')(({ theme }) =>
  theme.unstable_sx({
    width: '70px',
  })
);

const Text = styled('p')(({ theme }) =>
  theme.unstable_sx({
    fontSize: '14px',
    fontWeight: 500,
  })
);

const Comment = styled('div')(({ theme }) =>
  theme.unstable_sx({
    width: '100%',
    maxWidth: '280px',
    color: '#2b2b2b8f',
    mt: '10px',
  })
);

const ClientReviewApproval = () => {
  const [searchParams] = useSearchParams();
  const [approvalPayload, setApprovalPayload] = useState<ApprovalPayload>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState<'approved' | 'denied' | null>(null);

  const requestApproval = useCallback(async (payload: ApprovalPayload) => {
    try {
      await axios({
        method: 'POST',
        url: `${HOST_API}/client-reviews/approval`,
        data: payload,
      });

      setSuccess(payload.accepted === true ? 'approved' : 'denied');
    } catch (error) {
      setError(error?.message ?? 'Somenthing went wrong');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const queries = Object.fromEntries(searchParams as any);

    if (!queries?.accepted || !queries?.sign || !queries?.tk) {
      return setError('Something went wrong');
    }

    const payload = {
      accepted: queries.accepted === 'true' ? true : false,
      token: queries.tk,
      signature: queries.sign,
    };

    setApprovalPayload(payload);

    requestApproval(payload);
  }, []);

  return (
    <Wrapper>
      <Container>
        {typeof approvalPayload !== 'undefined' && loading && (
          <Text>
            {approvalPayload.accepted ? 'Approving client review...' : 'Denying client review...'}
          </Text>
        )}
        {success && (
          <Grow in>
            <Text style={{ paddingBottom: '10px' }}>
              {success === 'approved'
                ? 'Client review approved successfully!'
                : 'Client review denied successfully!'}
            </Text>
          </Grow>
        )}
        {error && <Text>{error}</Text>}
        {loading && <Image src="/icons/infinity-loading.svg" alt="loading" />}
        {error && <Image src="/icons/error.webp" alt="error" />}
        {success && (
          <Image
            src={success === 'approved' ? '/icons/approve.webp' : '/icons/denied.webp'}
            alt="success"
          />
        )}
      </Container>
      <Comment>
        {loading && (
          <Text>Please don't close this window while it is processing your request.</Text>
        )}
        {error && <Text>Try again later</Text>}
        {success && <Text>You can now close this window</Text>}
      </Comment>
    </Wrapper>
  );
};
export default ClientReviewApproval;

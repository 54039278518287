import { FC } from "react";
import Page from "../../components/Page";

type Props = {
  pageTitle: string
};

export const DataGridWrapper: FC<Props> = (
  { children, pageTitle }) => (
    <Page title={pageTitle}>
      {children}
    </Page>
  )

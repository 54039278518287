import { FC } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import useModal from "../../../hooks/useModal";

export type CustomModalProps = {
  title?: string,
  mainText?: string,
  acceptText?: string,
  cancelText?: string,
  onCancel?: VoidFunction,
  onAccept?: VoidFunction
  open?: boolean,
}

export const CustomModal: FC = () => {
  const { params } = useModal();
  return (
    <Dialog
      open={params.open || false}
      onClose={params.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {params.title ? params.title : 'Alert'}
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {params.mainText}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={params.onCancel} color={'info'}>
          {params.cancelText ? params.cancelText : 'CANCEL'}
        </Button>
        <Button onClick={params.onAccept} color={'info'} autoFocus>
          {params.acceptText ? params.acceptText : 'OK'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
